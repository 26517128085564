var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("related-procedures", {
        attrs: {
          optionsRepo: _vm.procedureTypesRepo,
          relationsFilter: () => true,
          optionsLabel: "procType",
          relationsField: "excludeBenefits",
          relationToOptionsField: "procedureTypeId",
          relationToFormField: "insurancePlanId",
          relationsRepo: _vm.excludeBenefitsRepo,
          fields: _vm.fields,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(procedureType)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(data.item.procedureType.procType) + " "),
              ]
            },
          },
          {
            key: "cell(description)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(data.item.procedureType.description) + " "),
              ]
            },
          },
          {
            key: "sidebar-header",
            fn: function (item) {
              return [
                item.id
                  ? _c("h4", [_vm._v("Update Procedure Type ")])
                  : _c("h4", [_vm._v("Add Procedure Type ")]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }