var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        ref: "currentTable",
        attrs: {
          itemsRepo: _vm.practiceUsersRepo,
          fields: _vm.fields,
          sidebarData: _vm.selectedRow,
          copyData: _vm.selectedRowCopy,
          loadParams: { UserId: _vm.$route.params.user },
          isDataFromModel: false,
          "is-add-edit-sidbar": true,
          sidebarOpen: _vm.sidebarAddEdit,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedRow = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedRow = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedRowCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedRowCopy = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebarAddEdit = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebarAddEdit = $event
          },
          save: _vm.save,
          "on-copied": _vm.onCopied,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(practiceName)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(data.item.practiceName) + " "),
                _c("br"),
                _c("span", { staticClass: "grey-text smaller-text" }, [
                  _vm._v("Practice ID: " + _vm._s(data.item.id)),
                ]),
              ]
            },
          },
          {
            key: "cell(role)",
            fn: function (data) {
              return [_vm._v(" " + _vm._s(data.value) + " ")]
            },
          },
          {
            key: "cell(insCompany)",
            fn: function (data) {
              return [
                _vm._l(data.value, function (company, $index) {
                  return _c("div", { key: $index }, [
                    $index < 3
                      ? _c("span", [
                          _vm._v(" " + _vm._s(company.insuranceName) + " "),
                        ])
                      : _vm._e(),
                  ])
                }),
                _vm.showMoreOption == data.item.id
                  ? _c(
                      "div",
                      _vm._l(data.value, function (company, $index) {
                        return _c("div", { key: $index }, [
                          $index >= 3
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(company.insuranceName) + " "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                data.value.length > 3 && _vm.showMoreOption != data.item.id
                  ? _c(
                      "span",
                      {
                        staticClass: "primary-text",
                        on: {
                          click: function ($event) {
                            _vm.showMoreOption = data.item.id
                          },
                        },
                      },
                      [
                        _c("u", [
                          _vm._v(_vm._s(data.value.length - 3) + " more"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.selectedRowCopy.id
                              ? _c("h4", { staticClass: "mb-2" }, [
                                  _vm._v("Edit Practice"),
                                ])
                              : _c("h4", [_vm._v("Add Practice")]),
                            !_vm.selectedRowCopy.id
                              ? _c(
                                  "b-form-group",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { label: "Practice Name" },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Practice Name",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("infinit-select", {
                                                  attrs: {
                                                    includeIds: [
                                                      _vm.selectedRowCopy
                                                        .practiceId,
                                                    ],
                                                    label: "name",
                                                    repo: _vm.practiceRepo,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedRowCopy
                                                        .practiceId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.selectedRowCopy,
                                                        "practiceId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedRowCopy.practiceId",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2380123847
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Role" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Role", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.optionsPracticeUserRole,
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.selectedRowCopy.role,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedRowCopy,
                                                  "role",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedRowCopy.role",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Insurance Companies" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Insurance Companies" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("multi-select", {
                                            attrs: {
                                              sourceIdField: "insuranceId",
                                              label: "name",
                                              optionsRepo: _vm.insuranceRepo,
                                            },
                                            model: {
                                              value:
                                                _vm.selectedRowCopy.insCompany,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedRowCopy,
                                                  "insCompany",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedRowCopy.insCompany",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "mt-0 text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "sidebar-right",
            "bg-variant": "white",
            "no-header": "",
            right: "",
            backdrop: "",
            shadow: "",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              { staticClass: "w-100 text-right pb-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    attrs: { variant: "primary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        _vm.permissionHistory = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.permissionHistory,
            callback: function ($$v) {
              _vm.permissionHistory = $$v
            },
            expression: "permissionHistory",
          },
        },
        [
          _c(
            "b-container",
            { staticClass: "px-2 mt-1" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("h4", [_vm._v("User Permission History")]),
                    _c("label", [
                      _vm._v(
                        "The history of user permissions in this practice"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "b-row",
                  { key: index, staticClass: "mt-1" },
                  [
                    _c("b-col", { attrs: { cols: "6" } }, [
                      _vm._v(" " + _vm._s(item.role) + " "),
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "text-right", attrs: { cols: "6" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              new Date(item.dateStart).toLocaleDateString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                }
                              )
                            ) +
                            " - " +
                            _vm._s(
                              item.dateEnd
                                ? new Date(item.dateEnd).toLocaleDateString(
                                    "en-US",
                                    {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "2-digit",
                                    }
                                  )
                                : "Active"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("b-col", { attrs: { cols: "12" } }, [_c("hr")]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }