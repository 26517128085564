import { render, staticRenderFns } from "./FeeScheduleOverrideCC.vue?vue&type=template&id=0a47f4a2&scoped=true"
import script from "./FeeScheduleOverrideCC.vue?vue&type=script&lang=ts"
export * from "./FeeScheduleOverrideCC.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a47f4a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a47f4a2')) {
      api.createRecord('0a47f4a2', component.options)
    } else {
      api.reload('0a47f4a2', component.options)
    }
    module.hot.accept("./FeeScheduleOverrideCC.vue?vue&type=template&id=0a47f4a2&scoped=true", function () {
      api.rerender('0a47f4a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/practices/practices-details/corporations-more/corporations-more-more/fee-schedules-more/FeeScheduleOverrideCC.vue"
export default component.exports