var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
        _c("div", { staticClass: "alert-body" }, [
          _c(
            "span",
            [
              _c("feather-icon", {
                staticClass: "mr-25",
                attrs: { icon: "AlertCircleIcon", size: "18" },
              }),
              _vm._v(" Data on this page is derived from the "),
              _c("strong", [_vm._v("company info")]),
              _vm._v(
                " , you can override specific fields by clicking the edit "
              ),
              _c("feather-icon", {
                staticClass: "ml-25 mr-25 text-secondary",
                attrs: { icon: "EditIcon", size: "18" },
              }),
              _vm._v(" icon. "),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "form-Component",
        {
          attrs: { copyData: _vm.insuranceCopy },
          on: {
            "update:copyData": function ($event) {
              _vm.insuranceCopy = $event
            },
            "update:copy-data": function ($event) {
              _vm.insuranceCopy = $event
            },
            save: _vm.validationForm,
          },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v
            },
            expression: "formData",
          },
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "validation-observer",
                { ref: "simpleRules" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Claims Payer ID",
                                "label-for": "claimsPayerID",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Claims Payer ID", rules: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(
                                          "b-input-group",
                                          {
                                            staticClass: "input-group-merge",
                                            attrs: { size: "sm" },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "claimsPayerID",
                                                placeholder: "",
                                                disabled: _vm.disabled != 1,
                                              },
                                              model: {
                                                value:
                                                  _vm.insuranceCopy
                                                    .claimsPayorId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.insuranceCopy,
                                                    "claimsPayorId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insuranceCopy.claimsPayorId",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              {
                                                staticClass: "cursor-pointer",
                                                attrs: { "is-text": "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.disableInput(1)
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "text-secondary",
                                                  attrs: { icon: "EditIcon" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Status Payer Id",
                                "label-for": "atatusPayerID",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Status Payer Id", rules: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(
                                          "b-input-group",
                                          {
                                            staticClass: "input-group-merge",
                                            attrs: { size: "sm" },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "statusPayerID",
                                                placeholder: "",
                                                disabled: _vm.disabled != 2,
                                              },
                                              model: {
                                                value:
                                                  _vm.insuranceCopy
                                                    .statusPayorId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.insuranceCopy,
                                                    "statusPayorId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insuranceCopy.statusPayorId",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              {
                                                staticClass: "cursor-pointer",
                                                attrs: { "is-text": "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.disableInput(2)
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "text-secondary",
                                                  attrs: { icon: "EditIcon" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Clearing House",
                                "label-for": "clearingHouse",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Clearing House", rules: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            id: "clearingHouse",
                                            placeholder:
                                              "Please select Clearing House",
                                            options: _vm.clearingHouseOptions,
                                            label: "name",
                                            reduce: (x) => x.id,
                                          },
                                          model: {
                                            value:
                                              _vm.insuranceCopy.clearingHouseId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insuranceCopy,
                                                "clearingHouseId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insuranceCopy.clearingHouseId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("br"),
                          _c("br"),
                          _c(
                            "can",
                            {
                              attrs: {
                                I: "View",
                                a: "ScrubberProcedureSettings",
                              },
                            },
                            [
                              _c("h5", { staticClass: "mb-1" }, [
                                _c("b", [
                                  _vm._v("Filing Limits "),
                                  _c("span", { staticClass: "grey-text" }, [
                                    _vm._v("(in Days)"),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "In network",
                                    "label-for": "inNetwork",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "In network", rules: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-input-group",
                                              {
                                                staticClass:
                                                  "input-group-merge",
                                                attrs: { size: "sm" },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "inNetwork",
                                                    placeholder: "",
                                                    disabled: _vm.disabled != 4,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.insuranceCopy
                                                        .inNetworkFilingLimit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "inNetworkFilingLimit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.inNetworkFilingLimit",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    attrs: { "is-text": "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.disableInput(
                                                          4
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "text-secondary",
                                                      attrs: {
                                                        icon: "EditIcon",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Out of network",
                                    "label-for": "outOfNetwork",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Out of network",
                                      rules: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-input-group",
                                              {
                                                staticClass:
                                                  "input-group-merge",
                                                attrs: { size: "sm" },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "outOfNetwork",
                                                    placeholder: "",
                                                    disabled: _vm.disabled != 5,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.insuranceCopy
                                                        .outOfNetworkFilingLimit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "outOfNetworkFilingLimit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.outOfNetworkFilingLimit",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    attrs: { "is-text": "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.disableInput(
                                                          5
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "text-secondary",
                                                      attrs: {
                                                        icon: "EditIcon",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Corrected claims",
                                "label-for": "correctedClaims",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Corrected claims", rules: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(
                                          "b-input-group",
                                          {
                                            staticClass: "input-group-merge",
                                            attrs: { size: "sm" },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "correctedClaims",
                                                placeholder: "",
                                                disabled: _vm.disabled != 6,
                                              },
                                              model: {
                                                value:
                                                  _vm.insuranceCopy
                                                    .correctedClaimFilingLimit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.insuranceCopy,
                                                    "correctedClaimFilingLimit",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insuranceCopy.correctedClaimFilingLimit",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              {
                                                staticClass: "cursor-pointer",
                                                attrs: { "is-text": "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.disableInput(6)
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "text-secondary",
                                                  attrs: { icon: "EditIcon" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            attrs: {
                              required: false,
                              withOverride: false,
                              editButtonAllowProp: true,
                            },
                            model: {
                              value: _vm.insuranceCopy.statusAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.insuranceCopy,
                                  "statusAddress",
                                  $$v
                                )
                              },
                              expression: "insuranceCopy.statusAddress",
                            },
                          }),
                          _c("br"),
                          _c("br"),
                          _c(
                            "can",
                            {
                              attrs: {
                                I: "View",
                                a: "ScrubberProcedureSettings",
                              },
                            },
                            [
                              _c("h5", { staticClass: "mb-1" }, [
                                _c("b", [
                                  _vm._v("Accepts claims electronically"),
                                ]),
                              ]),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        "Accepts secondary claims electronically? "
                                      ),
                                      _c("feather-icon", {
                                        staticClass:
                                          "ml-50 text-secondary cursor-pointer float-right",
                                        attrs: { icon: "EditIcon" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.disableInput(9)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("validation-provider", {
                                    staticClass: "demo-inline-spacing pt-0",
                                    attrs: { name: "AcceptsClaim", rules: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "AcceptsClaim",
                                                  value: "true",
                                                  required: "",
                                                  disabled: _vm.disabled != 9,
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .acceptsSecondaryClaimsE,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "acceptsSecondaryClaimsE",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.acceptsSecondaryClaimsE",
                                                },
                                              },
                                              [_vm._v(" Yes ")]
                                            ),
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "AcceptsClaim",
                                                  value: "false",
                                                  required: "",
                                                  disabled: _vm.disabled != 9,
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .acceptsSecondaryClaimsE,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "acceptsSecondaryClaimsE",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.acceptsSecondaryClaimsE",
                                                },
                                              },
                                              [_vm._v(" No ")]
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        "Accepts corrected claims electronically? "
                                      ),
                                      _c("feather-icon", {
                                        staticClass:
                                          "ml-50 text-secondary cursor-pointer float-right",
                                        attrs: { icon: "EditIcon" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.disableInput(10)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("validation-provider", {
                                    staticClass: "demo-inline-spacing pt-0",
                                    attrs: {
                                      name: "AcceptsCorrectedClaim",
                                      rules: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "AcceptsCorrectedClaim",
                                                  value: "true",
                                                  required: "",
                                                  disabled: _vm.disabled != 10,
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .acceptsCorrectedClaimsE,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "acceptsCorrectedClaimsE",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.acceptsCorrectedClaimsE",
                                                },
                                              },
                                              [_vm._v(" Yes ")]
                                            ),
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "AcceptsCorrectedClaim",
                                                  value: "false",
                                                  required: "",
                                                  disabled: _vm.disabled != 10,
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .acceptsCorrectedClaimsE,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "acceptsCorrectedClaimsE",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.acceptsCorrectedClaimsE",
                                                },
                                              },
                                              [_vm._v(" No ")]
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("email-phone-fax", {
                            attrs: {
                              emails: _vm.insuranceCopy.statusEmails,
                              phones: _vm.insuranceCopy.statusPhones,
                            },
                            on: {
                              "update:emails": function ($event) {
                                return _vm.$set(
                                  _vm.insuranceCopy,
                                  "statusEmails",
                                  $event
                                )
                              },
                              "update:phones": function ($event) {
                                return _vm.$set(
                                  _vm.insuranceCopy,
                                  "statusPhones",
                                  $event
                                )
                              },
                            },
                          }),
                          _c("br"),
                          _c("br"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-5", attrs: { cols: "12" } },
                    [
                      _c("h5", { staticClass: "mb-1" }, [
                        _c("b", [
                          _vm._v("Web Links (Log-In Details And Methods)"),
                        ]),
                      ]),
                      _c("weblinks-cc", {
                        attrs: {
                          webLinksToFormField: "insuranceId",
                          filterByField: "InsuranceId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }