import { render, staticRenderFns } from "./CorporationsMoreTabCC.vue?vue&type=template&id=594eda99&scoped=true"
import script from "./CorporationsMoreTabCC.vue?vue&type=script&lang=ts"
export * from "./CorporationsMoreTabCC.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594eda99",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('594eda99')) {
      api.createRecord('594eda99', component.options)
    } else {
      api.reload('594eda99', component.options)
    }
    module.hot.accept("./CorporationsMoreTabCC.vue?vue&type=template&id=594eda99&scoped=true", function () {
      api.rerender('594eda99', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/practices/practices-details/corporations-more/CorporationsMoreTabCC.vue"
export default component.exports