<template>
  <PlansDetailsMoreTabCC />
</template>

<script lang="ts">
import PlansDetailsMoreTabCC from '/src/class-components/practices/practices-details/profiles-more/plansdetails-more/PlansDetailsMoreTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    PlansDetailsMoreTabCC
  }
})
export default class ProfilesPlansMoreTab extends Vue {}
</script>
