var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "form-component",
            {
              attrs: {
                copyData: _vm.insurancePlanCopy,
                copyAddress: _vm.addressCopy,
                addressField: "paymentAddress",
              },
              on: {
                "update:copyData": function ($event) {
                  _vm.insurancePlanCopy = $event
                },
                "update:copy-data": function ($event) {
                  _vm.insurancePlanCopy = $event
                },
                "update:copyAddress": function ($event) {
                  _vm.addressCopy = $event
                },
                "update:copy-address": function ($event) {
                  _vm.addressCopy = $event
                },
                save: _vm.save,
              },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _c(
                "b-form",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-alert",
                            { attrs: { variant: "primary", show: "" } },
                            [
                              _c("div", { staticClass: "alert-body" }, [
                                _c(
                                  "span",
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-25",
                                      attrs: {
                                        icon: "AlertCircleIcon",
                                        size: "18",
                                      },
                                    }),
                                    _vm._v(
                                      " Data on this page is derived from the "
                                    ),
                                    _c("strong", [_vm._v("company info")]),
                                    _vm._v(
                                      " , you can override specific fields by clicking the "
                                    ),
                                    _c("feather-icon", {
                                      staticClass:
                                        "ml-30 mr-30 secondary-text cursor-pointer",
                                      attrs: { icon: "EditIcon", size: "16" },
                                    }),
                                    _vm._v(" icon "),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("email-phon-fax-override", {
                            attrs: {
                              sourceEmails: _vm.insurancePlanCopy.paymentEmails,
                              sourcePhones: _vm.insurancePlanCopy.paymentPhones,
                              overrideEmails: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.paymentEmails
                                : [],
                              overridePhones: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.paymentPhones
                                : [],
                            },
                            on: {
                              "update:sourceEmails": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "paymentEmails",
                                  $event
                                )
                              },
                              "update:source-emails": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "paymentEmails",
                                  $event
                                )
                              },
                              "update:sourcePhones": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "paymentPhones",
                                  $event
                                )
                              },
                              "update:source-phones": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "paymentPhones",
                                  $event
                                )
                              },
                            },
                          }),
                          _c("br"),
                          _c("br"),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            attrs: {
                              addressOverride: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.paymentAddress
                                : {},
                              withOverride: true,
                            },
                            model: {
                              value: _vm.addressCopy,
                              callback: function ($$v) {
                                _vm.addressCopy = $$v
                              },
                              expression: "addressCopy",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "can",
                        {
                          attrs: { I: "View", a: "ScrubberProcedureSettings" },
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "4", sm: "6" } },
                            [
                              _c("radio-override", {
                                attrs: {
                                  overrideData: _vm.insurancePlanCopy.insurance
                                    ? _vm.insurancePlanCopy.insurance
                                        .hasEftOption
                                    : "",
                                  label: "Has Electronic Funds Transfer (EFT)",
                                },
                                model: {
                                  value: _vm.insurancePlanCopy.hasEftOption,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlanCopy,
                                      "hasEftOption",
                                      $$v
                                    )
                                  },
                                  expression: "insurancePlanCopy.hasEftOption",
                                },
                              }),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Setup Instructions",
                                    "label-for": "eftInstructions",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Setup Instructions",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "eftInstructions",
                                                placeholder: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.insurancePlanCopy
                                                    .eftInstructions,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.insurancePlanCopy,
                                                    "eftInstructions",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insurancePlanCopy.eftInstructions",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c("radio-override", {
                                attrs: {
                                  overrideData: _vm.insurancePlanCopy.insurance
                                    ? _vm.insurancePlanCopy.insurance
                                        .hasEraOption
                                    : "",
                                  label:
                                    "Provides ERA (Electronic Remittance Advice)",
                                },
                                model: {
                                  value: _vm.insurancePlanCopy.hasEraOption,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlanCopy,
                                      "hasEraOption",
                                      $$v
                                    )
                                  },
                                  expression: "insurancePlanCopy.hasEraOption",
                                },
                              }),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Setup Instructions",
                                    "label-for": "eraInstructions",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Setup Instructions",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "eraInstructions",
                                                placeholder: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.insurancePlanCopy
                                                    .eraInstructions,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.insurancePlanCopy,
                                                    "eraInstructions",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insurancePlanCopy.eraInstructions",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }