import { render, staticRenderFns } from "./CloneStartingPoint.vue?vue&type=template&id=00cdc4d6&scoped=true"
import script from "./CloneStartingPoint.vue?vue&type=script&lang=ts"
export * from "./CloneStartingPoint.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00cdc4d6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00cdc4d6')) {
      api.createRecord('00cdc4d6', component.options)
    } else {
      api.reload('00cdc4d6', component.options)
    }
    module.hot.accept("./CloneStartingPoint.vue?vue&type=template&id=00cdc4d6&scoped=true", function () {
      api.rerender('00cdc4d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/fee-schedules/fee-schedules-more/manual-starting-point/CloneStartingPoint.vue"
export default component.exports