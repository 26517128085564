<template>
    <b-card
            style="height: 100%"
    >       
        <b-card-header>
            <h4>Add Fee Schedule Manually</h4>
        </b-card-header>
        <b-card-body>
            <h5> How would you like to start building your manual fee schedule?</h5>
        </b-card-body>

        <b-card-body>
            <!--               text and start button-->
            <!--            sdd border to top and bottom-->
            <b-row class="p-1 border-top border-bottom">
                <b-col>
                    <b-card-text>
                        Clone an existing Fee Schedule to act as a starting point
                    </b-card-text>
                </b-col>
                <b-col>
                    <b-button variant="primary" @click="$router.push({name: 'fee-schedules-manual-clone'})">
                        Start
                    </b-button>
                </b-col>
            </b-row>

            <b-row class="p-1 border-bottom">
                <b-col>
                    <b-card-text>
                        Import a Fee Schedule to act as a starting point
                    </b-card-text>
                </b-col>
                <b-col>
                    <b-button variant="primary">
                        Start
                    </b-button>
                </b-col>
            </b-row>

            <b-row class="p-1 border-bottom">
                <b-col>
                    <b-card-text>
                        Build the Fee Schedule completely from scratch
                    </b-card-text>
                </b-col>
                <b-col>
                    <b-button
                            variant="primary"
                    >
                        Start
                    </b-button>
                </b-col>
            </b-row>


        </b-card-body>
        <b-row>
            <b-col>
                <b-button
                        variant="outline-primary"
                >
                    Cancel
                </b-button>
            </b-col>
        </b-row>



    </b-card>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import {
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BCardGroup,
    BCardHeader,
    BCardText,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol
} from 'bootstrap-vue';

@Component({
    components: {
        BCard,
        BCardText,
        BCardHeader,
        BListGroup,
        BListGroupItem,
        BCardBody,
        BCardTitle,
        BCardFooter,
        BButton,
        BCardGroup,
        BRow,
        BCol
    }
})
export default class ChooseStartingPoint extends Vue {


}
</script>



<style scoped lang="scss">

</style>