var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Required Diagnosis" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Required Diagnosis", rules: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("infinit-select", {
                              ref: "reqDiagnosisSelect",
                              attrs: {
                                repo: _vm.diagnosisRepo,
                                "include-ids":
                                  _vm.procSettingsCopyRef
                                    .requiredDiagnosisSettings,
                                selectable: (x) => x.status == "Active",
                                multiple: true,
                                label: "code",
                              },
                              on: {
                                "option:selecting": _vm.onSelected,
                                "option:deselected": _vm.onDeselected,
                                "insurance-options-update":
                                  _vm.updateDisallowedOptions,
                              },
                              model: {
                                value:
                                  _vm.procSettingsCopyRef
                                    .requiredDiagnosisSettings,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.procSettingsCopyRef,
                                    "requiredDiagnosisSettings",
                                    $$v
                                  )
                                },
                                expression:
                                  "procSettingsCopyRef.requiredDiagnosisSettings",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Secondary Diagnosis" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Secondary Diagnosis", rules: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("infinit-select", {
                              ref: "secondaryDiagnosisSelect",
                              attrs: {
                                repo: _vm.diagnosisRepo,
                                "include-ids":
                                  _vm.procSettingsCopyRef
                                    .secondaryDiagnosisSettings,
                                selectable: (x) => x.status == "Active",
                                multiple: true,
                                label: "code",
                              },
                              on: {
                                "option:selecting": _vm.onSelected,
                                "option:deselected": _vm.onDeselected,
                                "insurance-options-update":
                                  _vm.updateDisallowedOptions,
                              },
                              model: {
                                value:
                                  _vm.procSettingsCopyRef
                                    .secondaryDiagnosisSettings,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.procSettingsCopyRef,
                                    "secondaryDiagnosisSettings",
                                    $$v
                                  )
                                },
                                expression:
                                  "procSettingsCopyRef.secondaryDiagnosisSettings",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Allowed Diagnosis" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Allowed Diagnosis", rules: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("infinit-select", {
                              ref: "allowedDiagnosisSelect",
                              attrs: {
                                repo: _vm.diagnosisRepo,
                                "include-ids":
                                  _vm.procSettingsCopyRef
                                    .allowedDiagnosisSettings,
                                selectable: (x) => x.status == "Active",
                                multiple: true,
                                label: "code",
                                sourceIdField: "diagnosisCodeId",
                              },
                              on: {
                                "option:selecting": _vm.onSelected,
                                "option:deselected": _vm.onDeselected,
                                "insurance-options-update":
                                  _vm.updateDisallowedOptions,
                              },
                              model: {
                                value:
                                  _vm.procSettingsCopyRef
                                    .allowedDiagnosisSettings,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.procSettingsCopyRef,
                                    "allowedDiagnosisSettings",
                                    $$v
                                  )
                                },
                                expression:
                                  "procSettingsCopyRef.allowedDiagnosisSettings",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Disallowed Diagnosis" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Disallowed Diagnosis", rules: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("infinit-select", {
                              ref: "disallowedDiagnosisSelect",
                              attrs: {
                                repo: _vm.diagnosisRepo,
                                "include-ids":
                                  _vm.procSettingsCopyRef
                                    .disallowedDiagnosisSettings,
                                selectable: (x) => x.status == "Active",
                                multiple: true,
                                label: "code",
                              },
                              on: {
                                "option:selecting": _vm.onSelected,
                                "option:deselected": _vm.onDeselected,
                                "insurance-options-update":
                                  _vm.updateDisallowedOptions,
                              },
                              model: {
                                value:
                                  _vm.procSettingsCopyRef
                                    .disallowedDiagnosisSettings,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.procSettingsCopyRef,
                                    "disallowedDiagnosisSettings",
                                    $$v
                                  )
                                },
                                expression:
                                  "procSettingsCopyRef.disallowedDiagnosisSettings",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }