var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form-component",
    {
      attrs: { "no-body": "", "copy-data": _vm.profileContractCopy },
      on: {
        "update:copyData": function ($event) {
          _vm.profileContractCopy = $event
        },
        "update:copy-data": function ($event) {
          _vm.profileContractCopy = $event
        },
        save: _vm.save,
      },
      model: {
        value: _vm.profileContract,
        callback: function ($$v) {
          _vm.profileContract = $$v
        },
        expression: "profileContract",
      },
    },
    [
      _c(
        "b-card-body",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required",
                      name: "Rate Type",
                      vid: "rateType",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Rate Type" } },
                              [
                                _c("b-form-select", {
                                  attrs: { size: "sm", options: _vm.rateTypes },
                                  model: {
                                    value: _vm.profileContractCopy.rateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.profileContractCopy,
                                        "rateType",
                                        $$v
                                      )
                                    },
                                    expression: "profileContractCopy.rateType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.profileContractCopy.rateType ===
                        _vm.ContractRateType.Percent,
                      expression:
                        "profileContractCopy.rateType === ContractRateType.Percent",
                    },
                  ],
                  attrs: { cols: "3" },
                },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required",
                      name: "Medicare",
                      vid: "medicare",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Percent of Medicare" } },
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "input-group-merge" },
                                  [
                                    _c("b-form-input", {
                                      attrs: { size: "sm", type: "number" },
                                      model: {
                                        value:
                                          _vm.profileContractCopy.ratePercent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.profileContractCopy,
                                            "ratePercent",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "profileContractCopy.ratePercent",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      { attrs: { "is-text": "" } },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "PercentIcon" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.profileContractCopy.rateType === _vm.ContractRateType.Percent
                ? _c(
                    "b-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: "required",
                          name: "Fee Schedule Name",
                          vid: "medicareFeeSchedule",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { label: "Medicare Fee Schedule" },
                                    },
                                    [
                                      _c("infinit-select", {
                                        attrs: {
                                          repo: _vm.feeScheduleRepo,
                                          label: "name",
                                          "include-ids": _vm.profileContractCopy
                                            .contractBaseFeeScheduleId
                                            ? [
                                                _vm.profileContractCopy
                                                  .contractBaseFeeScheduleId,
                                              ]
                                            : null,
                                        },
                                        model: {
                                          value:
                                            _vm.profileContractCopy
                                              .contractBaseFeeScheduleId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profileContractCopy,
                                              "contractBaseFeeScheduleId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profileContractCopy.contractBaseFeeScheduleId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1542805534
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("ContactMandatoryTable", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.profileContractCopy.rateType ===
                _vm.ContractRateType.Percent ||
              _vm.profileContractCopy.rateType === _vm.ContractRateType.Fixed,
            expression:
              "profileContractCopy.rateType === ContractRateType.Percent || profileContractCopy.rateType === ContractRateType.Fixed",
          },
        ],
        attrs: { "rate-type": _vm.profileContractCopy.rateType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }