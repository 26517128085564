var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            { staticClass: "pl-2 pt-2" },
            [
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Details")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("DetailsCC", {
                    ref: "profile-details",
                    attrs: {
                      mode: _vm.mode,
                      isCredentialing: _vm.isCredentialing,
                    },
                    on: { save: _vm.save },
                    model: {
                      value: _vm.profile,
                      callback: function ($$v) {
                        _vm.profile = $$v
                      },
                      expression: "profile",
                    },
                  }),
                ],
                1
              ),
              _vm.isProfile
                ? _c(
                    "b-tab",
                    {
                      attrs: { disabled: _vm.disableTabs },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [_vm._v(" Plans Details")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2579398079
                      ),
                    },
                    [_c("PlansDetailsCC", { ref: "plan-details" })],
                    1
                  )
                : _vm._e(),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", disabled: _vm.disableTabs },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Notes")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("notes-tab", {
                    attrs: {
                      filter: {
                        profileId: _vm.profile.id
                          ? _vm.profile.id
                          : _vm.$route.params.itemData,
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }