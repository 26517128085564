<template>
  <FeeSchedulesCC />
</template>

<script lang="ts">
import FeeSchedulesCC from '/src/class-components/fee-schedules/FeeSchedulesCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    FeeSchedulesCC
  }
})
export default class FeeSchedules extends Vue {}
</script>
