var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    attrs: {
      value: _vm.listData.map((x) => x[_vm.sourceIdField]),
      options: _vm.optionsCopy,
      label: _vm.label,
      reduce: (x) => x.id,
      selectable: (x) => x.status == "Active",
      multiple: "",
      required: _vm.required,
      placeholder: "Enter ...",
    },
    on: {
      "option:selecting": _vm.optionSelected,
      "option:deselected": _vm.optionDeselected,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }