<template>
  <InsurancePlansCC />
</template>

<script lang="ts">
import InsurancePlansCC from '/src/class-components/insurance/InsurancePlansCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    InsurancePlansCC
  }
})
export default class InsurancePlans extends Vue {}
</script>
