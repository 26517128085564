var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0 pb-0" },
        [
          _c(
            "tabs-component",
            { attrs: { pills: "" } },
            [
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Detail")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("DetailsCC", {
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { disabled: !_vm.allowed },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Procedure Codes")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("ProcedureCodesCC", {
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "can",
                { attrs: { I: "View", a: "ScrubberProcedureSettings" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { disabled: !_vm.allowed },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Procedure Settings")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("ProcedureSettings", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.formData,
                          callback: function ($$v) {
                            _vm.formData = $$v
                          },
                          expression: "formData",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { disabled: !_vm.allowed },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Activity & Notes")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("ActivityAndNotesCC")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }