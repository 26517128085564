var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form-component",
    {
      attrs: { "copy-data": _vm.feeScheduleCopy, validate: true },
      on: {
        "update:copyData": function ($event) {
          _vm.feeScheduleCopy = $event
        },
        "update:copy-data": function ($event) {
          _vm.feeScheduleCopy = $event
        },
        save: _vm.save,
      },
      model: {
        value: _vm.feeSchedule,
        callback: function ($$v) {
          _vm.feeSchedule = $$v
        },
        expression: "feeSchedule",
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("h4", [_vm._v("Add Fee Schedule")]),
                  ]),
                ],
                1
              ),
              _c("fee-schedule-version-form", {
                attrs: {
                  "fee-schedule-name": _vm.feeScheduleCopy.name,
                  "version-status": _vm.version.status,
                  "version-date-start": _vm.version.dateStart,
                  "version-date-end": _vm.version.dateEnd,
                },
                on: {
                  "update:feeScheduleName": function ($event) {
                    return _vm.$set(_vm.feeScheduleCopy, "name", $event)
                  },
                  "update:fee-schedule-name": function ($event) {
                    return _vm.$set(_vm.feeScheduleCopy, "name", $event)
                  },
                  "update:versionStatus": function ($event) {
                    return _vm.$set(_vm.version, "status", $event)
                  },
                  "update:version-status": function ($event) {
                    return _vm.$set(_vm.version, "status", $event)
                  },
                  "update:versionDateStart": function ($event) {
                    return _vm.$set(_vm.version, "dateStart", $event)
                  },
                  "update:version-date-start": function ($event) {
                    return _vm.$set(_vm.version, "dateStart", $event)
                  },
                  "update:versionDateEnd": function ($event) {
                    return _vm.$set(_vm.version, "dateEnd", $event)
                  },
                  "update:version-date-end": function ($event) {
                    return _vm.$set(_vm.version, "dateEnd", $event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "first-row",
                    fn: function () {
                      return [
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Type" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Type", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.feeScheduleType,
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.feeScheduleCopy.type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.feeScheduleCopy,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "feeScheduleCopy.type",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "second-row",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("br"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("pricing-override-table", {
                attrs: { "hide-edit-and-delete": true },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }