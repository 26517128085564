<template>
  <AttachmentsTabCC />
</template>

<script lang="ts">
import AttachmentsTabCC from '/src/class-components/practices/practices-details/AttachmentsTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    AttachmentsTabCC
  }
})
export default class AttachmentsTab extends Vue {}
</script>
