/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter, DtoModelResponse,
    GeneralStatus,
    ProcedureSettingsDto,
    ProcedureSettingsDtoListResponseModel,
    ProcedureSettingsDtoModelResponse, ProcedureSettingsGroupedByReqModifiersCptDto, ProcSettingsGroupedByCptModsModel,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class ProcedureSettings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name PostProcedureSettings
     * @request POST:/ProcedureSettings/Add
     */
    postProcedureSettings = (data: ProcedureSettingsDto, params: RequestParams = {}) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name PostProcedureSettings
     * @request POST:/ProcedureSettings/Add
     */
    postPost(data: ProcedureSettingsDto, params: RequestParams = {}) {
        return this.postProcedureSettings(data, params);
    }

    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name PostProcedureSettings
     * @request POST:/ProcedureSettings/Add
     */
    postProcedureSettingsGroupedByProcCodeMods = (data: ProcSettingsGroupedByCptModsModel, params: RequestParams = {}) =>
        this.request<DtoModelResponse<ProcedureSettingsGroupedByReqModifiersCptDto>, any>({
            path: `/ProcedureSettings/AddOrUpdateGroupedByProcCodeMods`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name LoadList
     * @request GET:/ProcedureSettings/Load
     */
    loadList = (
        query?: {
            /** @format int64 */
            InsuranceId?: number;
            /** @format int64 */
            ProfilePlanContractId?: number;
            /** @format int64 */
            CptId?: number;
            OnlyInsuranceCpt?: boolean;
            OnlyPracticeCpt?: boolean;
            OnlyCpt?: boolean;
            /** @format int32 */
            Take?: number;
            /** @format int32 */
            Skip?: number;
            IncludeIds?: number[];
            orderByField?: string;
            orderByType?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            /** @format int64 */
            PracticeId?: number;
            ForDropdown?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<ProcedureSettingsDtoListResponseModel, any>({
            path: `/ProcedureSettings/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });


    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name LoadList
     * @request GET:/ProcedureSettings/LoadByInsuranceReqMods
     */
    LoadByInsuranceReqMods = (
        query?: {
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
            InsuranceId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ProcedureSettingsDtoListResponseModel, any>({
            path: `/ProcedureSettings/LoadByInsuranceReqMods`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name LoadList
     * @request GET:/ProcedureSettings/Load
     */
    loadGroupedByReqModifiersCpt = (
        query?: {
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
            InsuranceId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ProcedureSettingsDtoListResponseModel, any>({
            path: `/ProcedureSettings/loadGroupedByReqModifiersCpt`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name LoadList
     * @request GET:/ProcedureSettings/LoadByPracticeJustifiableWriteOff
     */
    LoadByPracticeJustifiableWriteOff = (
        query?: {
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
            InsuranceId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ProcedureSettingsDtoListResponseModel, any>({
            path: `/ProcedureSettings/LoadByPracticeJustifiableWriteOff`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });


    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name GetByIdList
     * @request GET:/ProcedureSettings/GetById
     */
    getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name DeleteDelete
     * @request DELETE:/ProcedureSettings/Delete
     */
    deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name UpdateCreate
     * @request POST:/ProcedureSettings/Update
     */
    updateCreate = (data: ProcedureSettingsDto, params: RequestParams = {}) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name UpdateCreate
     * @request POST:/ProcedureSettings/Update
     */
    updateCreateByInsuranceCpt = (data: ProcedureSettingsDto, params: RequestParams = {}) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/UpdateByInsuranceCpt`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name GetByInsuranceCptList
     * @request GET:/ProcedureSettings/GetByInsuranceCpt
     */
    getByInsuranceCptList = (
        query?: {
            /** @format int64 */
            insuranceId?: number;
            /** @format int64 */
            cptId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/GetByInsuranceCpt`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name GetByInsuranceCptList
     * @request GET:/ProcedureSettings/GetByInsuranceCpt
     */
    getWhatInsuranceCptOverrides = (
        query?: {
            /** @format int64 */
            insuranceId?: number;
            /** @format int64 */
            cptId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/GetWhatInsuranceCptOverrides`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });

    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name GetByCptIdList
     * @request GET:/ProcedureSettings/GetByCptId
     */
    getByCptIdList = (
        query?: {
            /** @format int32 */
            cptId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/GetByCptId`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name UpdateByCptIdCreate
     * @request POST:/ProcedureSettings/UpdateByCptId
     */
    updateByCptIdCreate = (data: ProcedureSettingsDto, params: RequestParams = {}) =>
        this.request<ProcedureSettingsDtoModelResponse, any>({
            path: `/ProcedureSettings/UpdateByCptId`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcedureSettings
     * @name PostProcedureSettings
     * @request POST:/ProcedureSettings/Add
     */
}
