<template>
  <FeeSchedulesEditCC />
</template>

<script lang="ts">
import FeeSchedulesEditCC from '/src/class-components/fee-schedules/fee-schedules-more/FeeSchedulesEditCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    FeeSchedulesEditCC
  }
})
export default class FeeSchedulesEdit extends Vue {}
</script>
