var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "table-component",
    {
      ref: "currentTable",
      attrs: {
        "sidebar-data": _vm.selectedRowCopy,
        "sidebar-open": _vm.sidebar,
        "items-repo": _vm.repo,
        "load-params": { FeeScheduleId: _vm.$route.params.feeScheduleId },
        "is-data-from-model": false,
        "is-add-edit-sidbar": true,
        "get-by-id-on-edit-filter": () => true,
        fields: _vm.fields,
        width: "30%",
      },
      on: {
        "update:sidebarData": function ($event) {
          _vm.selectedRowCopy = $event
        },
        "update:sidebar-data": function ($event) {
          _vm.selectedRowCopy = $event
        },
        "update:sidebarOpen": function ($event) {
          _vm.sidebar = $event
        },
        "update:sidebar-open": function ($event) {
          _vm.sidebar = $event
        },
        save: _vm.save,
      },
      scopedSlots: _vm._u([
        {
          key: "cell(pricingDetails)",
          fn: function (data) {
            return [
              _vm._l(data.value, function (item) {
                return [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(item.modifier ? item.modifier.code : "NONE") +
                        " "
                    ),
                  ]),
                ]
              }),
            ]
          },
        },
        {
          key: "cell(pricingDetails.facilityPrice)",
          fn: function (data) {
            return [
              _vm._l(data.item.pricingDetails, function (item) {
                return [
                  _c("div", [
                    _vm._v(" " + _vm._s("$" + item.facilityPrice) + " "),
                  ]),
                ]
              }),
            ]
          },
        },
        {
          key: "cell(pricingDetails.nonFacilityPrice)",
          fn: function (data) {
            return [
              _vm._l(data.item.pricingDetails, function (item) {
                return [
                  _c("div", [
                    _vm._v(" " + _vm._s("$" + item.nonFacilityPrice) + " "),
                  ]),
                ]
              }),
            ]
          },
        },
      ]),
      model: {
        value: _vm.selectedRow,
        callback: function ($$v) {
          _vm.selectedRow = $$v
        },
        expression: "selectedRow",
      },
    },
    [
      [
        _c(
          "b-container",
          [
            _c(
              "b-row",
              { staticClass: "py-2" },
              [
                _c("b-col", [
                  _c("header", [_c("h4", [_vm._v("Edit Procedure Price")])]),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Procedure Code" } },
                      [
                        _c("infinit-select", {
                          model: {
                            value: _vm.selectedRow.cpt,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedRow, "cpt", $$v)
                            },
                            expression: "selectedRow.cpt",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Description" } },
                      [
                        _c("b-form-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.cptDescription,
                            callback: function ($$v) {
                              _vm.cptDescription = $$v
                            },
                            expression: "cptDescription",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "my-1" },
              [
                _c(
                  "b-col",
                  [
                    _c("b-table", {
                      attrs: {
                        items: _vm.selectedRowCopy.pricingDetails,
                        fields: [
                          {
                            key: "modifier",
                            label: "Modifiers",
                            sortable: false,
                          },
                          {
                            key: "facilityPrice",
                            label: "Facility Price",
                            sortable: false,
                          },
                          {
                            key: "nonFacilityPrice",
                            label: "Non-Facility Price",
                            sortable: false,
                          },
                        ],
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(modifier)",
                          fn: function (data) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.item.modifier
                                        ? data.item.modifier.code
                                        : "NONE"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(facilityPrice)",
                          fn: function (data) {
                            return [
                              _c("b-form-input", {
                                attrs: {
                                  value: data.item.overridePrice
                                    ? data.item.overridePrice.facilityPrice
                                    : "",
                                },
                                on: {
                                  update: function ($event) {
                                    return _vm.overridFacilityPriceUpdate(
                                      data.item,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "cell(nonFacilityPrice)",
                          fn: function (data) {
                            return [
                              _c("b-form-input", {
                                attrs: {
                                  value: data.item.overridePrice
                                    ? data.item.overridePrice.nonFacilityPrice
                                    : "",
                                },
                                on: {
                                  update: function ($event) {
                                    return _vm.overrideNonFacilityPriceUpdate(
                                      data.item,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }