var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.label } },
        [
          _c("validation-provider", {
            attrs: { name: _vm.label, rules: !_vm.editButtonAllow ? "" : "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          ref: "input",
                          attrs: {
                            type: _vm.type,
                            placeholder: _vm.overrideField
                              ? _vm.overrideField.toString()
                              : "",
                            disabled: _vm.editButtonAllow,
                          },
                          model: {
                            value: _vm.inputField,
                            callback: function ($$v) {
                              _vm.inputField = $$v
                            },
                            expression: "inputField",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          { attrs: { "is-text": "" } },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer text-secondary",
                              attrs: { icon: "EditIcon" },
                              on: { click: _vm.onEditClick },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }