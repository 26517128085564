<template>
  <FeeSchedulesAddCC />
</template>

<script lang="ts">
import FeeSchedulesAddCC from '/src/class-components/fee-schedules/fee-schedules-more/FeeSchedulesAddCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    FeeSchedulesAddCC
  }
})
export default class FeeSchedulesAdd extends Vue {}
</script>
