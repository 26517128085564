var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: { copyData: _vm.procSettingsCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.procSettingsCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.procSettingsCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-alert",
                      {
                        staticClass: "mb-2 col-md-12",
                        attrs: { variant: "primary", show: "" },
                      },
                      [
                        _c("div", { staticClass: "alert-body" }, [
                          _c(
                            "span",
                            [
                              _c("feather-icon", {
                                staticClass: "mr-25",
                                attrs: { icon: "AlertCircleIcon", size: "18" },
                              }),
                              _vm._v(" Data on this page is derived from the "),
                              _c("strong", [_vm._v("procedure type level")]),
                              _vm._v(
                                " , you can override specific fields by clicking the "
                              ),
                              _c("feather-icon", {
                                staticClass:
                                  "ml-30 mr-30 secondary-text cursor-pointer",
                                attrs: { icon: "EditIcon", size: "16" },
                              }),
                              _vm._v(" icon "),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("input-override", {
                          attrs: {
                            label: "Max. Daily claim limit",
                            overrideData: _vm.generalSettings.dailyLimitDefault,
                            type: "number",
                          },
                          model: {
                            value: _vm.procSettingsCopy.maxDailyLimit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.procSettingsCopy,
                                "maxDailyLimit",
                                $$v
                              )
                            },
                            expression: "procSettingsCopy.maxDailyLimit",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" },
                            ],
                            attrs: {
                              title:
                                "set this if this procedure is usually done more than once (e.g. right and left)",
                              label: "Min. Daily claim limit",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "Min. Daily claim limit",
                                rules: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "minDailyLimit",
                                          type: "number",
                                        },
                                        model: {
                                          value:
                                            _vm.procSettingsCopy.minDailyLimit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.procSettingsCopy,
                                              "minDailyLimit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "procSettingsCopy.minDailyLimit",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("single-select-override", {
                          attrs: {
                            label: "Modifiers to use when within daily limit",
                            optionsRepo: _vm.modifiersRepo,
                            optionsLabel: "code",
                            overrideData:
                              _vm.generalSettings.dailyLimitDefaultModifierCode,
                          },
                          model: {
                            value:
                              _vm.procSettingsCopy.dailyLimitDefaultModifierId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.procSettingsCopy,
                                "dailyLimitDefaultModifierId",
                                $$v
                              )
                            },
                            expression:
                              "procSettingsCopy.dailyLimitDefaultModifierId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" },
                            ],
                            attrs: {
                              title:
                                "set this if this procedure could be done on the right and left side with RT and LT modifiers",
                              label: "Is RT / LT applicable",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Is RT/LT applicable", rules: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          id: "isRTLTApplicable",
                                          switch: "",
                                        },
                                        model: {
                                          value:
                                            _vm.procSettingsCopy
                                              .isRTLTApplicable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.procSettingsCopy,
                                              "isRTLTApplicable",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "procSettingsCopy.isRTLTApplicable",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("single-select-override", {
                          directives: [
                            { name: "b-tooltip", rawName: "v-b-tooltip" },
                          ],
                          attrs: {
                            title:
                              "set this if the min. daily limit is set to more than 1 to determine if the multiplication is done by the number of procedures or the number of units",
                            label: "Line type",
                            overrideData:
                              _vm.generalSettings.dailyLimitDefaultLineType,
                            isOptionsFromRepo: false,
                            "options-enum": _vm.dailyLimitLineType,
                            "is-options-enum": true,
                          },
                          model: {
                            value:
                              _vm.procSettingsCopy.dailyLimitDefaultLineType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.procSettingsCopy,
                                "dailyLimitDefaultLineType",
                                $$v
                              )
                            },
                            expression:
                              "procSettingsCopy.dailyLimitDefaultLineType",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.procSettings,
      callback: function ($$v) {
        _vm.procSettings = $$v
      },
      expression: "procSettings",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }