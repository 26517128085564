<template>
  <ProcedureTypesCC />
</template>

<script lang="ts">
import ProcedureTypesCC from '/src/class-components/procedures/ProcedureTypesCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    ProcedureTypesCC
  }
})
export default class ProcedureTypes extends Vue {}
</script>
