var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle
    ? _c(
        "b-row",
        { staticClass: "content-header" },
        [
          _c(
            "b-col",
            {
              staticClass: "content-header-left mb-2",
              attrs: { cols: "12", md: "9" },
            },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "content-header-title float-left pr-1 mb-0",
                      },
                      [_vm._v(" " + _vm._s(_vm.$route.meta.pageTitle) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c(
                              "b-breadcrumb-item",
                              { attrs: { to: "/" } },
                              [
                                _c("feather-icon", {
                                  staticClass: "align-text-top",
                                  attrs: { icon: "HomeIcon", size: "16" },
                                }),
                              ],
                              1
                            ),
                            _vm._l(
                              typeof _vm.$route.meta.breadcrumb === "function"
                                ? _vm.$route.meta.breadcrumb(_vm.$route)
                                : _vm.$route.meta.breadcrumb,
                              function (item) {
                                return _c(
                                  "b-breadcrumb-item",
                                  {
                                    key: item.text,
                                    attrs: { active: item.active, to: item.to },
                                  },
                                  [_vm._v(" " + _vm._s(item.text) + " ")]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-col", {
            staticClass:
              "content-header-right text-md-right d-md-block d-none mb-1",
            attrs: { md: "3", cols: "12" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }