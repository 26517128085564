/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  GeneralStatus,
  ProviderProfileDto,
  ProviderProfileDtoListResponseModel,
  ProviderProfileDtoModelResponse,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ProviderProfile<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ProviderProfile
   * @name PostProviderProfile
   * @request POST:/ProviderProfile/Add
   */
  postProviderProfile = (data: ProviderProfileDto, params: RequestParams = {}) =>
    this.request<ProviderProfileDtoModelResponse, any>({
      path: `/ProviderProfile/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProviderProfile
   * @name LoadList
   * @request GET:/ProviderProfile/Load
   */
  loadList = (
    query?: {
      /** @format int32 */
      Take?: number;
      /** @format int32 */
      Skip?: number;
      IncludeIds?: number[];
      orderByField?: string;
      orderByType?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      /** @format int64 */
      PracticeId?: number;
      ForDropdown?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProviderProfileDtoListResponseModel, any>({
      path: `/ProviderProfile/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProviderProfile
   * @name GetByIdList
   * @request GET:/ProviderProfile/GetById
   */
  getByIdList = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProviderProfileDtoModelResponse, any>({
      path: `/ProviderProfile/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProviderProfile
   * @name DeleteDelete
   * @request DELETE:/ProviderProfile/Delete
   */
  deleteDelete = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProviderProfileDtoModelResponse, any>({
      path: `/ProviderProfile/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProviderProfile
   * @name UpdateCreate
   * @request POST:/ProviderProfile/Update
   */
  updateCreate = (data: ProviderProfileDto, params: RequestParams = {}) =>
    this.request<ProviderProfileDtoModelResponse, any>({
      path: `/ProviderProfile/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
