/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { StringListResponseModel, UserInfoModelResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Permissions<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Permissions
     * @name GetPermissionsList
     * @request GET:/Permissions/GetPermissions
     * @secure
     */
    getPermissionsList = (params: RequestParams = {}) =>
        this.request<UserInfoModelResponse, any>({
            path: `/Permissions/GetPermissions`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Permissions
     * @name GetResourcesList
     * @request GET:/Permissions/GetResources
     * @secure
     */
    getResourcesList = (params: RequestParams = {}) =>
        this.request<StringListResponseModel, any>({
            path: `/Permissions/GetResources`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
}
