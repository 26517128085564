var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: { copyData: _vm.procSettingsCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.procSettingsCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.procSettingsCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "8", xs: "12" } },
                  [
                    _c("Diagnosis", {
                      attrs: { "proc-settings-copy": _vm.procSettingsCopy },
                      on: {
                        "update:procSettingsCopy": function ($event) {
                          _vm.procSettingsCopy = $event
                        },
                        "update:proc-settings-copy": function ($event) {
                          _vm.procSettingsCopy = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.procSettings,
      callback: function ($$v) {
        _vm.procSettings = $$v
      },
      expression: "procSettings",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }