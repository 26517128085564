var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "table-component",
        {
          attrs: {
            "items-repo": _vm.procBudleRepo,
            isDataFromModel: false,
            sidebarData: _vm.bundle,
            fields: _vm.fields,
            isAddEditSidbar: true,
            sidebarOpen: _vm.sidebar,
            copyData: _vm.bundleCopy,
            "do-save": true,
            validate: true,
            loadFuncName: "getGroupedBundlesList",
            width: " 30%",
            "get-by-id-field": "procCodeId",
          },
          on: {
            "update:sidebarData": function ($event) {
              _vm.bundle = $event
            },
            "update:sidebar-data": function ($event) {
              _vm.bundle = $event
            },
            "update:sidebarOpen": function ($event) {
              _vm.sidebar = $event
            },
            "update:sidebar-open": function ($event) {
              _vm.sidebar = $event
            },
            "update:copyData": function ($event) {
              _vm.bundleCopy = $event
            },
            "update:copy-data": function ($event) {
              _vm.bundleCopy = $event
            },
          },
        },
        [
          [
            _c(
              "b-container",
              { staticClass: "p-1" },
              [
                _c(
                  "b-row",
                  [_c("b-col", [_c("h4", [_vm._v("Add Procedure Bundle")])])],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("div", [
                        _vm._v(
                          "Select witch codes you want to include in this bundle"
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              label: "Procedure Code",
                              "label-for": "procCode",
                            },
                          },
                          [
                            _c("infinit-select", {
                              attrs: {
                                label: "procCode",
                                repo: _vm.procCodeRepo,
                                "include-ids": [_vm.bundleCopy.procCodeId],
                                disabled: _vm.bundle.id != null,
                              },
                              model: {
                                value: _vm.bundleCopy.procCodeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bundleCopy, "procCodeId", $$v)
                                },
                                expression: "bundleCopy.procCodeId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  {
                    staticClass: "mt-2",
                    staticStyle: {
                      "border-top-color": "#b8c2cc",
                      "border-top-width": "1px",
                      "border-top-style": "solid",
                    },
                  },
                  [
                    _c(
                      "b-col",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "my-1 pl-2",
                            attrs: { md: "6", sm: "6", xs: "6" },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0 d-flex align-items-center",
                                attrs: {
                                  label: "Search",
                                  "label-cols-sm": "2",
                                  "label-align-sm": "left",
                                  "label-size": "sm",
                                  "label-for": "filterInput",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  {
                                    staticClass: "input-group-merge",
                                    attrs: { size: "sm" },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "filterInput",
                                        type: "search",
                                        placeholder: "Type to Search",
                                      },
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      { attrs: { "is-text": "" } },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "SearchIcon" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("b-table", {
                          attrs: {
                            bordered: "",
                            striped: "",
                            fields: [
                              {
                                key: "bundledProcCode",
                                label: "Bundled Pro. Code",
                              },
                              { key: "type", label: "Type" },
                              {
                                key: "overwrittenType",
                                label: "Overwritten Type",
                              },
                            ],
                            items: _vm.bundleCopy.bundledProcCodes,
                            "current-page": _vm.currentPage,
                            "per-page": 10,
                            filter: _vm.filter,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(bundledProcCode)",
                              fn: function (row) {
                                return [
                                  row.item.bundledProcCodeId &&
                                  row.item.bundledProcCode
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(row.item.bundledProcCode) + " "
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c("infinit-select", {
                                            attrs: {
                                              label: "procCode",
                                              repo: _vm.procCodeRepo,
                                            },
                                            model: {
                                              value: row.item.bundledProcCodeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "bundledProcCodeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "row.item.bundledProcCodeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                            {
                              key: "cell(type)",
                              fn: function (row) {
                                return [
                                  row.item.type && row.item.id
                                    ? _c("div", [_vm._v(_vm._s(row.item.type))])
                                    : _c(
                                        "div",
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.typeOptions,
                                              size: "sm",
                                              required: "",
                                            },
                                            model: {
                                              value: row.item.type,
                                              callback: function ($$v) {
                                                _vm.$set(row.item, "type", $$v)
                                              },
                                              expression: "row.item.type",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                            {
                              key: "cell(overwrittenType)",
                              fn: function (row) {
                                return [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.typeOptions,
                                      size: "sm",
                                      required: "",
                                    },
                                    model: {
                                      value: row.item.overwrittenType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          row.item,
                                          "overwrittenType",
                                          $$v
                                        )
                                      },
                                      expression: "row.item.overwrittenType",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("b-pagination", {
                          staticClass: "mx-2",
                          attrs: {
                            "total-rows": _vm.bundle.bundledProcCodes
                              ? _vm.bundle.bundledProcCodes.length
                              : 0,
                            "per-page": 10,
                            size: "sm",
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              type: "button",
                              variant: "outline-primary",
                              size: "sm",
                              pill: "",
                            },
                            on: { click: _vm.addBundledProcCode },
                          },
                          [
                            _c("feather-icon", { attrs: { icon: "PlusIcon" } }),
                            _vm._v(" Add "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }