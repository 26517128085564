import { render, staticRenderFns } from "./ActivityAndNotesCC.vue?vue&type=template&id=edf8b414&scoped=true"
import script from "./ActivityAndNotesCC.vue?vue&type=script&lang=ts"
export * from "./ActivityAndNotesCC.vue?vue&type=script&lang=ts"
import style0 from "./ActivityAndNotesCC.vue?vue&type=style&index=0&id=edf8b414&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edf8b414",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('edf8b414')) {
      api.createRecord('edf8b414', component.options)
    } else {
      api.reload('edf8b414', component.options)
    }
    module.hot.accept("./ActivityAndNotesCC.vue?vue&type=template&id=edf8b414&scoped=true", function () {
      api.rerender('edf8b414', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/procedures/add-procedures-more/ActivityAndNotesCC.vue"
export default component.exports