<template>
  <div>
    <b-form-group :label="label">
      <validation-provider
          v-slot="{ errors }"
          :name="label"
          :rules="!editButtonAllow ? '' : ''"
      >
        <b-input-group>
          <b-form-input
              ref="input"
              :type="type"
              v-model="inputField"
              :placeholder="overrideField ? overrideField.toString() : ''"
              :disabled="editButtonAllow"
          />
          <b-input-group-append is-text>
            <feather-icon
                icon="EditIcon"
                class="cursor-pointer text-secondary"
                @click="onEditClick"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import {
  Component,
  ModelSync,
  Prop,
  PropSync,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import {required} from "vee-validate/dist/rules";
import _ from "lodash";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroup,
    BInputGroupAppend,

    BFormGroup,
    BFormInput,
  },
})
export default class InputOverride extends Vue {
  @ModelSync("inputData", "input")
  inputField!: Object | null;

  @Prop()
  label!: String;

  @Prop()
  type!: String;

  @PropSync("overrideData")
  overrideField!: Object;

  editButtonAllow = true;

  @Watch("inputField")
  inputFieldWatch() {
    console.log(this.inputField)
    if (_.isEmpty(this.inputField) == false || _.isNumber(this.inputField)) {
      console.log(this.inputField)
      this.editButtonAllow = false;
    } else if (this.inputField == "") {
      this.inputField = null;
    }
  }

  @Ref("input") readonly refs_input!: any;

  onEditClick() {
    if (this.editButtonAllow == false) {
      this.inputField = null;

    } else {
      console.log(this.refs_input)
      this.refs_input.focus()
    }
    this.editButtonAllow = !this.editButtonAllow;
  }
}
</script>

<style lang="scss" scoped></style>