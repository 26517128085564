var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            {
              staticClass: "pl-2",
              scopedSlots: _vm._u([
                {
                  key: "tabs-end",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("infinit-select", {
                            attrs: {
                              repo: _vm.insuranceRepo,
                              filter: { CptId: +_vm.$route.params.id },
                              label: "code",
                              placeholder: "Select Insurance",
                            },
                            model: {
                              value: _vm.selectedInsurance,
                              callback: function ($$v) {
                                _vm.selectedInsurance = $$v
                              },
                              expression: "selectedInsurance",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-tab",
                { ref: "tab1", attrs: { title: "General" } },
                [
                  _c("GeneralCC", {
                    attrs: {
                      procedureSettings: _vm.procSettings,
                      procedureType: _vm.procType,
                    },
                    on: { save: _vm.save },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { ref: "tab2", attrs: { title: "Daily Limits" } },
                [
                  _c("DailyLimitsCC", {
                    attrs: {
                      procedureSettings: _vm.procSettings,
                      generalSettings: _vm.generalSettings,
                      procedureType: _vm.procType,
                    },
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { ref: "tab3", attrs: { title: "Diagnosis Req." } },
                [
                  _c("DiagnosisReqCC", {
                    attrs: {
                      procedureSettings: _vm.procSettings,
                      procedureType: _vm.procType,
                    },
                    on: {
                      "update:procedureSettings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedure-settings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedureType": function ($event) {
                        _vm.procType = $event
                      },
                      "update:procedure-type": function ($event) {
                        _vm.procType = $event
                      },
                      save: _vm.save,
                    },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { ref: "tab4", attrs: { title: "Modifiers Req." } },
                [
                  _c("ModifiersReqCC", {
                    attrs: { procedureSettings: _vm.procSettings },
                    on: {
                      "update:procedureSettings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedure-settings": function ($event) {
                        _vm.procSettings = $event
                      },
                      save: _vm.save,
                    },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { ref: "tab5", attrs: { title: "Place of service" } },
                [
                  _c("PlaceOfServiceCC", {
                    attrs: { procedureSettings: _vm.procSettings },
                    on: {
                      "update:procedureSettings": function ($event) {
                        _vm.procSettings = $event
                      },
                      "update:procedure-settings": function ($event) {
                        _vm.procSettings = $event
                      },
                      save: _vm.save,
                    },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }