var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("table-component", {
    ref: "tableComponent",
    attrs: {
      fields: _vm.fields,
      "items-repo": _vm.corpFeeScheduleRepo,
      "load-params": { corporationId: _vm.$route.params.itemData },
      "is-data-from-model": false,
      "is-add-edit-sidbar": true,
      "copy-data": _vm.corpFeeScheduleCopy,
      "sidebar-data": _vm.corpFeeSchedule,
      "sidebar-open": _vm.sidebar,
      validate: true,
      "do-save": true,
      "get-by-id-on-edit-filter": () => true,
    },
    on: {
      "update:copyData": function ($event) {
        _vm.corpFeeScheduleCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.corpFeeScheduleCopy = $event
      },
      "update:sidebarOpen": function ($event) {
        _vm.sidebar = $event
      },
      "update:sidebar-open": function ($event) {
        _vm.sidebar = $event
      },
      onDelete: _vm.onDelete,
      "on-before-save": _vm.onBeforeSave,
    },
    scopedSlots: _vm._u([
      {
        key: "cell(name)",
        fn: function (data) {
          return [_vm._v(" " + _vm._s(data.item.feeSchedule.name) + " ")]
        },
      },
      {
        key: "cell(contractType)",
        fn: function (data) {
          return [
            _vm._v(
              " " +
                _vm._s(
                  data.item.contractType == _vm.ContractType.OutOfNetwork
                    ? "Out of Network"
                    : data.item.contractType == _vm.ContractType.InNetwork
                    ? "In Network"
                    : ""
                ) +
                " "
            ),
          ]
        },
      },
      {
        key: "cell(startenddate)",
        fn: function (data) {
          return [
            _vm._v(
              " " +
                _vm._s(
                  data.item.dateStart
                    ? new Date(data.item.dateStart).toLocaleDateString()
                    : ""
                ) +
                " - " +
                _vm._s(
                  data.item.dateEnd
                    ? new Date(data.item.dateEnd).toLocaleDateString()
                    : ""
                ) +
                " "
            ),
          ]
        },
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-container",
              { staticClass: "pt-2" },
              [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm.corpFeeScheduleCopy.id
                        ? "Edit Fee Schedule"
                        : "Add Fee Schedule"
                    )
                  ),
                ]),
                _c(
                  "b-row",
                  { staticClass: "pt-2" },
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Fee Schedule Name" } },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "Fee Schedule Name",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("infinit-select", {
                                        attrs: {
                                          repo: _vm.feeScheduleRepo,
                                          "include-ids": [
                                            _vm.corpFeeScheduleCopy
                                              .feeScheduleId,
                                          ],
                                          label: "name",
                                        },
                                        model: {
                                          value:
                                            _vm.corpFeeScheduleCopy
                                              .feeScheduleId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.corpFeeScheduleCopy,
                                              "feeScheduleId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "corpFeeScheduleCopy.feeScheduleId",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mt-2",
                            attrs: { label: "In\\Out Network" },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "In\\Out Network",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-select", {
                                        attrs: {
                                          options: Object.keys(
                                            _vm.ContractType
                                          ).map((key) => ({
                                            text: _vm.ContractType[key],
                                            value: _vm.ContractType[key],
                                          })),
                                          required: "",
                                        },
                                        model: {
                                          value:
                                            _vm.corpFeeScheduleCopy
                                              .contractType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.corpFeeScheduleCopy,
                                              "contractType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "corpFeeScheduleCopy.contractType",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Start Date" } },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Start Date", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          "weekday-header-format": "narrow",
                                          required: "",
                                        },
                                        model: {
                                          value:
                                            _vm.corpFeeScheduleCopy.dateStart,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.corpFeeScheduleCopy,
                                              "dateStart",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "corpFeeScheduleCopy.dateStart",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "End Date" } },
                          [
                            _c("validation-provider", {
                              attrs: { name: "End Date", rules: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-datepicker", {
                                        model: {
                                          value:
                                            _vm.corpFeeScheduleCopy.dateEnd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.corpFeeScheduleCopy,
                                              "dateEnd",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "corpFeeScheduleCopy.dateEnd",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Status" } },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Status", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-select", {
                                        attrs: {
                                          options: Object.keys(
                                            _vm.DateDependantStatus
                                          )
                                            .filter(
                                              (x) =>
                                                x !==
                                                _vm.DateDependantStatus.Deleted
                                            )
                                            .map((key) => ({
                                              text: _vm.DateDependantStatus[
                                                key
                                              ],
                                              value:
                                                _vm.DateDependantStatus[key],
                                            })),
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.corpFeeScheduleCopy.status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.corpFeeScheduleCopy,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "corpFeeScheduleCopy.status",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }