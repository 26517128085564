var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.label } },
        [
          _c("validation-provider", {
            attrs: {
              name: _vm.label,
              rules: !_vm.disabled && _vm.required ? "required" : "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("b-form-input", {
                      attrs: {
                        type: _vm.type,
                        name: _vm.label,
                        placeholder: _vm.placeholder,
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.inputData,
                        callback: function ($$v) {
                          _vm.inputData = $$v
                        },
                        expression: "inputData",
                      },
                    }),
                    _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }