var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.label } },
        [
          _c("validation-provider", {
            attrs: { name: _vm.label, rules: !_vm.editButtonAllow ? "" : "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    !_vm.isOptionsEnum
                      ? _c(
                          "single-select",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  relationField: _vm.relationField,
                                  idsToDisable: _vm.idsToDisable,
                                  optionsLabel: _vm.optionsLabel,
                                  optionsRepo: _vm.optionsRepo,
                                  placeholder: _vm.overrideData,
                                  disabled: _vm.editButtonAllow,
                                  isOptionsFromRepo: _vm.isOptionsFromRepo,
                                  options: _vm.options,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "open-indicator",
                                      fn: function ({ attributes }) {
                                        return [
                                          _c(
                                            "b-input-group-addon",
                                            { attrs: { "is-text": "" } },
                                            [
                                              _c("feather-icon", {
                                                staticClass:
                                                  "cursor-pointer text-secondary",
                                                attrs: { icon: "EditIcon" },
                                                on: { click: _vm.onEditClick },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.formData,
                                  callback: function ($$v) {
                                    _vm.formData = $$v
                                  },
                                  expression: "formData",
                                },
                              },
                              "single-select",
                              _vm.$attrs,
                              false
                            ),
                            _vm.$listeners
                          )
                        )
                      : _c(
                          "v-select",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  options: _vm.options,
                                  label: "text",
                                  reduce: (option) => option.value,
                                  placeholder: _vm.overrideData,
                                  disabled: _vm.editButtonAllow,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "open-indicator",
                                    fn: function ({ attributes }) {
                                      return [
                                        _c(
                                          "b-input-group-addon",
                                          { attrs: { "is-text": "" } },
                                          [
                                            _c("feather-icon", {
                                              staticClass:
                                                "cursor-pointer text-secondary",
                                              attrs: { icon: "EditIcon" },
                                              on: { click: _vm.onEditClick },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.formData,
                                  callback: function ($$v) {
                                    _vm.formData = $$v
                                  },
                                  expression: "formData",
                                },
                              },
                              "v-select",
                              _vm.$attrs,
                              false
                            ),
                            _vm.$listeners
                          )
                        ),
                    _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }