var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "is-data-from-model": false,
          "sidebar-data": _vm.selected,
          "copy-data": _vm.copy,
          "is-add-edit-sidbar": false,
          fields: _vm.fields,
          "items-repo": _vm.patientInsuranceRepo,
          "load-params": { patientId: _vm.$route.params.id },
          "selected-variant": "dark",
          striped: "false",
          "is-selectable": true,
          "select-mode": "single",
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selected = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selected = $event
          },
          "update:copyData": function ($event) {
            _vm.copy = $event
          },
          "update:copy-data": function ($event) {
            _vm.copy = $event
          },
          "row-selected": _vm.rowSelected,
        },
      }),
      _vm.selected.id
        ? _c(
            "form-component",
            {
              attrs: { "copy-data": _vm.copy },
              on: {
                "update:copyData": function ($event) {
                  _vm.copy = $event
                },
                "update:copy-data": function ($event) {
                  _vm.copy = $event
                },
              },
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Carrier" } },
                        [
                          _c("infinit-select", {
                            attrs: {
                              repo: _vm.practiceInsuranceRepo,
                              label: "insuranceCode",
                              "include-ids": [_vm.selected.practiceInsuranceId],
                              filter: { PracticeId: _vm.selected.practiceId },
                              required: "",
                            },
                            model: {
                              value: _vm.selected.practiceInsuranceId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selected,
                                  "practiceInsuranceId",
                                  $$v
                                )
                              },
                              expression: "selected.practiceInsuranceId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { label: "Coverage" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              options: Object.values(_vm.Coverage).map((x) => ({
                                text: x,
                                value: x,
                              })),
                              "text-field": "text",
                              "value-field": "value",
                              required: "",
                            },
                            model: {
                              value: _vm.selected.coverage,
                              callback: function ($$v) {
                                _vm.$set(_vm.selected, "coverage", $$v)
                              },
                              expression: "selected.coverage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Effective start date" } },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: { required: "" },
                                    model: {
                                      value: _vm.selected.beginDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.selected, "beginDate", $$v)
                                      },
                                      expression: "selected.beginDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Effective end date" } },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: { type: "date", required: "" },
                                    model: {
                                      value: _vm.selected.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.selected, "endDate", $$v)
                                      },
                                      expression: "selected.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Subscriber name" } },
                                [
                                  _c("b-form-input", {
                                    attrs: { required: "" },
                                    model: {
                                      value: _vm.selectedSubscriber.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedSubscriber,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "selectedSubscriber.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Relationship" } },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.RelationshipType,
                                      "text-field": "text",
                                      "value-field": "value",
                                    },
                                    model: {
                                      value: _vm.relationshipType,
                                      callback: function ($$v) {
                                        _vm.relationshipType = $$v
                                      },
                                      expression: "relationshipType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Subscriber ID" } },
                                [
                                  _c("b-form-input", {
                                    attrs: { required: "" },
                                    model: {
                                      value: _vm.selectedSubscriber.id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedSubscriber,
                                          "id",
                                          $$v
                                        )
                                      },
                                      expression: "selectedSubscriber.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Guarantor" } },
                                [
                                  _c("infinit-select", {
                                    attrs: {
                                      repo: _vm.relatedPersonRepo,
                                      label: "firstName",
                                      "include-ids": [_vm.selected.guarantorId],
                                      filter: {
                                        patientId: _vm.selected.patientId,
                                      },
                                    },
                                    model: {
                                      value: _vm.selected.guarantorId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selected,
                                          "guarantorId",
                                          $$v
                                        )
                                      },
                                      expression: "selected.guarantorId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Co-insurance amount" } },
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.selected.copay,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.selected, "copay", $$v)
                                      },
                                      expression: "selected.copay",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Co-insurance type" } },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: { value: "Fixed" },
                                      model: {
                                        value: _vm.selected.copayType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selected,
                                            "copayType",
                                            $$v
                                          )
                                        },
                                        expression: "selected.copayType",
                                      },
                                    },
                                    [_vm._v("Dollar")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: { value: "Percent" },
                                      model: {
                                        value: _vm.selected.copayType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selected,
                                            "copayType",
                                            $$v
                                          )
                                        },
                                        expression: "selected.copayType",
                                      },
                                    },
                                    [_vm._v("Percentage")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Co-pay amount" } },
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: (
                                        _vm.selected.lastEligibility || {}
                                      ).inCoInsurance,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selected.lastEligibility || {},
                                          "inCoInsurance",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "(selected.lastEligibility ||{}).inCoInsurance",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Deductible balance" } },
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: (
                                        _vm.selected.lastEligibility || {}
                                      ).inDeductibleRemaining,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selected.lastEligibility || {},
                                          "inDeductibleRemaining",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "(selected.lastEligibility ||{}).inDeductibleRemaining",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("Address", {
                                attrs: { label: "Subscriber address" },
                                model: {
                                  value: _vm.guarantor.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.guarantor, "address", $$v)
                                  },
                                  expression: "guarantor.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }