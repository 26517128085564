<template>
  <FeeSchedulesViewOneCC />
</template>

<script lang="ts">
import FeeSchedulesViewOneCC from '/src/class-components/fee-schedules/fee-schedules-more/FeeSchedulesViewOneCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    FeeSchedulesViewOneCC
  }
})
export default class FeeSchedulesViewOne extends Vue {}
</script>
