var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0 pb-0" },
        [
          _c(
            "tabs-component",
            {
              attrs: { "base-route": { name: "proc-codes-update" }, pills: "" },
            },
            [
              _c(
                "Can",
                { attrs: { I: "View", a: "ScrubberProcedureSettings" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { active: "", title: "Details" } },
                    [
                      _c("DetailsCC", {
                        attrs: { dataSource: _vm.procedureCode },
                        on: { save: _vm.save },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Settings",
                        disabled: !_vm.allowed,
                        to: { name: "proc-codes-update-settings" },
                      },
                    },
                    [
                      _c("Settings", {
                        attrs: { sourceData: _vm.procedureCode },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        disabled: !_vm.allowed,
                        title: "Procedure Info",
                      },
                    },
                    [_c("ProcedureInfoCC")],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        disabled: !_vm.allowed,
                        title: "Activity & Notes",
                      },
                    },
                    [_c("ActivityAndNotesCC")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }