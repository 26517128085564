var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          getByIdOnEditFilter: () => false,
          isDataFromModel: false,
          isAddEditSidbar: false,
          copyData: _vm.copyData,
          sidebarData: _vm.sidebarData,
          addEditLink: {
            name: "ins-companies-details-procedure-settings",
            params: {
              id: _vm.route.params.id,
              cptId: _vm.sidebarData.cptId,
              procSettingId: _vm.sidebarData.id ? _vm.sidebarData.id : "new",
            },
          },
          fields: _vm.fields,
          itemsRepo: _vm.procSettingsRepo,
          "load-params": {
            OnlyInsuranceCpt: true,
            insuranceId: _vm.route.params.id,
          },
        },
        on: {
          "update:copyData": function ($event) {
            _vm.copyData = $event
          },
          "update:copy-data": function ($event) {
            _vm.copyData = $event
          },
          "update:sidebarData": function ($event) {
            _vm.sidebarData = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.sidebarData = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.selectedCptId = null
                            _vm.$bvModal.show("modal-1")
                          },
                        },
                      },
                      [_vm._v("Add")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "b-modal",
        {
          attrs: { id: "modal-1", title: "Add Procedure Settings" },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          ok()
                          _vm.$bvModal.hide("modal-1")
                          _vm.$router.push({
                            name: "ins-companies-details-procedure-settings",
                            params: {
                              id: _vm.route.params.id,
                              cptId: _vm.selectedCptId,
                              procSettingId: "new",
                            },
                          })
                        },
                      },
                    },
                    [_vm._v(" Add ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: {
                        click: function ($event) {
                          cancel()
                          _vm.$bvModal.hide("modal-1")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c("infinit-select", {
                attrs: {
                  repo: _vm.procedureCodeRepo,
                  label: "procCode",
                  placeholder: "Select Procedure Code",
                },
                model: {
                  value: _vm.selectedCptId,
                  callback: function ($$v) {
                    _vm.selectedCptId = $$v
                  },
                  expression: "selectedCptId",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }