var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("form-component", {
        attrs: { "show-status": false, "copy-data": _vm.practiceManagerCopy },
        on: {
          "update:copyData": function ($event) {
            _vm.practiceManagerCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.practiceManagerCopy = $event
          },
          save: _vm.savePracticeManager,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _vm._v(" Practice manager "),
                        _c(
                          "b-form-group",
                          [
                            _c("single-select", {
                              attrs: {
                                "options-repo": _vm.usersRepo,
                                "options-label": "name",
                                "is-options-from-repo": true,
                                "selected-object": _vm.selectedUser,
                              },
                              on: {
                                "update:selectedObject": function ($event) {
                                  _vm.selectedUser = $event
                                },
                                "update:selected-object": function ($event) {
                                  _vm.selectedUser = $event
                                },
                              },
                              model: {
                                value: _vm.practiceManagerCopy.userId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.practiceManagerCopy,
                                    "userId",
                                    $$v
                                  )
                                },
                                expression: "practiceManagerCopy.userId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _vm._v(" Primary email "),
                        _c(
                          "b-form-group",
                          { staticClass: "align-items-center mr-1" },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  staticClass: "w-100",
                                  attrs: {
                                    id: "sortByinput",
                                    value: _vm.selectedUser.email,
                                    readonly: "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b", [_vm._v("User Privileges")]),
                _c("table-component", {
                  ref: "currentTable",
                  attrs: {
                    itemsRepo: _vm.practiceUsersRepo,
                    fields: _vm.fields,
                    sidebarData: _vm.selectedRow,
                    copyData: _vm.selectedRowCopy,
                    loadParams: { PracticeId: _vm.$route.params.id },
                    isDataFromModel: false,
                    sidebarOpen: _vm.sidebarAddEdit,
                    "is-add-edit-sidbar": true,
                    "do-save": true,
                  },
                  on: {
                    "update:sidebarData": function ($event) {
                      _vm.selectedRow = $event
                    },
                    "update:sidebar-data": function ($event) {
                      _vm.selectedRow = $event
                    },
                    "update:copyData": function ($event) {
                      _vm.selectedRowCopy = $event
                    },
                    "update:copy-data": function ($event) {
                      _vm.selectedRowCopy = $event
                    },
                    "update:sidebarOpen": function ($event) {
                      _vm.sidebarAddEdit = $event
                    },
                    "update:sidebar-open": function ($event) {
                      _vm.sidebarAddEdit = $event
                    },
                    "on-copied": function ($event) {
                      _vm.selectedRowCopy.practiceId = +_vm.$route.params.id
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(insCompany)",
                      fn: function (data) {
                        return [
                          _vm._l(data.value, function (company, $index) {
                            return _c("div", { key: $index }, [
                              $index < 3
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(company.insuranceName) +
                                        " , "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          _vm.showMoreOption == data.item.id
                            ? _c(
                                "div",
                                _vm._l(data.value, function (company, $index) {
                                  return _c("div", { key: $index }, [
                                    $index >= 3
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(company.insuranceName) +
                                              ", "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                          data.value.length > 3 &&
                          _vm.showMoreOption != data.item.id
                            ? _c(
                                "span",
                                {
                                  staticClass: "primary-text",
                                  on: {
                                    click: function ($event) {
                                      _vm.showMoreOption = data.item.id
                                    },
                                  },
                                },
                                [
                                  _c("u", [
                                    _vm._v(
                                      _vm._s(data.value.length - 3) + " more"
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(dateRange)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                data.item.dateStart
                                  ? new Date(
                                      data.item.dateStart
                                    ).toLocaleDateString()
                                  : ""
                              ) +
                              " -- " +
                              _vm._s(
                                data.item.dateEnd
                                  ? new Date(
                                      data.item.dateEnd
                                    ).toLocaleDateString()
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c(
                            "b-container",
                            { staticClass: "px-2 mt-1" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _vm.selectedRowCopy.id
                                        ? _c("h4", { staticClass: "mb-2" }, [
                                            _vm._v("Edit User"),
                                          ])
                                        : _c("h4", [_vm._v("Add User")]),
                                      !_vm.selectedRowCopy.id
                                        ? _c(
                                            "b-form-group",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { label: "User Name" },
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "User Name",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("infinit-select", {
                                                            attrs: {
                                                              includeIds: [
                                                                _vm
                                                                  .selectedRowCopy
                                                                  .userId,
                                                              ],
                                                              label: "name",
                                                              repo: _vm.usersRepo,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectedRowCopy
                                                                  .userId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.selectedRowCopy,
                                                                    "userId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "selectedRowCopy.userId",
                                                            },
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2496010590
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-form-group",
                                        { attrs: { label: "Role" } },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Role",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("b-form-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.optionsPracticeUserRole,
                                                        state:
                                                          errors.length > 0
                                                            ? false
                                                            : null,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedRowCopy
                                                            .role,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.selectedRowCopy,
                                                            "role",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedRowCopy.role",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Insurance Companies",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Insurance Companies",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("multi-select", {
                                                      attrs: {
                                                        sourceIdField:
                                                          "insuranceId",
                                                        label: "name",
                                                        optionsRepo:
                                                          _vm.insuranceRepo,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedRowCopy
                                                            .insCompany,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.selectedRowCopy,
                                                            "insCompany",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedRowCopy.insCompany",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "mt-0 text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.practiceManager,
          callback: function ($$v) {
            _vm.practiceManager = $$v
          },
          expression: "practiceManager",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }