var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: { copyData: _vm.procSettingsCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.procSettingsCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.procSettingsCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
                      _c("div", { staticClass: "alert-body" }, [
                        _c(
                          "span",
                          [
                            _c("feather-icon", {
                              staticClass: "mr-25",
                              attrs: { icon: "AlertCircleIcon", size: "18" },
                            }),
                            _vm._v(" Data on this page is derived from the "),
                            _c("strong", [_vm._v("procedure type level")]),
                            _vm._v(
                              " , you can override specific fields by clicking the "
                            ),
                            _c("feather-icon", {
                              staticClass:
                                "ml-30 mr-30 secondary-text cursor-pointer",
                              attrs: { icon: "EditIcon", size: "16" },
                            }),
                            _vm._v(" icon "),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "4" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("h5", [_vm._v("Global period settings")]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Preoperative period" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Preoperative period",
                                    rules: !_vm.editButtonAllow[0].allow
                                      ? "required"
                                      : "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input-override", {
                                            attrs: {
                                              overrideData: _vm.procType
                                                .globalPeriodDaysPre
                                                ? _vm.procType.globalPeriodDaysPre.toString()
                                                : "",
                                            },
                                            model: {
                                              value:
                                                _vm.procSettingsCopy
                                                  .globalPeriodDaysPre,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procSettingsCopy,
                                                  "globalPeriodDaysPre",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "procSettingsCopy.globalPeriodDaysPre",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Postoperative period" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Postoperative period",
                                    rules: !_vm.editButtonAllow[0].allow
                                      ? "required"
                                      : "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input-override", {
                                            attrs: {
                                              overrideData: _vm.procType
                                                .globalPeriodDays
                                                ? _vm.procType.globalPeriodDays.toString()
                                                : "",
                                            },
                                            model: {
                                              value:
                                                _vm.procSettingsCopy
                                                  .globalPeriodDays,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procSettingsCopy,
                                                  "globalPeriodDays",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "procSettingsCopy.globalPeriodDays",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Type" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Type", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.globalPeriodTypesOptions,
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.procSettingsCopy
                                                  .globalPeriodType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procSettingsCopy,
                                                  "globalPeriodType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procSettingsCopy.globalPeriodType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-1" },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c("h5", [_vm._v("Patient requirements")]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Ages suitability from",
                                        },
                                      },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "AgesSuitabilityFrom",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      state:
                                                        errors.length > 0
                                                          ? false
                                                          : null,
                                                      type: "number",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.procSettingsCopy
                                                          .ageMin,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.procSettingsCopy,
                                                          "ageMin",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "procSettingsCopy.ageMin",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "To" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "AgesSuitabilityTo",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      state:
                                                        errors.length > 0
                                                          ? false
                                                          : null,
                                                      type: "number",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.procSettingsCopy
                                                          .ageMax,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.procSettingsCopy,
                                                          "ageMax",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "procSettingsCopy.ageMax",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Gender" } },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing",
                                  attrs: { name: "Gender", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-radio-group", {
                                            staticClass: "pt-0 mt-0",
                                            attrs: {
                                              name: "Gender",
                                              options: _vm.genderOptions,
                                              required: "",
                                              icon: "",
                                            },
                                            model: {
                                              value:
                                                _vm.procSettingsCopy.gender,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procSettingsCopy,
                                                  "gender",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procSettingsCopy.gender",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "4" } },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mt-3" },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Is justifiable write off (WRO)?",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing",
                                  attrs: { name: "WRO", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "WRO",
                                                value: "true",
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procSettingsCopy
                                                    .isJustifiableWriteOff,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procSettingsCopy,
                                                    "isJustifiableWriteOff",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procSettingsCopy.isJustifiableWriteOff",
                                              },
                                            },
                                            [_vm._v(" Yes ")]
                                          ),
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "WRO",
                                                value: "false",
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procSettingsCopy
                                                    .isJustifiableWriteOff,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procSettingsCopy,
                                                    "isJustifiableWriteOff",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procSettingsCopy.isJustifiableWriteOff",
                                              },
                                            },
                                            [_vm._v(" No ")]
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.procSettings,
      callback: function ($$v) {
        _vm.procSettings = $$v
      },
      expression: "procSettings",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }