<template>
  <b-skeleton-wrapper :loading="_formLoading">
    <template #loading>
      <b-card>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </b-card>
    </template>
<!--    <div v-disable-all="!$can(formDataCopy.id ? 'Update' : 'Create',$route.meta.resource )">-->
      <validation-observer ref="simpleRules">
        <b-card v-bind="$attrs">
          <b-row class="mb-1">
            <b-col cols="6" class="text-left" v-show="showHeader">
              <h4>
                <b>
                  <slot name="header"></slot>
                </b>
              </h4>
            </b-col>
            <b-col :cols="showHeader ? 6 : 12">
              <div class="d-flex float-right text-right mr-3">
                <slot name="status-right"></slot>

                <span class="d-flex float-right text-right" v-if="showStatus">
                Status
                <b-form-checkbox
                    v-model="formDataCopy.status"
                    value="Active"
                    unchecked-value="Inactive"
                    class="ml-1 custom-control-light"
                    name="check-button"
                    switch
                />
              </span>
              </div>
            </b-col>
          </b-row>
          <slot></slot>
          <slot name="body"></slot>
          <b-card-footer footer-border-variant="white">
            <b-form>
              <b-row>
                <b-col cols="5" class="text-left">
                  <can :a="$route.meta.resource" I="Delete">
                    <template v-if="showDelete">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="transparent"
                          @click="onDelete"
                      >
                        <feather-icon icon="TrashIcon" class="mr-50 danger-text"/>
                        <span class="danger-text align-middle">Delete</span>
                      </b-button>
                    </template>
                  </can>
                </b-col>
                <b-col cols="7" class="text-right">
                  <can :a="$route.meta.resource" :I="formDataCopy.id? 'Update' : 'Create'">
                    <label>No changes made</label>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        class="ml-1 mr-1"
                        @click="cancel"
                    >
                      Cancel
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm"
                    >
                      <b-spinner v-show="isLoading" small></b-spinner>
                      <feather-icon icon="CheckIcon" class="mr-50"/>

                      <span class="align-middle">Save</span>
                    </b-button>
                  </can>
                </b-col>
              </b-row>
            </b-form>
          </b-card-footer>
        </b-card>
      </validation-observer>
<!--    </div>-->

  </b-skeleton-wrapper>
</template>

<script lang="ts">
import {ISource} from "@/services/addOrUpdateOrDeleteService";
import _ from "lodash";
import {PropType} from "vue";
import {
  Component,
  Emit,
  ModelSync,
  Prop,
  PropSync,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import {
  BCard,
  BCol,
  BRow,
  BForm,
  BFormCheckbox,
  BButton,
  BCardFooter,
  BSpinner,
  BSkeletonWrapper,
  BSkeleton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {mapActions, mapState} from 'vuex';
import {State, Mutation} from 'vuex-class';
import {Can} from "@casl/vue";

@Component({
  components: {
    BCard,
    BCol,
    BRow,
    BForm,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCardFooter,
    BSpinner,
    Can,
    BSkeletonWrapper,
    BSkeleton
  },
  directives: {
    Ripple,

  },
})
export default class FormComponent extends Vue {
  @ModelSync("sourceData", "input", {
    type: Object as PropType<ISource>,
  })
  formData!: ISource;

  @PropSync("copyData", {
    type: Object as PropType<ISource>,
  })
  formDataCopy!: ISource;

  @PropSync("copyAddress", {
    type: Object as PropType<ISource>,
    default: () => ({}),
  })
  addressCopy!: ISource;

  @Prop({default: "any"})
  addressField!: String;

  @Prop()
  showStatus!: boolean;

  @Prop()
  validate!: boolean;

  @Prop()
  showHeader!: boolean;

  @Prop({default: false})
  showDelete!: Boolean;

  @Emit()
  save() {
    console.log("form save triggerd");
    console.log(this.formDataCopy);
    if (_.isEmpty(this.addressCopy) == false) {
      console.log(this.addressCopy, "not empty");
      this.formDataCopy[this.addressField.toString()] = this.addressCopy;
    }
    if (_.isEmpty(this.formDataCopy) == false) {
      console.log("formDataCopyNotEmpty", this.formDataCopy, this.formData);
    }
    return this.formDataCopy;
  }

  @Emit("cancel")
  cancel() {
    this.formDataCopy = _.cloneDeep(this.formData);
    this.addressCopy = _.cloneDeep(this.formData[this.addressField.toString()]);
  }

  @Emit()
  onDelete() {
  }

  @Emit()
  onCopy() {
    return this.formDataCopy;
  }

  @Watch("formData", {deep: true, immediate: true})
  async formDataWatch(newData, oldData) {
    console.log("formDataCloned", oldData, newData);
    this.formDataCopy = _.cloneDeep(newData);
    this.addressCopy = _.cloneDeep(
        this.formData[this.addressField.toString()] || {}
    );

    this.onCopy();
    await this.$nextTick();
    this.$emit("update:copyData", this.formDataCopy);
  }

  @Ref("simpleRules")
  refs_simpleRules!: any;

  @Ref("form")
  form!: HTMLDivElement;

  validationForm() {
    if (this.validate!!)
      this.refs_simpleRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    else this.save();
  }

  @State(state => state.loading.isLoading)
  private _isLoading!: boolean;

  private get isLoading(): boolean {
    return this._isLoading;
  }

  private set isLoading(value: boolean) {
    this._isLoading = value;
  }

  @State(state => state.formLoading.isLoading)
  private _formLoading!: boolean;

  private get formLoading(): boolean {
    return this._formLoading;
  }

  private set formLoading(value: boolean) {
    this._formLoading = value;
  }

  // mounted() {
  //   //@ts-ignore
  //   var can = this.$can(this.$route.meta.resource, 'create')
  //
  //   if(!can) {
  //     [].slice.call( this.form.elements ).forEach(function(item){
  //       item.disabled = !item.disabled;
  //     });
  //   }
  // }

}
</script>


<style lang="scss" scoped>
</style>
