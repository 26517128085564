<template>
  <div>
    <tabs-component
      pills
    >
      <b-tab active>
        <template #title>
          Clearing Houses
        </template>
        <ClearingHousesCC />
      </b-tab>
      <b-tab>
        <template #title>
          EHR Systems
        </template>
        <EHRsystemsCC />
      </b-tab>
      <b-tab>
        <template #title>
          Modifiers
        </template>
        <ModifiersCC />
      </b-tab>
      <b-tab>
        <template #title>
          Diagnosis
        </template>
        <DiagnosisCC />
      </b-tab>
      <b-tab>
        <template #title>
          Contact Role Types
        </template>
        <ContactRoleTypesCC />
      </b-tab>
      <b-tab>
        <template #title>
          POS codes
        </template>
        <POScodesCC />
      </b-tab>
      <b-tab>
        <template #title>
          Dr. Specialties
        </template>
        <DrSpecialtiesCC />
      </b-tab>
      <b-tab>
        <template #title>
          Insurance Plan Types
        </template>
        <InsurancePlanCC />
      </b-tab>
    </tabs-component>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import ClearingHousesCC from './lists-options-more/ClearingHousesCC.vue';
import EHRsystemsCC from './lists-options-more/EHRsystemsCC.vue';
import ModifiersCC from './lists-options-more/ModifiersCC.vue';
import DiagnosisCC from './lists-options-more/DiagnosisCC.vue';
import ContactRoleTypesCC from './lists-options-more/ContactRoleTypesCC.vue';
import POScodesCC from './lists-options-more/POScodesCC.vue';
import DrSpecialtiesCC from './lists-options-more/DrSpecialtiesCC.vue';
import InsurancePlanCC from './lists-options-more/InsurancePlanCC.vue';

import {
  BTab, BTabs, BCard,
} from 'bootstrap-vue'
import TabsComponent from "@/components/TabsComponent.vue";

@Component({
  components: {
    TabsComponent,
    ClearingHousesCC,
    EHRsystemsCC,
    ModifiersCC,
    DiagnosisCC,
    ContactRoleTypesCC,
    POScodesCC,
    DrSpecialtiesCC,
    InsurancePlanCC,
    BTab,
    BTabs,
    BCard,
  }
})
export default class ListsOptionsCC extends Vue {

  // DATA

  // COMPUTED

  // METHODS

  // MOUNTED
}
</script>
<style lang="scss" scoped>
@import '/src/assets/scss/variables/_variables.scss';
</style>
