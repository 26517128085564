import { render, staticRenderFns } from "./TableComponent.vue?vue&type=template&id=01b2f77f&scoped=true"
import script from "./TableComponent.vue?vue&type=script&lang=ts"
export * from "./TableComponent.vue?vue&type=script&lang=ts"
import style0 from "./TableComponent.vue?vue&type=style&index=0&id=01b2f77f&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b2f77f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01b2f77f')) {
      api.createRecord('01b2f77f', component.options)
    } else {
      api.reload('01b2f77f', component.options)
    }
    module.hot.accept("./TableComponent.vue?vue&type=template&id=01b2f77f&scoped=true", function () {
      api.rerender('01b2f77f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TableComponent.vue"
export default component.exports