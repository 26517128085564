/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ClaimValidateModel,
    ClaimValidateModelListResponseModel,
    ClaimValidateModelModelResponse,
    ClaimValidationResponse,
    ColumnFilter,
    GeneralStatus, ScrubResultStatus,
    SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export default class Scrubber<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Scrubber
     * @name ValidateClaimCreate
     * @request POST:/Scrubber/ValidateClaim
     */
    validateClaimCreate = (data: ClaimValidateModel, params: RequestParams = {}) =>
        this.request<ClaimValidationResponse, any>({
            path: `/Scrubber/ValidateClaim`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Scrubber
     * @name PostScrubber
     * @request POST:/Scrubber/Add
     */
    postScrubber = (data: ClaimValidateModel, params: RequestParams = {}) =>
        this.request<ClaimValidateModelModelResponse, any>({
            path: `/Scrubber/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Scrubber
     * @name LoadList
     * @request GET:/Scrubber/Load
     */
    loadList = (
        query?: {
            /** @format int32 */
            Take?: number;
            /** @format int32 */
            Skip?: number;
            IncludeIds?: number[];
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            /** @format int64 */
            PracticeId?: number;
            ForDropdown?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<ClaimValidateModelListResponseModel, any>({
            path: `/Scrubber/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Scrubber
     * @name GetByIdList
     * @request GET:/Scrubber/GetById
     */
    getByIdList = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ClaimValidateModelModelResponse, any>({
            path: `/Scrubber/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Scrubber
     * @name DeleteDelete
     * @request DELETE:/Scrubber/Delete
     */
    deleteDelete = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ClaimValidateModelModelResponse, any>({
            path: `/Scrubber/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Scrubber
     * @name UpdateCreate
     * @request POST:/Scrubber/Update
     */
    updateCreate = (data: ClaimValidateModel, params: RequestParams = {}) =>
        this.request<ClaimValidateModelModelResponse, any>({
            path: `/Scrubber/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });

    updateStatus = (data : {status: ScrubResultStatus, id?: number | null }, params: RequestParams = {}) =>
        this.request<ModelResponse<boolean>, any>({
            path: `/Scrubber/UpdateStatus`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
