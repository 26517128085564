var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "px-2 my-2" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm._t("header"),
              _c(
                "b-form-group",
                { attrs: { label: "Procedure code" } },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required",
                      name: "Procedure code",
                      vid: "procCode",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("infinit-select", {
                              attrs: {
                                repo: _vm.procedureCodesRepo,
                                label: "procCode",
                                includeIds: [_vm.cpt],
                                "is-disabled": false,
                                multiple: false,
                              },
                              on: { "option:selected": _vm.onOptionSelected },
                              model: {
                                value: _vm.cpt,
                                callback: function ($$v) {
                                  _vm.cpt = $$v
                                },
                                expression: "cpt",
                              },
                            }),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "Description" } },
                [
                  _c("b-form-textarea", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _vm._t("afterDescription"),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "my-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                { staticClass: "rounded-card", attrs: { "body-class": "p-0" } },
                [
                  _c("b-table", {
                    attrs: { items: _vm.modifiers, fields: _vm.fields1 },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(modifier)",
                        fn: function (row) {
                          return [
                            _c("div", [_vm._v(_vm._s(row.item.modifier))]),
                          ]
                        },
                      },
                      {
                        key: "cell(price)",
                        fn: function (row) {
                          return [
                            _c(
                              "b-input-group",
                              {
                                attrs: {
                                  prepend:
                                    _vm.rateType === _vm.ContractRateType.Fixed
                                      ? "$"
                                      : _vm.rateType ===
                                        _vm.ContractRateType.Percent
                                      ? "%"
                                      : null,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "number" },
                                  on: {
                                    update: function ($event) {
                                      return _vm.onPriceDetailsUpdate(
                                        $event,
                                        "price",
                                        row.item
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.item.price,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "price", $$v)
                                    },
                                    expression: "row.item.price",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(facilityPrice)",
                        fn: function (row) {
                          return [
                            _c(
                              "b-input-group",
                              {
                                attrs: {
                                  prepend:
                                    _vm.rateType === _vm.ContractRateType.Fixed
                                      ? "$"
                                      : _vm.rateType ===
                                        _vm.ContractRateType.Percent
                                      ? "%"
                                      : null,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "number" },
                                  on: {
                                    update: function ($event) {
                                      return _vm.onPriceDetailsUpdate(
                                        $event,
                                        "facilityPrice",
                                        row.item
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.item.facilityPrice,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "facilityPrice", $$v)
                                    },
                                    expression: "row.item.facilityPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(nonFacilityPrice)",
                        fn: function (row) {
                          return [
                            _c(
                              "b-input-group",
                              {
                                attrs: {
                                  prepend:
                                    _vm.rateType === _vm.ContractRateType.Fixed
                                      ? "$"
                                      : _vm.rateType ===
                                        _vm.ContractRateType.Percent
                                      ? "%"
                                      : null,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "number" },
                                  on: {
                                    update: function ($event) {
                                      return _vm.onPriceDetailsUpdate(
                                        $event,
                                        "nonFacilityPrice",
                                        row.item
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.item.nonFacilityPrice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row.item,
                                        "nonFacilityPrice",
                                        $$v
                                      )
                                    },
                                    expression: "row.item.nonFacilityPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }