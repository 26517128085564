<template>
  <ProcedureCodesCC />
</template>

<script lang="ts">
import ProcedureCodesCC from '/src/class-components/procedures/ProcedureCodesCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    ProcedureCodesCC
  }
})
export default class ProcedureCodes extends Vue {}
</script>
