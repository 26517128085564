/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    BooleanModelResponse,
    ColumnFilter, DtoModelResponse,
    GeneralStatus,
    InsurancesDto,
    InsurancesDtoListResponseModel,
    InsurancesDtoModelResponse, MargeInsuranceModel, MoveInsurancesModel,
    SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Insurances<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Insurances
   * @name PostInsurances
   * @request POST:/Insurances/Add
   */
  postInsurances = (data: InsurancesDto, params: RequestParams = {}) =>
    this.request<InsurancesDtoModelResponse, any>({
      path: `/Insurances/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
    /**
     * No description
     *
     * @tags Insurances
     * @name MargeInsurancesCreate
     * @request POST:/Insurances/MargeInsurances
     */
    margeInsurancesCreate = (data: MargeInsuranceModel, params: RequestParams = {}) =>
        this.request<BooleanModelResponse, any>({
            path: `/Insurances/MargeInsurances`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
  /**
   * No description
   *
   * @tags Insurances
   * @name LoadList
   * @request GET:/Insurances/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
      InsurancePlanId?: number;
      OnlyWithInsuranceCptProcedureSettings?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<InsurancesDtoListResponseModel, any>({
      path: `/Insurances/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Insurances
   * @name GetByIdList
   * @request GET:/Insurances/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<InsurancesDtoModelResponse, any>({
      path: `/Insurances/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Insurances
   * @name DeleteDelete
   * @request DELETE:/Insurances/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<InsurancesDtoModelResponse, any>({
      path: `/Insurances/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
    /**
     * No description
     *
     * @tags Insurances
     * @name MoveInsurancesCreate
     * @request POST:/Insurances/MoveInsurances
     */
    moveInsurancesCreate = (data: MoveInsurancesModel, params: RequestParams = {}) =>
        this.request<BooleanModelResponse, any>({
            path: `/Insurances/MoveInsurances`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
  /**
   * No description
   *
   * @tags Insurances
   * @name UpdateCreate
   * @request POST:/Insurances/Update
   */
  updateCreate = (data: InsurancesDto, params: RequestParams = {}) =>
    this.request<InsurancesDtoModelResponse, any>({
      path: `/Insurances/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
