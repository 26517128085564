/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter,
    GeneralStatus,
    ScrubResultDto,
    ScrubResultDtoListResponseModel,
    ScrubResultDtoModelResponse,
    SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ScrubResults<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags ScrubResults
     * @name LoadList
     * @request GET:/ScrubResults/Load
     */
    loadList = (
        query?: {
            ShowBilled?: boolean;
            ShowApproved?: boolean;
            /** @format int32 */
            Take?: number;
            /** @format int32 */
            Skip?: number;
            IncludeIds?: number[];
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            /** @format int64 */
            PracticeId?: number;
            ForDropdown?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<ScrubResultDtoListResponseModel, any>({
            path: `/ScrubResults/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ScrubResults
     * @name GetByIdList
     * @request GET:/ScrubResults/GetById
     */
    getByIdList = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ScrubResultDtoModelResponse, any>({
            path: `/ScrubResults/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ScrubResults
     * @name ScrubClaimDetail
     * @request GET:/ScrubResults/ScrubClaim/{claimNumber}
     */
    scrubClaimDetail = (claimNumber: string, params: RequestParams = {}) =>
        this.request<ScrubResultDtoModelResponse, any>({
            path: `/ScrubResults/ScrubClaim/${claimNumber}`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ScrubResults
     * @name PostScrubResults
     * @request POST:/ScrubResults/Add
     */
    postScrubResults = (data: ScrubResultDto, params: RequestParams = {}) =>
        this.request<ScrubResultDtoModelResponse, any>({
            path: `/ScrubResults/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ScrubResults
     * @name DeleteDelete
     * @request DELETE:/ScrubResults/Delete
     */
    deleteDelete = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<ScrubResultDtoModelResponse, any>({
            path: `/ScrubResults/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ScrubResults
     * @name UpdateCreate
     * @request POST:/ScrubResults/Update
     */
    updateCreate = (data: ScrubResultDto, params: RequestParams = {}) =>
        this.request<ScrubResultDtoModelResponse, any>({
            path: `/ScrubResults/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
