var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "designer",
      staticStyle: {
        position: "absolute",
        left: "0",
        right: "2%",
        top: "10%",
        bottom: "10%",
      },
      attrs: { "data-bind": "dxReportDesigner: $data" },
      on: {
        "": function ($event) {
          return _vm.console.log($event)
        },
      },
    },
    [_c("div", { ref: "saveAs", staticClass: "saveAsDialog" }), _vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { attrs: { id: "save-as" } }, [
      _c("div", { staticClass: "dxrd-reportdialog-content" }, [
        _c("div", {
          staticStyle: { "margin-bottom": "10px" },
          attrs: {
            "data-bind":
              "dxTextBox: {\n           height: 36,\n           value: reportUrl,\n           placeholder: 'Enter a report name to save...', showClearButton: true\n           }",
          },
        }),
        _c("div", {
          staticStyle: { "margin-bottom": "10px" },
          attrs: {
            "data-bind":
              "dxSelectBox: {\n                                  height: 36,\n                                  dataSource: categories,\n                                  value: categoryName,\n                                  keyExpr: 'key',\n                                  valueExpr: 'value',\n                                  displayExpr: 'key',\n                                  acceptCustomValue: false,\n                                  placeholder: 'Select a resource...',\n                              }",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }