var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: { label: _vm.label },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("b-form-text", [
                    _c(
                      "h6",
                      [
                        _vm._v(" " + _vm._s(_vm.label) + " "),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer text-secondary",
                          attrs: { icon: "EditIcon" },
                          on: { click: _vm.onEditClick },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("validation-provider", {
            staticClass: "demo-inline-spacing pt-0",
            attrs: { name: _vm.label, rules: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("b-form-radio-group", {
                      staticClass: "pt-0 mt-0",
                      attrs: {
                        disabled: _vm.editButtonAllow,
                        name: _vm.label,
                        value: true,
                        options: _vm.options,
                        required: "",
                      },
                      model: {
                        value: _vm.radioValue,
                        callback: function ($$v) {
                          _vm.radioValue = $$v
                        },
                        expression: "radioValue",
                      },
                    }),
                    _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }