<template>
  <div>
    <b-form-group :label="label">
      <template #label>
        <b-form-text>
          <h6>
            {{ label }}
            <feather-icon
                icon="EditIcon"
                class="cursor-pointer text-secondary"
                @click="onEditClick"
            />
          </h6>
        </b-form-text>
      </template>

      <validation-provider
          v-slot="{ errors }"
          :name="label"
          rules=""
          class="demo-inline-spacing pt-0"
      >
        <b-form-radio-group
            v-model="radioValue"
            :disabled="editButtonAllow"
            :name="label"
            :value="true"
            :options="options"
            required
            class="pt-0 mt-0"
        >
        </b-form-radio-group>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import {
  Component,
  ModelSync,
  Prop,
  PropSync,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupAddon,
  BFormRadio,
  BFormText,
  BFormGroup,
  BFormRadioGroup,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import {required} from "vee-validate/dist/rules";
import _ from "lodash";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroup,
    BInputGroupAddon,
    BFormRadio,
    BFormGroup,
    BFormInput,
    BFormText,
    BFormRadioGroup
  },
  props: {
    options: {
      default: () => [
        {text: "Yes", value: true},
        {text: "No", value: false},
      ],
    },
  },
})
export default class RadioOverride extends Vue {
  @ModelSync("inputData", "input")
  inputField!: Boolean | null;

  @Prop()
  label!: String;

  @PropSync("overrideData")
  overrideField!: Boolean; 

  editButtonAllow = true;

  @Watch("inputField")
  inputFieldWatch() {
    console.log(this.inputField);
    if (_.isNil(this.inputField) == false) {
      console.log(this.inputField);
      this.editButtonAllow = false;
    }
  }

  get radioValue() {
    if (this.inputField == null || this.inputField == undefined)
      return this.overrideField;
    else {
      return this.inputField;
    }
  }

  set radioValue(value) {
    if (this.editButtonAllow) {
      this.overrideField = value
    } else {
      this.inputField = value;
    }
  }

  onEditClick() {
    if (this.editButtonAllow == false) {
      this.inputField = null;
    }
    this.editButtonAllow = !this.editButtonAllow;
  }
}
</script>

<style lang="scss" scoped></style>