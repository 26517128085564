<template>
  <div ref="viewer" style="position: absolute; left: 0; right: 2%; top: 10%; bottom: 10%"></div>
</template>

<script>
import 'devexpress-reporting/dx-webdocumentviewer';
import {DxReportViewer} from 'devexpress-reporting/dx-webdocumentviewer';
import * as ko from 'knockout';
import {ajaxSetup} from "@devexpress/analytics-core/analytics-utils-native";
import axios from "@/libs/axios";

export default {
  name: "WebDocumentViewer",
  mounted() {
    this.mountView();
  },
  methods: {
    mountView() {
      console.log("baseURL", axios.defaults.baseURL);
      const reportUrl = ko.observable(this.$route.params.reportName);
      const viewerRef = this.$refs.viewer;
      const requestOptions = {
        host: axios.defaults.baseURL,
        invokeAction: "DXXRDV",
      };

      const viewer = new DxReportViewer(viewerRef, {reportUrl, requestOptions});
      viewer.render();
    },
  },
  watch: {
    $route() {
      this.mountView();
    }
  },
  beforeUnmount() {
    ko.cleanNode(this.$refs.viewer);
  }
};
</script>

IMPORTANT
