var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-select",
        _vm._g(
          _vm._b(
            {
              attrs: {
                options: _vm.currentOptions,
                label: _vm.optionsLabel,
                reduce: _vm.reduce,
                selectable: (x) => x.status == "Active",
                disabled: _vm.disabled,
                placeholder: _vm.placeholdrField,
              },
              on: { "option:selecting": _vm.optionSelected },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function (_, slot) {
                    return {
                      key: slot,
                      fn: function (props) {
                        return [_vm._t(slot, null, null, props)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            "v-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }