import { render, staticRenderFns } from "./ProcedureSettings.vue?vue&type=template&id=c256feae&scoped=true"
import script from "./ProcedureSettings.vue?vue&type=script&lang=ts"
export * from "./ProcedureSettings.vue?vue&type=script&lang=ts"
import style0 from "./ProcedureSettings.vue?vue&type=style&index=0&id=c256feae&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c256feae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c256feae')) {
      api.createRecord('c256feae', component.options)
    } else {
      api.reload('c256feae', component.options)
    }
    module.hot.accept("./ProcedureSettings.vue?vue&type=template&id=c256feae&scoped=true", function () {
      api.rerender('c256feae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/procedures/add-procedures-more/ProcedureSettings.vue"
export default component.exports