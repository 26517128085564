var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("form-component", {
        attrs: { copyData: _vm.formDataCopy },
        on: {
          "update:copyData": function ($event) {
            _vm.formDataCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.formDataCopy = $event
          },
          save: _vm.save,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "mt-2 pl-2", attrs: { md: "12" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                "Does this practice allow us to pull medical records on our own?",
                            },
                          },
                          [
                            _c("validation-provider", {
                              staticClass: "demo-inline-spacing pt-0",
                              attrs: {
                                name: "Medical Records",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "Medical Records",
                                            value: "true",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataCopy
                                                .isAllowedTuPullMedicalRecords,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataCopy,
                                                "isAllowedTuPullMedicalRecords",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataCopy.isAllowedTuPullMedicalRecords",
                                          },
                                        },
                                        [_vm._v(" Yes ")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "Medical Records",
                                            value: "false",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataCopy
                                                .isAllowedTuPullMedicalRecords,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataCopy,
                                                "isAllowedTuPullMedicalRecords",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataCopy.isAllowedTuPullMedicalRecords",
                                          },
                                        },
                                        [_vm._v(" No ")]
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "mt-4 pl-2", attrs: { md: "12" } },
                      [_c("h4", [_vm._v("Overwritten Procedure Settings")])]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "my-1 pl-2",
                        attrs: { md: "8", sm: "8", xs: "6" },
                      },
                      [
                        _c("h6", [
                          _c("b", [
                            _vm._v(
                              "Procedures That Are A Justifiable Write Off"
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("table-component", {
                  ref: "JWOTable",
                  attrs: {
                    fields: _vm.fields,
                    itemsRepo: _vm.procedureSettingsRepo,
                    loadFuncName: "LoadByPracticeJustifiableWriteOff",
                    loadParams: { PracticeId: _vm.$route.params.id },
                    sidebarOpen: _vm.procedureSettingsSidebar,
                    sidebarData: _vm.procedureSettingsData,
                    copyData: _vm.procedureSettingsCopy,
                    "is-add-edit-sidbar": true,
                    isDataFromModel: false,
                  },
                  on: {
                    "update:sidebarOpen": function ($event) {
                      _vm.procedureSettingsSidebar = $event
                    },
                    "update:sidebar-open": function ($event) {
                      _vm.procedureSettingsSidebar = $event
                    },
                    "update:sidebarData": function ($event) {
                      _vm.procedureSettingsData = $event
                    },
                    "update:sidebar-data": function ($event) {
                      _vm.procedureSettingsData = $event
                    },
                    "update:copyData": function ($event) {
                      _vm.procedureSettingsCopy = $event
                    },
                    "update:copy-data": function ($event) {
                      _vm.procedureSettingsCopy = $event
                    },
                    save: _vm.procedureSettingsSave,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c(
                            "b-container",
                            { staticClass: "px-2 mt-1 pb-3" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _vm.procedureSettingsCopy.id
                                        ? _c("h4", [_vm._v("Edit Procedure")])
                                        : _c("h4", [_vm._v("Add Procedure")]),
                                      _c("p", { staticClass: "mt-2" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.mode == "edit"
                                                ? "Edit"
                                                : "Add"
                                            ) +
                                            " a procedure that is a justifiable write off "
                                        ),
                                      ]),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Select the procedure",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Procedure",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("infinit-select", {
                                                      attrs: {
                                                        repo: _vm.procedureCodesRepo,
                                                        label: "procCode",
                                                        includeIds: [
                                                          _vm
                                                            .procedureSettingsCopy
                                                            .cptId,
                                                        ],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .procedureSettingsCopy
                                                            .cptId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.procedureSettingsCopy,
                                                            "cptId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "procedureSettingsCopy.cptId",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Select the Insurance",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Procedure",
                                              rules: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("infinit-select", {
                                                      attrs: {
                                                        repo: _vm.insuranceRepo,
                                                        label: "name",
                                                        includeIds: [
                                                          _vm
                                                            .procedureSettingsCopy
                                                            .insuranceId,
                                                        ],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .procedureSettingsCopy
                                                            .insuranceId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.procedureSettingsCopy,
                                                            "insuranceId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "procedureSettingsCopy.insuranceId",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "b-col",
                  {
                    staticClass: "my-1 pl-2",
                    attrs: { md: "8", sm: "8", xs: "6" },
                  },
                  [
                    _c("h6", [
                      _c("b", [
                        _vm._v("Procedures That Have Other Required Modifiers"),
                      ]),
                    ]),
                  ]
                ),
                _c("table-component", {
                  ref: "GroupedTable",
                  attrs: {
                    fields: _vm.fieldsModifiers,
                    itemsRepo: _vm.procedureSettingsRepo,
                    loadFuncName: "loadGroupedByReqModifiersCpt",
                    loadParams: { PracticeId: _vm.$route.params.id },
                    sidebarOpen: _vm.procedureSettingsModsSidebar,
                    sidebarData: _vm.procedureSettingsReqModsData,
                    copyData: _vm.procedureSettingsReqModsCopy,
                    isDataFromModel: false,
                    "is-add-edit-sidbar": true,
                    getByIdOnEditFilter: () => false,
                  },
                  on: {
                    "update:sidebarOpen": function ($event) {
                      _vm.procedureSettingsModsSidebar = $event
                    },
                    "update:sidebar-open": function ($event) {
                      _vm.procedureSettingsModsSidebar = $event
                    },
                    "update:sidebarData": function ($event) {
                      _vm.procedureSettingsReqModsData = $event
                    },
                    "update:sidebar-data": function ($event) {
                      _vm.procedureSettingsReqModsData = $event
                    },
                    "update:copyData": function ($event) {
                      _vm.procedureSettingsReqModsCopy = $event
                    },
                    "update:copy-data": function ($event) {
                      _vm.procedureSettingsReqModsCopy = $event
                    },
                    save: _vm.procedureSettingsReqModsSave,
                    "on-open": function ($event) {
                      _vm.mode = $event
                    },
                    "on-copied": _vm.onCopied,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(modifiers)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.modifiers
                                    ? data.item.modifiers
                                        .map((c) => c.code)
                                        .join(", ")
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(insurances)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.insurances
                                    .map((c) => c.name)
                                    .join(", ")
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      _vm.procedureSettingsModsSidebar
                        ? {
                            key: "body",
                            fn: function () {
                              return [
                                _c(
                                  "b-container",
                                  { staticClass: "px-2 mt-1 pb-3" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _vm.mode == "edit"
                                              ? _c("h4", [
                                                  _vm._v(
                                                    "Edit Procedure Code Modifier"
                                                  ),
                                                ])
                                              : _c("h4", [
                                                  _vm._v(
                                                    "Add Procedure Code Modifier"
                                                  ),
                                                ]),
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "Procedure Code",
                                                },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Procedure Code",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "infinit-select",
                                                              {
                                                                attrs: {
                                                                  repo: _vm.procedureCodesRepo,
                                                                  label:
                                                                    "procCode",
                                                                  includeIds: [
                                                                    _vm
                                                                      .procedureSettingsReqModsCopy
                                                                      .cptId,
                                                                  ],
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .procedureSettingsReqModsCopy
                                                                      .procedureCodeId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.procedureSettingsReqModsCopy,
                                                                        "procedureCodeId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "procedureSettingsReqModsCopy.procedureCodeId",
                                                                },
                                                              }
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2921099212
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "Required Modifiers",
                                                  "label-for": "basicInput",
                                                },
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Required Modifiers",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "infinit-select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.procedureSetModsIds,
                                                                    expression:
                                                                      "procedureSetModsIds",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  repo: _vm.modsRepo,
                                                                  label: "code",
                                                                  multiple: true,
                                                                  includeIds:
                                                                    _vm.procedureSetModsIds,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.procedureSetModsIds,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.procedureSetModsIds =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "procedureSetModsIds",
                                                                },
                                                              }
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2890974424
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            Array.isArray(
                                              _vm.procedureSetInsuranceIds
                                            )
                                              ? [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Insurance Companies",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "Insurance Companies",
                                                            rules: "required",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "infinit-select",
                                                                      {
                                                                        attrs: {
                                                                          repo: _vm.insuranceRepo,
                                                                          multiple: true,
                                                                          label:
                                                                            "name",
                                                                          includeIds:
                                                                            _vm.procedureSetInsuranceIds,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.procedureSetInsuranceIds,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.procedureSetInsuranceIds =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "procedureSetInsuranceIds",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "mt-0 text-danger",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            203983264
                                                          ),
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }