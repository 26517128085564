<template>
  <UsersCC />
</template>

<script lang="ts">
import UsersCC from '/src/class-components/settings/UsersCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    UsersCC
  }
})
export default class Users extends Vue {}
</script>
