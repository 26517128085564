var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "my-1", attrs: { cols: "7" } }, [
                _c("h4", [_vm._v(_vm._s(_vm.feeSchedule.name))]),
              ]),
              _c("b-col", { staticClass: "my-1", attrs: { cols: "5" } }, [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c("b", [_vm._v("Type:")]),
                    _vm._v(" " + _vm._s(_vm.feeSchedule.type || "N/A") + "   "),
                    _c(
                      "b-link",
                      {
                        attrs: {
                          to: {
                            name: "fee-schedules-add",
                            params: {
                              type: _vm.feeSchedule.type,
                              feeScheduleId: _vm.$route.params.feeScheduleId,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(40, 199, 111, 0.15)",
                                expression: "'rgba(40, 199, 111, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            attrs: { variant: "primary", size: "sm" },
                          },
                          [_vm._v(" + New Version ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm.currentVersion
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "label",
                      {
                        staticClass: "my-label",
                        class: "label-" + _vm.currentVersion.status,
                        attrs: { id: "my-label", for: "active-version" },
                      },
                      [_vm._v(_vm._s(_vm.currentVersion.status))]
                    ),
                    _c(
                      "div",
                      {
                        class: "div-" + _vm.currentVersion.status,
                        attrs: { id: "active-version" },
                      },
                      [
                        _c(
                          "b-container",
                          { staticStyle: { margin: "20px" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c("div", [
                                    _c("strong", [_vm._v("Ver")]),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.currentVersion.versionNumber
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("b-col", [
                                  _c("strong", [_vm._v("Start/End Date")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        new Date(
                                          _vm.currentVersion.dateStart
                                        ).toLocaleDateString()
                                      ) +
                                      " - " +
                                      _vm._s(
                                        _vm.currentVersion.dateEnd
                                          ? new Date(
                                              _vm.currentVersion.dateEnd
                                            ).toLocaleDateString()
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "fee-schedules-version-edit",
                                            params: {
                                              feeScheduleVersionId:
                                                _vm.currentVersion.id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(40, 199, 111, 0.15)",
                                                expression:
                                                  "'rgba(40, 199, 111, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "outline-primary",
                                              size: "md",
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-1",
                                              attrs: {
                                                icon: "EditIcon",
                                                size: "16",
                                              },
                                            }),
                                            _vm._v(" Edit "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("table-component", {
                    attrs: {
                      "items-repo": _vm.feeVersionRepo,
                      "sidebar-data": _vm.selectedVersion,
                      "copy-data": _vm.selecctedVersionCopy,
                      "is-data-from-model": false,
                      "load-params": {
                        feeScheduleId: +_vm.$route.params.feeScheduleId,
                      },
                      fields: _vm.fields,
                      "is-add-edit-sidbar": false,
                      "is-delete": false,
                      "add-edit-link": {
                        name: "fee-schedules-version-edit",
                        params: {
                          feeScheduleVersionId: _vm.selectedVersion.id,
                        },
                      },
                    },
                    on: {
                      "update:sidebarData": function ($event) {
                        _vm.selectedVersion = $event
                      },
                      "update:sidebar-data": function ($event) {
                        _vm.selectedVersion = $event
                      },
                      "update:copyData": function ($event) {
                        _vm.selecctedVersionCopy = $event
                      },
                      "update:copy-data": function ($event) {
                        _vm.selecctedVersionCopy = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "Add",
                        fn: function () {
                          return [_c("span")]
                        },
                        proxy: true,
                      },
                      {
                        key: "cell(delete)",
                        fn: function (data) {
                          return [_c("span")]
                        },
                      },
                      {
                        key: "cell(action-right)",
                        fn: function (data) {
                          return [
                            _c(
                              "b-link",
                              {
                                attrs: {
                                  to: {
                                    name: "fee-schedules-add",
                                    params: {
                                      type: _vm.feeSchedule.type,
                                      feeScheduleId:
                                        _vm.$route.params.feeScheduleId,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(40, 199, 111, 0.15)",
                                        expression:
                                          "'rgba(40, 199, 111, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass: "mr-1 d-flex px-0",
                                    attrs: {
                                      variant: "flat-primary",
                                      size: "sm",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "CopyIcon", size: "16" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(dateStart)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  new Date(data.value).toLocaleDateString()
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(dateEnd)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.value
                                    ? new Date(data.value).toLocaleDateString()
                                    : "N/A"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }