var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "layout-horizontal",
    {
      scopedSlots: _vm._u([
        {
          key: "navbar",
          fn: function ({ toggleVerticalMenuActive }) {
            return [
              _c("app-navbar-horizontal-layout-brand"),
              _c("navbar", {
                attrs: {
                  "toggle-vertical-menu-active": toggleVerticalMenuActive,
                },
              }),
            ]
          },
        },
      ]),
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }