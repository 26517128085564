<template>
<span>
        <span ref="dropTrigger">
            <slot name="drop-trigger"></slot>
        </span>
<div ref="dropContent" class="container-fluid">
  <h4 class="header row py-1 px-1"><slot  name="header">{{header}}</slot> </h4>
  
    <div class="row px-1 py-1"><slot name="drop-content"></slot></div>
  <div class="row justify-content-end"> <b-button variant="outline-danger" size="sm" class="col-2 my-1 mx-1"
                                                  @click="onClose">OK</b-button></div>
</div>
 
</span>
</template>

<script lang="ts">
import Drop from 'tether-drop';
import Vue from "vue";
import Component from "vue-class-component";
import {BButton} from "bootstrap-vue";

@Component({
  components: {
    BButton
  },
  props: {
    position: String,
    openOn: String,
    classes: String,
    constrainToWindow: Boolean,
    constrainToScrollParent: Boolean,
    hoverOpenDelay: Number,
    hoverCloseDelay: Number,
    focusDelay: Number,
    blurDelay: Number,
    tetherOptions: Object,
    remove: Boolean,
    header: String,
  }
})
export default class Tooltip extends Vue {

  drop: null | Drop = null;
  close: boolean = false;

  mounted() {
    this.drop = new Drop({
      target: this.$refs.dropTrigger as Element,
      content: this.$refs.dropContent as Element,
      position: this.$props.position ? this.$props.position : 'bottom left',
      openOn: this.$props.openOn ? this.$props.openOn : 'click',
      classes: this.$props.classes ? this.$props.classes : 'drop-theme-arrows',
      constrainToWindow: this.$props.constrainToWindow ? true : false,
      constrainToScrollParent: this.$props.constrainToScrollParent ? true : false,
      hoverOpenDelay: this.$props.hoverOpenDelay ? this.$props.hoverOpenDelay : 0,
      hoverCloseDelay: this.$props.hoverCloseDelay ? this.$props.hoverCloseDelay : 50,
      focusDelay: this.$props.focusDelay ? this.$props.focusDelay : 0,
      blurDelay: this.$props.blurDelay ? this.$props.blurDelay : 50,
      tetherOptions: this.$props.tetherOptions ? this.$props.tetherOptions : {},
      remove: this.close,

    });
  }
  
  onClose() {
    this.drop?.close();
    
    console.log("close" + this.close, this.drop);
  }
}
</script>
<style>

.drop-element, .drop-element:after, .drop-element:before, .drop-element *, .drop-element *:after, .drop-element *:before {
  box-sizing: border-box;
}

.drop-element {
  position: absolute;
  display: none;
}

.drop-element.drop-open {
  display: block;
}

.drop-element.drop-theme-arrows {
  max-width: 100%;
  max-height: 100%;
}

.drop-element.drop-theme-arrows .drop-content {


  position: absolute;
  width: 276px;
  height: auto;
  left: 0px;
  top: 8px;

  background: #FFFFFF;

  border: 1px solid #EBE9F1;

  box-shadow: 0px 0px 10px rgba(130, 134, 139, 0.25);
  border-radius: 8px;
}

.drop-element.drop-theme-arrows .drop-content .header {

  padding-top: 3%;
  padding-bottom: 7%;
  padding-left: 10%;
  margin-right: -14px;
  margin-left: -14px;


  background: #EA5455;
  border-radius: 7px 7px 0px 0px;
  color: #FFFFFF;
}

.drop-element.drop-theme-arrows .drop-content .detail {

}

.drop-element.drop-theme-arrows .drop-content:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-width: 16px;
  border-style: solid;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-center .drop-content {
  margin-bottom: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-center .drop-content:before {
  top: 100%;
  left: 50%;
  margin-left: -16px;
  border-top-color: #eee;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-center .drop-content {
  margin-top: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-center .drop-content:before {
  bottom: 100%;
  left: 50%;
  margin-left: -16px;
  border-bottom-color: #eee;
}

.drop-element.drop-theme-arrows.drop-element-attached-right.drop-element-attached-middle .drop-content {
  margin-right: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-right.drop-element-attached-middle .drop-content:before {
  left: 100%;
  top: 50%;
  margin-top: -16px;
  border-left-color: #eee;
}

.drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle .drop-content {
  margin-left: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle .drop-content:before {
  right: 100%;
  top: 50%;
  margin-top: -16px;
  border-right-color: #eee;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content {
  margin-top: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content:before {
  bottom: 100%;
  left: 16px;
  border-bottom-color: #EA5455;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content {
  margin-top: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content:before {
  bottom: 100%;
  right: 16px;
  border-bottom-color: #EA5455;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content {
  margin-bottom: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content:before {
  top: 100%;
  left: 16px;
  border-top-color: #EA5455;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content {
  margin-bottom: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content:before {
  top: 100%;
  right: 16px;
  border-top-color: #EA5455;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content {
  margin-right: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content:before {
  top: 16px;
  left: 100%;
  border-left-color: #EA5455;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content {
  margin-left: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content:before {
  top: 16px;
  right: 100%;
  border-right-color: #EA5455;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content {
  margin-right: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content:before {
  bottom: 16px;
  left: 100%;
  border-left-color: #EA5455;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content {
  margin-left: 16px;
}

.drop-element.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content:before {
  bottom: 16px;
  right: 100%;
  border-right-color: #EA5455;
}

</style>