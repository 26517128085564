var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "main1" },
    [
      _c(
        "b-button",
        {
          staticClass: "position-fixed round-button",
          staticStyle: { right: "100px", bottom: "100px" },
          attrs: { id: "notes-button", variant: "success" },
        },
        [
          _c("BIconPencilSquare"),
          _vm.noteList.length > 0
            ? _c(
                "b-badge",
                { staticClass: "-ml-4 mb-3", attrs: { variant: "danger" } },
                [_vm._v(" " + _vm._s(_vm.noteList.length) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-popover",
        {
          attrs: {
            target: "notes-button",
            triggers: "click",
            placement: "top",
            "custom-class": "popover-custom",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_vm._v(" Notes ")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-list-group",
            [
              _vm._l(_vm.noteList, function (note, index) {
                return _c(
                  "b-list-group-item",
                  { key: index },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("b-avatar", [
                              _vm._v(_vm._s(note.user ? note.user.name : "")),
                            ]),
                          ],
                          1
                        ),
                        _c("b-col", { attrs: { cols: "5" } }, [
                          _vm._v(" " + _vm._s(note.text) + " "),
                        ]),
                        _c("b-col", { attrs: { cols: "2" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                new Date(note.dateCreated)
                                  .toLocaleDateString()
                                  .slice(0, -5)
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "b-col",
                          { staticClass: "mr-1", attrs: { cols: "1" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "transparent" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(note)
                                  },
                                },
                              },
                              [
                                _c("b-icon-pencil", {
                                  attrs: { variant: "success" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "mr-1", attrs: { cols: "1" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "transparent" },
                                on: {
                                  click: function ($event) {
                                    _vm.noteRepo
                                      .deleteDelete({ id: note.id })
                                      .then(_vm.getNotes)
                                  },
                                },
                              },
                              [
                                _c("b-icon-trash", {
                                  attrs: { variant: "danger" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "b-list-group-item",
                [
                  _c("b-form-textarea", {
                    attrs: { rows: "3", placeholder: "Enter note" },
                    model: {
                      value: _vm.copyData.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.copyData, "text", $$v)
                      },
                      expression: "copyData.text",
                    },
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-2",
                      attrs: { variant: "success" },
                      on: { click: _vm.saveNote },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }