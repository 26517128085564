var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Allowed PlaceOfService" } },
                [
                  _c("infinit-select", {
                    attrs: {
                      repo: _vm.PlaceOfServiceRepo,
                      "include-ids":
                        _vm.procSettingsCopyRef.allowedPlaceOfServiceSettings,
                      selectable: (x) => x.status == "Active",
                      multiple: true,
                      label: "code",
                    },
                    on: {
                      "option:selecting": _vm.onSelected,
                      "option:deselected": _vm.onDeselected,
                      "insurance-options-update": _vm.updateDisallowedOptions,
                    },
                    model: {
                      value:
                        _vm.procSettingsCopyRef.allowedPlaceOfServiceSettings,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.procSettingsCopyRef,
                          "allowedPlaceOfServiceSettings",
                          $$v
                        )
                      },
                      expression:
                        "procSettingsCopyRef.allowedPlaceOfServiceSettings",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Disallowed PlaceOfService" } },
                [
                  _c("infinit-select", {
                    attrs: {
                      repo: _vm.PlaceOfServiceRepo,
                      "include-ids":
                        _vm.procSettingsCopyRef
                          .disallowedPlaceOfServiceSettings,
                      selectable: (x) => x.status == "Active",
                      multiple: true,
                      label: "code",
                    },
                    on: {
                      "option:selecting": _vm.onSelected,
                      "option:deselected": _vm.onDeselected,
                      "insurance-options-update": _vm.updateDisallowedOptions,
                    },
                    model: {
                      value:
                        _vm.procSettingsCopyRef
                          .disallowedPlaceOfServiceSettings,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.procSettingsCopyRef,
                          "disallowedPlaceOfServiceSettings",
                          $$v
                        )
                      },
                      expression:
                        "procSettingsCopyRef.disallowedPlaceOfServiceSettings",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }