import { render, staticRenderFns } from "./HorizontalNavMenuItems.vue?vue&type=template&id=34443406"
import script from "./HorizontalNavMenuItems.vue?vue&type=script&lang=js"
export * from "./HorizontalNavMenuItems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34443406')) {
      api.createRecord('34443406', component.options)
    } else {
      api.reload('34443406', component.options)
    }
    module.hot.accept("./HorizontalNavMenuItems.vue?vue&type=template&id=34443406", function () {
      api.rerender('34443406', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue"
export default component.exports