var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { staticStyle: { height: "75vh" } },
    [
      _c(
        "b-row",
        [_c("b-col", [_c("h4", [_vm._v("Add Fee Schedule Manually")])])],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", [
            _c("h6", [
              _vm._v(" Select the Fee Schedule to clone "),
              _c("br"),
              _vm._v(" and use as a starting point "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "my-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("InfinitSelect", {
                attrs: { label: "name", repo: _vm.feeScheduleRepo },
                model: {
                  value: _vm.selectedFeeSchedule,
                  callback: function ($$v) {
                    _vm.selectedFeeSchedule = $$v
                  },
                  expression: "selectedFeeSchedule",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "fee-schedules-manual-start",
                      })
                    },
                  },
                },
                [_vm._v(" Back ")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "fee-schedules-clone-form",
                        params: {
                          feeScheduleId: _vm.selectedFeeSchedule,
                          type: "Manual",
                        },
                      })
                    },
                  },
                },
                [_vm._v(" Clone & Continue ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }