var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Assigned User" } },
                [
                  _c("infinit-select", {
                    attrs: {
                      "include-ids": [_vm.credentialingAssignedUserId],
                      repo: _vm.userRepo,
                      label: "name",
                      placeholder: "Credentialing Assigned User",
                      value: _vm.credentialingAssignedUserId,
                    },
                    on: {
                      input: (val) =>
                        _vm.nextTick(() =>
                          _vm.$emit("update:credentialingAssignedUserId", val)
                        ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Type" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.credentialingTypeOptions,
                      value: _vm.credentialingType,
                    },
                    on: {
                      input: (val) =>
                        _vm.nextTick(() =>
                          _vm.$emit("update:credentialingType", val)
                        ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Due Date" } },
                [
                  _c("b-form-datepicker", {
                    attrs: { value: _vm.credentialingDueDate },
                    on: {
                      input: (val) =>
                        _vm.nextTick(() =>
                          _vm.$emit("update:credentialingDueDate", val)
                        ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Details" } },
                [
                  _c("b-form-textarea", {
                    attrs: { value: _vm.credentialingDetails },
                    on: {
                      input: (val) =>
                        _vm.nextTick(() =>
                          _vm.$emit("update:credentialingDetails", val)
                        ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Status" } },
                [
                  _c("b-form-select", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.html",
                        modifiers: { html: true },
                      },
                    ],
                    attrs: {
                      options: _vm.profileStatusOptions,
                      title:
                        "Active: Application has been completed, and a new Profile is created and marked as ACTIVE </br> </br>\nInactive: Application has been completed, and a new Profile is created and marked as INACTIVE </br> </br>\nDeleted: Profile / Request has been Deleted from the Module </br> </br>\nPending: Application is being worked on by Credentialing Team  </br> </br>\nWaiting for Doctor to Confirm in Writing: Waiting for Doctor to confirm the Credentialing request and pay the deposit </br> </br>\nApproved: Application has been Approved, No profile was created.",
                      value: _vm.status,
                    },
                    on: {
                      input: (val) => {
                        _vm.$emit("update:status", val)
                        _vm.nextTick(() =>
                          _vm.console.log("status", _vm.status)
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }