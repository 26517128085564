var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "form-Component",
        {
          attrs: {
            copyData: _vm.insuranceCopy,
            showHeader: true,
            showStatus: _vm.formData.id,
            validate: true,
          },
          on: {
            "update:copyData": function ($event) {
              _vm.insuranceCopy = $event
            },
            "update:copy-data": function ($event) {
              _vm.insuranceCopy = $event
            },
            save: ($event) => {
              _vm.$emit("save", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formData.id
                          ? _vm.formData.name
                          : "Add Insurance Company"
                      ) +
                      " "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v
            },
            expression: "formData",
          },
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _vm.insuranceCopy.isTemp
                ? _c(
                    "b-row",
                    { attrs: { id: "mappingBox" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticStyle: {
                            flex: "none",
                            width: "5px",
                            "align-self": "center",
                          },
                        },
                        [
                          _c("b-icon-exclamation-circle", {
                            attrs: { variant: "dark-blue" },
                          }),
                        ],
                        1
                      ),
                      _c("b-col", { staticStyle: { color: "#1F7580" } }, [
                        _vm._v(" Insurance Company waiting for a review "),
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            id: "mapButton",
                            variant: "outline-primary",
                          },
                          on: { click: _vm.mapInsurances },
                        },
                        [_vm._v(" Map ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            id: "makeDefaultButton",
                            variant: "primary",
                          },
                          on: { click: _vm.makePrimary },
                        },
                        [_vm._v(" Make Primary ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Insurance Company Name" } },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Insurance Company Name",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: _vm.insuranceCopy.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insuranceCopy,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "insuranceCopy.name",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { label: "Insurance Company ID" },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "Insurance Company ID" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("b-form-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value: _vm.insuranceCopy.id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.id",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "Short Code" } },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Short Code",
                                          rules: "alpha_num|required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    maxlength: "10",
                                                    type: "text",
                                                    placeholder: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.insuranceCopy.code,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.insuranceCopy,
                                                        "code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "insuranceCopy.code",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        errors[0]
                                                          ? "At least 1 character (max 10) and alphanumeric only"
                                                          : ""
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "can",
                            {
                              attrs: {
                                I: "View",
                                a: "ScrubberProcedureSettings",
                              },
                            },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "IPA Insurance Company?" } },
                                [
                                  _c("validation-provider", {
                                    staticClass: "demo-inline-spacing pt-0",
                                    attrs: {
                                      name: "IPA Insurance Company?",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "IPA Insurance Company?",
                                                  value: true,
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy.isIpa,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "isIpa",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.isIpa",
                                                },
                                              },
                                              [_vm._v(" Yes ")]
                                            ),
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "IPA Insurance Company?",
                                                  value: false,
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy.isIpa,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "isIpa",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.isIpa",
                                                },
                                              },
                                              [_vm._v(" No ")]
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Work Comp Insurance Company?",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "demo-inline-spacing pt-0",
                                    attrs: {
                                      name: "Work Comp Insurance Company?",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "Work Comp Insurance Company?",
                                                  value: true,
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .isWorkComp,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "isWorkComp",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.isWorkComp",
                                                },
                                              },
                                              [_vm._v(" Yes ")]
                                            ),
                                            _c(
                                              "b-form-radio",
                                              {
                                                staticClass: "pt-0 mt-0",
                                                attrs: {
                                                  name: "Work Comp Insurance Company?",
                                                  value: false,
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.insuranceCopy
                                                      .isWorkComp,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insuranceCopy,
                                                      "isWorkComp",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insuranceCopy.isWorkComp",
                                                },
                                              },
                                              [_vm._v(" No ")]
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { attrs: { label: "IPA Insurance Company" } },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "IPA Insurance Company",
                                      rules: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("infinit-select", {
                                              attrs: {
                                                includeIds: [
                                                  _vm.insuranceCopy
                                                    .parentInsuarnceId,
                                                ],
                                                repo: _vm.insuranceRepo,
                                                label: "name",
                                              },
                                              model: {
                                                value:
                                                  _vm.insuranceCopy
                                                    .parentInsuarnceId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.insuranceCopy,
                                                    "parentInsuarnceId",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "insuranceCopy.parentInsuarnceId",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { attrs: { label: "Medicare Type" } },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Medicare Type", rules: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-select", {
                                              attrs: {
                                                options: _vm.medicareTypeSelect,
                                                selected: null,
                                              },
                                              model: {
                                                value:
                                                  _vm.insuranceCopy
                                                    .medicareType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.insuranceCopy,
                                                    "medicareType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insuranceCopy.medicareType",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            { attrs: { label: "Website" } },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Website",
                                  rules: "regex:(?=.*[.])",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: _vm.insuranceCopy.website,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insuranceCopy,
                                                "website",
                                                $$v
                                              )
                                            },
                                            expression: "insuranceCopy.website",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            attrs: { required: false },
                            model: {
                              value: _vm.insuranceCopy.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.insuranceCopy, "address", $$v)
                              },
                              expression: "insuranceCopy.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("email-phone-fax", {
                            attrs: {
                              emails: _vm.insuranceCopy.emails,
                              phones: _vm.insuranceCopy.phones,
                            },
                            on: {
                              "update:emails": function ($event) {
                                return _vm.$set(
                                  _vm.insuranceCopy,
                                  "emails",
                                  $event
                                )
                              },
                              "update:phones": function ($event) {
                                return _vm.$set(
                                  _vm.insuranceCopy,
                                  "phones",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("mapping-sidebar", {
        attrs: {
          open: _vm.showMappingSidebar,
          insurances: _vm.insurances,
          base: _vm.baseMapId,
        },
        on: {
          "update:open": function ($event) {
            _vm.showMappingSidebar = $event
          },
          "update:insurances": function ($event) {
            _vm.insurances = $event
          },
          "update:base": function ($event) {
            _vm.baseMapId = $event
          },
          "on-submit-success": _vm.onSubmitSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }