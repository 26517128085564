var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("TableComponent", {
        attrs: {
          "items-repo": _vm.profileRepo,
          "is-data-from-model": false,
          fields: _vm.fields,
          "sidebar-data": _vm.selectedProfile,
          "copy-data": _vm.selectedProfileCopy,
          validate: true,
          "do-save": true,
          "get-by-id-on-edit-filter": () => false,
          "add-edit-link": {
            name: "practices-details-view-profile",
            params: {
              id: _vm.selectedProfile.practiceId,
              itemData: _vm.selectedProfile.id ? _vm.selectedProfile.id : "new",
              type: "credentialing",
            },
          },
          "load-params": { IsCredentialing: true },
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedProfile = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedProfile = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedProfileCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedProfileCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(40, 199, 111, 0.15)",
                        expression: "'rgba(40, 199, 111, 0.15)'",
                        modifiers: { 400: true },
                      },
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.modal-1",
                        modifiers: { "modal-1": true },
                      },
                    ],
                    attrs: { variant: "outline-primary", size: "sm" },
                  },
                  [_vm._v(" Bulk Add ")]
                ),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "modal-1",
                      title: "Bulk Add",
                      "hide-footer": "",
                      size: "lg",
                      centered: "",
                    },
                  },
                  [
                    _c("BulkInsertModal", {
                      attrs: {
                        "practice-id": _vm.practiceId,
                        type: "credentialing",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "practiceId",
                          fn: function ({ v$, validateState }) {
                            return [
                              _c(
                                "b-row",
                                { staticClass: "justify-content-center m-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "m-0",
                                          attrs: {
                                            state: validateState("practiceId"),
                                            label: "Practice",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "invalid-feedback",
                                                fn: function () {
                                                  return [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "Please select a practice"
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("InfinitSelect", {
                                            attrs: {
                                              repo: _vm.practiceRepo,
                                              label: "name",
                                              placeholder: "Select Practice",
                                            },
                                            model: {
                                              value: _vm.practiceId,
                                              callback: function ($$v) {
                                                _vm.practiceId = $$v
                                              },
                                              expression: "practiceId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(status)",
            fn: function (data) {
              return [
                _c("div", [
                  _vm._v(_vm._s(data.value.replace(/([A-Z])/g, " $1"))),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }