var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          attrs: {
            options: _vm.insuranceOptions,
            disabled: _vm.disabled,
            filterable: true,
            multiple: _vm.multipleSync,
            reduce: (x) => x.id,
            label: _vm.label,
          },
          on: {
            open: _vm.onOpen,
            close: _vm.onClose,
            search: (query) => (_vm.searchTextCopy = query),
          },
          scopedSlots: _vm._u(
            [
              {
                key: "list-header",
                fn: function () {
                  return [
                    _vm.selectedInsurance
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "transparent" },
                            on: {
                              click: function ($event) {
                                _vm.selectedInsurance = null
                              },
                            },
                          },
                          [_vm._v(" Clear selection ")]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "list-footer",
                fn: function () {
                  return [
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hasNextPage,
                            expression: "hasNextPage",
                          },
                        ],
                        ref: "load",
                        staticClass: "loader",
                      },
                      [_vm._v(" Loading more options... ")]
                    ),
                  ]
                },
                proxy: true,
              },
              _vm._l(_vm.$scopedSlots, function (_, slot) {
                return {
                  key: slot,
                  fn: function (props) {
                    return [_vm._t(slot, null, null, props)]
                  },
                }
              }),
            ],
            null,
            true
          ),
          model: {
            value: _vm.selectedInsurance,
            callback: function ($$v) {
              _vm.selectedInsurance = $$v
            },
            expression: "selectedInsurance",
          },
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }