var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("table-component", {
    attrs: {
      addEditLink: {
        name: "ins-plans-details",
        params: { id: _vm.formData.id ? _vm.formData.id : "new" },
      },
      isAddEditSidbar: false,
      getByIdOnEditFilter: () => false,
      itemsRepo: _vm.insurancePlansRepo,
      sidebarData: _vm.formData,
      isDataFromModel: false,
      fields: _vm.fields,
    },
    on: {
      "update:sidebarData": function ($event) {
        _vm.formData = $event
      },
      "update:sidebar-data": function ($event) {
        _vm.formData = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }