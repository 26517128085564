var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-col", { staticClass: "ml-1 my-1", attrs: { cols: "12" } }, [
            _c("h4", [_vm._v(_vm._s(_vm.feeSchedule.name))]),
            _c("b", [_vm._v("Type:")]),
            _vm._v(" " + _vm._s(_vm.feeSchedule.type || "N/A") + "   "),
            _c("b", [_vm._v("Status:")]),
            _vm._v(" " + _vm._s(_vm.feeSchedule.status || "N/A") + "   "),
            _c("b", [_vm._v("Current active version:")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.activeVersion ? _vm.activeVersion.versionNumber : "N/A"
                ) +
                "   "
            ),
            _c("b", [_vm._v("Started:")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.activeVersion
                    ? _vm.activeVersion.dateStart
                      ? new Date(
                          _vm.activeVersion.dateStart
                        ).toLocaleDateString()
                      : "N/A"
                    : "N/A"
                ) +
                "   "
            ),
            _c("b", [_vm._v("Ends:")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.activeVersion
                    ? _vm.activeVersion.dateEnd
                      ? new Date(_vm.activeVersion.dateEnd).toLocaleDateString()
                      : "N/A"
                    : "N/A"
                ) +
                " "
            ),
            _c("hr", { staticClass: "mr-2" }),
          ]),
          _vm.feeSchedule.type !== "Dynamic"
            ? _c("fee-schedule-pricing-table", {
                attrs: {
                  "show-pricing-for": true,
                  "fee-schedule-id": +_vm.$route.params.feeScheduleId,
                },
              })
            : _c("fee-schedule-version-pricing-view-table"),
        ],
        1
      ),
      _vm.showModel
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "modal-sm",
                "cancel-variant": "primary",
                "ok-only": "",
                "ok-title": "Ok",
                centered: "",
                size: "sm",
                title: "Medloop: System Alert",
                "hide-footer": _vm.result.message ? false : true,
              },
              model: {
                value: _vm.showModal,
                callback: function ($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal",
              },
            },
            [
              !_vm.result.message
                ? _c(
                    "div",
                    { staticClass: "text-center w-100 mt-2 mb-2" },
                    [
                      _c("b-spinner", {
                        attrs: { variant: "primary", label: "Loading..." },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("b-card-text", { attrs: { else: "" } }, [
                _vm._v(_vm._s(_vm.result.message)),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }