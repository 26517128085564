var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "table-component",
    {
      attrs: {
        fields: _vm.fields,
        "items-repo": _vm.noteRepo,
        "is-data-from-model": false,
        "load-params": _vm.filter,
        "is-add-edit-sidbar": true,
        "sidebar-data": _vm.sidebarData,
        "copy-data": _vm.copyData,
        "sidebar-open": _vm.sidebarOpen,
        "do-save": true,
        resource: "Notes",
      },
      on: {
        "update:sidebarData": function ($event) {
          _vm.sidebarData = $event
        },
        "update:sidebar-data": function ($event) {
          _vm.sidebarData = $event
        },
        "update:copyData": function ($event) {
          _vm.copyData = $event
        },
        "update:copy-data": function ($event) {
          _vm.copyData = $event
        },
        "update:sidebarOpen": function ($event) {
          _vm.sidebarOpen = $event
        },
        "update:sidebar-open": function ($event) {
          _vm.sidebarOpen = $event
        },
        "on-before-save": _vm.onBeforeSave,
      },
      scopedSlots: _vm._u([
        {
          key: "cell(delete)",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c(
          "b-col",
          { staticClass: "mt-2" },
          [
            _c(
              "b-form-group",
              { attrs: { label: "Note" } },
              [
                _c("b-form-textarea", {
                  attrs: { rows: "22", placeholder: "Enter note" },
                  model: {
                    value: _vm.copyData.text,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyData, "text", $$v)
                    },
                    expression: "copyData.text",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }