var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showTabs
    ? _c(
        "b-tabs",
        _vm._b(
          {
            ref: "rootTabs",
            on: { input: _vm.changed },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (_, slot) {
                  return {
                    key: slot,
                    fn: function (props) {
                      return [_vm._t(slot, null, null, props)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
            model: {
              value: _vm.currentRouteIndex,
              callback: function ($$v) {
                _vm.currentRouteIndex = $$v
              },
              expression: "currentRouteIndex",
            },
          },
          "b-tabs",
          _vm.$attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }