var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "upload-box text-center", attrs: { cols: "12" } },
            [
              _c(
                "b-row",
                { staticClass: "inside" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mx-auto",
                      attrs: { cols: "3", md: "3", sm: "12" },
                    },
                    [
                      _c("h4", { staticClass: "text-primary" }, [
                        _vm._v("Upload Medicare Rates File"),
                      ]),
                      _c("validation-provider", {
                        attrs: { name: "file", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({
                              validate,
                              errors,
                              valid,
                              validated,
                            }) {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass: "mt-1",
                                    attrs: {
                                      variant:
                                        !validated || valid
                                          ? "primary"
                                          : "outline-danger",
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.uploadExcel.click()
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "UploadIcon" },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "align-middle" },
                                      [_vm._v(" " + _vm._s(_vm.fileName))]
                                    ),
                                  ],
                                  1
                                ),
                                _c("input", {
                                  ref: "uploadExcel",
                                  attrs: {
                                    hidden: "",
                                    type: "file",
                                    accept: ".csv",
                                    id: "excelFileId",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onFileUpload(
                                        validate,
                                        $event.target
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("p", { staticClass: "mt-1" }, [
                        _vm._v(
                          "File can be in a CSV format. further instructions regarding the file structure can be found "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.ngsmedicare.com/fee-schedule-lookup",
                            },
                          },
                          [_vm._v("here")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }