var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { title: "Search filters" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: { placeholder: "Name" },
                    on: {
                      input: function ($event) {
                        return _vm.onFieldChange("name", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: { placeholder: "Chart #" },
                    on: {
                      input: function ($event) {
                        return _vm.onFieldChange("chart", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: { type: "date", placeholder: "DOB" },
                    on: {
                      input: function ($event) {
                        return _vm.onFieldChange("dob", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: { placeholder: "Claim #" },
                    on: {
                      input: function ($event) {
                        return _vm.onFieldChange("visit.claimNumber", $event)
                      },
                    },
                    model: {
                      value: _vm.claimNumberFilter,
                      callback: function ($$v) {
                        _vm.claimNumberFilter = $$v
                      },
                      expression: "claimNumberFilter",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("TableComponent", {
        ref: "table",
        attrs: {
          "items-repo": _vm.patientRepo,
          isDataFromModel: false,
          fields: _vm.fields,
          isAddEditSidbar: false,
          "add-edit-link": {
            name: "patient-details",
            params: { id: _vm.selectedPatient.id },
          },
          "get-by-id-on-edit-filter": () => false,
          "sidebar-data": _vm.selectedPatient,
          "load-params": { claimNumber: _vm.claimNumberFilter },
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedPatient = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedPatient = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }