var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-card",
            [
              _c(
                "b-form",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("h5", { staticClass: "mb-1" }, [
                            _vm._v("Member id Prefix / Suffix"),
                          ]),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Member Id prefix",
                                "label-for": "memberIdPrefix",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Member Id prefix",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "memberIdPrefix",
                                            placeholder: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .memberIdPrefix,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "memberIdPrefix",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.memberIdPrefix",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Member id suffix",
                                "label-for": "memberIdSuffix",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Member id suffix" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: { id: "memberIdSuffix" },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .memberIdSuffix,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "memberIdSuffix",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.memberIdSuffix",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            model: {
                              value: _vm.insurancePlanCopy.credentialingAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "credentialingAddress",
                                  $$v
                                )
                              },
                              expression:
                                "insurancePlanCopy.credentialingAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "mt-5 text-right",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("label", [_vm._v("No changes made")]),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(255, 255, 255, 0.15)",
                                  expression: "'rgba(255, 255, 255, 0.15)'",
                                  modifiers: { 400: true },
                                },
                              ],
                              staticClass: "ml-1 mr-1",
                              attrs: { variant: "outline-primary" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(255, 255, 255, 0.15)",
                                  expression: "'rgba(255, 255, 255, 0.15)'",
                                  modifiers: { 400: true },
                                },
                              ],
                              attrs: { variant: "primary", type: "submit" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.validationForm.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "CheckIcon" },
                              }),
                              _c("span", { staticClass: "align-middle" }, [
                                _vm._v("Save"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }