var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Required Modifiers" } },
                [
                  _c("infinit-select", {
                    ref: "reqModifiersSelect",
                    attrs: {
                      repo: _vm.ModifiersRepo,
                      "include-ids":
                        _vm.procSettingsCopyRef.requiredModifierSettings,
                      selectable: (x) => x.status == "Active",
                      multiple: true,
                      label: "code",
                    },
                    on: {
                      "option:selecting": _vm.onSelected,
                      "option:deselected": _vm.onDeselected,
                      "insurance-options-update": _vm.updateDisallowedOptions,
                    },
                    model: {
                      value: _vm.procSettingsCopyRef.requiredModifierSettings,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.procSettingsCopyRef,
                          "requiredModifierSettings",
                          $$v
                        )
                      },
                      expression:
                        "procSettingsCopyRef.requiredModifierSettings",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Allowed Modifiers" } },
                [
                  _c("infinit-select", {
                    ref: "allowedModifiersSelect",
                    attrs: {
                      repo: _vm.ModifiersRepo,
                      "include-ids":
                        _vm.procSettingsCopyRef.allowedModifierSettings,
                      selectable: (x) => x.status == "Active",
                      multiple: true,
                      label: "code",
                      sourceIdField: "ModifiersCodeId",
                    },
                    on: {
                      "option:selecting": _vm.onSelected,
                      "option:deselected": _vm.onDeselected,
                      "insurance-options-update": _vm.updateDisallowedOptions,
                    },
                    model: {
                      value: _vm.procSettingsCopyRef.allowedModifierSettings,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.procSettingsCopyRef,
                          "allowedModifierSettings",
                          $$v
                        )
                      },
                      expression: "procSettingsCopyRef.allowedModifierSettings",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Disallowed Modifiers" } },
                [
                  _c("infinit-select", {
                    ref: "disallowedModifiersSelect",
                    attrs: {
                      repo: _vm.ModifiersRepo,
                      "include-ids":
                        _vm.procSettingsCopyRef.disallowedModifierSettings,
                      selectable: (x) => x.status == "Active",
                      multiple: true,
                      label: "code",
                    },
                    on: {
                      "option:selecting": _vm.onSelected,
                      "option:deselected": _vm.onDeselected,
                      "insurance-options-update": _vm.updateDisallowedOptions,
                    },
                    model: {
                      value: _vm.procSettingsCopyRef.disallowedModifierSettings,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.procSettingsCopyRef,
                          "disallowedModifierSettings",
                          $$v
                        )
                      },
                      expression:
                        "procSettingsCopyRef.disallowedModifierSettings",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }