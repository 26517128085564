var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: { copyData: _vm.userCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.userCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.userCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6", sm: "8", xs: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "First Name" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "First Name",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.userCopy.firstName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userCopy,
                                                  "firstName",
                                                  $$v
                                                )
                                              },
                                              expression: "userCopy.firstName",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Last Name" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Last Name",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.userCopy.lastName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userCopy,
                                                  "lastName",
                                                  $$v
                                                )
                                              },
                                              expression: "userCopy.lastName",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "User Type" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "User Type",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              options: [
                                                {
                                                  value: "Admin",
                                                  text: "Admin",
                                                },
                                                { value: "User", text: "User" },
                                              ],
                                            },
                                            model: {
                                              value: _vm.userCopy.type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userCopy,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression: "userCopy.type",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Status" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Status", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              options: [
                                                {
                                                  value: "Active",
                                                  text: "Active",
                                                },
                                                {
                                                  value: "Inactive",
                                                  text: "Inactive",
                                                },
                                              ],
                                            },
                                            model: {
                                              value: _vm.userCopy.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userCopy,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "userCopy.status",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Email" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Email", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.userCopy.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userCopy,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "userCopy.email",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v
      },
      expression: "formData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }