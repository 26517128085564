var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.contactRoleTypeRepo,
          "is-data-from-model": false,
          "sidebar-data": _vm.selectedCRT,
          "copy-data": _vm.selectedCRTCopy,
          "sidebar-open": _vm.sidebar,
          fields: _vm.fields,
          validate: true,
          "is-add-edit-sidbar": true,
          "do-save": true,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedCRT = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedCRT = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedCRTCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedCRTCopy = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.selectedCRT.id
                              ? _c("h4", [_vm._v("Edit Contact Role Type")])
                              : _c("h4", [_vm._v("Add Contact Role Type")]),
                            _c(
                              "b-form-group",
                              { staticClass: "mt-2", attrs: { label: "Role" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Role", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: { required: "" },
                                            model: {
                                              value:
                                                _vm.selectedCRTCopy.roleName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedCRTCopy,
                                                  "roleName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedCRTCopy.roleName",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Description" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Description",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              placeholder: "",
                                              rows: "3",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.selectedCRTCopy.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedCRTCopy,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedCRTCopy.description",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }