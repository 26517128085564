<template>
  <div>
    <b-card no-body>
      <b-row class="px-2">
        <b-col
          md="12"
          sm="12"
          xs="12"
          class="my-1 pl-2 text-right"
        >
          <b-button
            type="button"
            variant="outline-primary"
            size="sm"
            pill
          >
            <feather-icon
              icon="UploadIcon"
              size="16"
            />
          </b-button>
          <b-button
            type="button"
            variant="outline-primary"
            size="sm"
            pill
            class="ml-1"
          >
            <feather-icon
              icon="DownloadIcon"
              size="16"
            />
          </b-button>
          <b-button
            type="button"
            variant="primary"
            size="sm"
            pill
            class="ml-1"
            @click="onRowSelected({}, 'add')"
          >
            + Add
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            selectable
            select-mode="single"
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="apiData.data.list"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(procCode)="data">
              {{ data.item.procCode }}
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(description)="data">
              {{ data.item.description }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="d-flex px-0"
                  @click="onRowSelected(data.item, 'edit')"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="float-right px-0 ml-1"
                  size="sm"
                  @click="deleteRow(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="3"
          class="pb-1 pl-2"
        >
          <label class="d-inline-block text-sm-left mr-50 pl-2">Show</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-col>
        <b-col
          cols="9"
          class="pb-1"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0 pr-2"
          />
        </b-col>
      </b-row>
    </b-card>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-sidebar
          id="sidebar-right"
          bg-variant="white"
          no-header
          v-model="sidebar"
          right
          backdrop
          shadow
        >
          <b-container class="px-2 mt-1 pb-3">
            <b-row>
              <b-col>
                <h4 v-if="mode=='edit'">Edit Fixed Amounts</h4>
                <h4 v-else>Add Fixed Amounts</h4>
                <b-form-group
                  label="Procedure Code"
                  class="mt-2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Procedure Code"
                    rules="required"
                  >
                    <b-form-input
                      placeholder=""
                      v-model="selectedRow.procCode"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Name"
                  class="mt-2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      placeholder=""
                      v-model="selectedRow.name"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Description"
                  class="mt-2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <b-form-input
                      placeholder=""
                      v-model="selectedRow.description"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      label="Amount"
                      class="mt-2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Amount"
                        rules="required"
                      >
                        <!-- <b-form-input
                          type="number"
                          v-model="selectedRow.amount"
                          required
                        /> -->
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            type="number"
                            v-model="selectedRow.amount"
                            required
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="DollarSignIcon"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                
              </b-col>
            </b-row>
          </b-container>
          <template #footer>
            <b-container>
              <b-row>
                <b-col>
                  <div class="w-100 text-right pb-2">
                    <b-button
                      v-if="mode == 'edit'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      @click="deleteRow(selectedRow.id)"
                    >
                      <span class="align-middle">Delete</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="ml-1 mr-1"
                      @click="sidebar = false"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      type="submit"
                      @click.prevent="validationForm"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Done</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </b-sidebar>
      </b-form>
    </validation-observer>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref } from 'vue-property-decorator';
import Procedures from '../../../../../../modules/Procedures';
import Ripple from 'vue-ripple-directive'
import {
  BAvatar, BInputGroupAppend, BSidebar, BCard, BTable, BBadge, BContainer, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { ContactMandatoryTypes } from '../../../../../../types/procedureTypes';

@Component({
  components: {
    BSidebar,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BForm,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BAvatar,
  },
  directives: {
    Ripple,
  }
})
export default class PercentCC extends Vue {

  // DATA
  @Ref('simpleRules') readonly refs_simpleRules!: any;
  procedures = new Procedures();
  apiData:ApiMethodResponse<ContactMandatoryTypes> = {
    message: '',
    data: {
      list: [],
      rowsCount: 0,
    },
  };
  selectedRow:ContactMandatoryTypes = {
    id: null,
    procCode: null,
    name: null,
    description: null,
    amount: null
  };
  contractType = null;
  medicare = null;
  feeSchedule = null;
  mode = '';
  result:string = '';
  sidebar:boolean = false;
  statusChange = null;
  perPage = 5;
  pageOptions = [3, 5, 10];
  totalRows = 1;
  currentPage = 1;
  sortBy = '';
  sortDesc = false;
  sortDirection = 'asc';
  filter = null;
  filterOn = [];
  selector = null;
  fields = [
    { key: 'procCode', label: 'procedure code', sortable: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'description', label: 'description', sortable: true },
    { key: 'amount', label: 'amount + percent', sortable: true },
    { key: 'action', label: 'Actions'},
  ];

  // COMPUTED

  // METHODS
  onRowSelected(rowItem, mode) {
    this.mode = mode;
    if(mode == 'add') {
      this.selectedRow  = {
        id: null,
        procCode: null,
        name: null,
        description: null,
        amount: null
      }
    } else {
      this.selectedRow = { ...rowItem };
    }
    this.sidebar = true;
  }
  deleteRow(id) {
    alert("Delete " + id);
    this.sidebar = false;
    // this.result = this.settings.deleteClearingHouses(id);
  }
  validationForm() {
    this.refs_simpleRules.validate().then(success => {
      if (success) {
        // eslint-disable-next-line
        alert('form submitted!');
        if(this.mode == 'add') {
          // this.result = this.practices.addClearingHouses(this.selectedRow);
        } else if (this.mode == 'edit') {
          // this.result = this.practices.updateClearingHouses('', this.selectedRow);
        }
      }
    })
  }
  onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length
    this.currentPage = 1
  }

  // CREATED
  async created() {
    this.apiData = this.procedures.getContactMandatory('id');
  }

  // MOUNTED
  mounted() {
    this.totalRows = this.apiData.data.list!.length
  }
}
</script>
<style lang="scss" scoped>
@import '/src/assets/scss/variables/_variables.scss';
.danger-text {
  color: var(--danger);
  font-weight: bold;
}
</style>
