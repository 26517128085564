/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    DtoModelResponse,
    GeneralStatus, PatientArDto,
    SortType,
    VisitDto,
    VisitDtoListResponseModel,
    VisitDtoModelResponse
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Visit<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Visit
   * @name PostVisit
   * @request POST:/Visit/Add
   * @secure
   */
  postVisit = (data: VisitDto, params: RequestParams = {}) =>
    this.request<VisitDtoModelResponse, any>({
      path: `/Visit/Add`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Visit
   * @name LoadList
   * @request GET:/Visit/Load
   * @secure
   */
  loadList = (
    query?: {
      /** @format int32 */
      Take?: number;
      /** @format int32 */
      Skip?: number;
      IncludeIds?: number[];
      orderByField?: string;
      orderByType?: SortType;
      SearchTerm?: string;
      ColumnFilters?: string;
      Status?: GeneralStatus;
      /** @format int64 */
      PracticeId?: number;
      ForDropdown?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<VisitDtoListResponseModel, any>({
      path: `/Visit/Load`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Visit
   * @name GetByIdList
   * @request GET:/Visit/GetById
   * @secure
   */
  getByIdList = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<VisitDtoModelResponse, any>({
      path: `/Visit/GetById`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });

    GetPatientAr = (
        query?: {
            /** @format int64 */
            patientId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<DtoModelResponse<PatientArDto>, any>({
            path: `/Visit/GetPatientAr`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
  /**
   * No description
   *
   * @tags Visit
   * @name DeleteDelete
   * @request DELETE:/Visit/Delete
   * @secure
   */
  deleteDelete = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<VisitDtoModelResponse, any>({
      path: `/Visit/Delete`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Visit
   * @name UpdateCreate
   * @request POST:/Visit/Update
   * @secure
   */
  updateCreate = (data: VisitDto, params: RequestParams = {}) =>
    this.request<VisitDtoModelResponse, any>({
      path: `/Visit/Update`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
