var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("form-component", {
        attrs: { copyData: _vm.procedureTypeCopy },
        on: {
          "update:copyData": function ($event) {
            _vm.procedureTypeCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.procedureTypeCopy = $event
          },
          save: _vm.save,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "6", sm: "8", xs: "12" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Procedure Type Name" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Procedure Type",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.procedureTypeCopy.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.procedureTypeCopy,
                                                      "type",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "procedureTypeCopy.type",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Procedure Type Code" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Procedure Type Code",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options:
                                                    _vm.procedureTypeType,
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.procedureTypeCopy
                                                      .procType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.procedureTypeCopy,
                                                      "procType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "procedureTypeCopy.procType",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Description" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Description",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  row: "4",
                                                  placeholder: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.procedureTypeCopy
                                                      .description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.procedureTypeCopy,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "procedureTypeCopy.description",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label:
                                        "Requires a unique primary diagnosis",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      staticClass: "demo-inline-spacing",
                                      attrs: {
                                        name: "Primary Diagnosis",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "pt-0 mt-0",
                                                  attrs: {
                                                    name: "Primary Diagnosis",
                                                    value: true,
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.procedureTypeCopy
                                                        .isPrimaryDiagnosis,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.procedureTypeCopy,
                                                        "isPrimaryDiagnosis",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "procedureTypeCopy.isPrimaryDiagnosis",
                                                  },
                                                },
                                                [_vm._v(" Yes ")]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  staticClass: "pt-0 mt-0",
                                                  attrs: {
                                                    name: "Primary Diagnosis",
                                                    value: false,
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.procedureTypeCopy
                                                        .isPrimaryDiagnosis,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.procedureTypeCopy,
                                                        "isPrimaryDiagnosis",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "procedureTypeCopy.isPrimaryDiagnosis",
                                                  },
                                                },
                                                [_vm._v(" No ")]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }