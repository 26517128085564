var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            { staticClass: "pl-2 pt-2", attrs: { pills: "" } },
            [
              _c(
                "b-tab",
                { attrs: { active: "", title: "Details" } },
                [
                  _c("ProviderInfoCC", {
                    ref: "provider-details",
                    attrs: { mode: _vm.mode },
                    on: {
                      save: _vm.save,
                      "delete-provider": _vm.deleteProvider,
                    },
                    model: {
                      value: _vm.provider,
                      callback: function ($$v) {
                        _vm.provider = $$v
                      },
                      expression: "provider",
                    },
                  }),
                ],
                1
              ),
              _c(
                "Can",
                { attrs: { I: "View", a: "Profiles" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Profiles" } },
                    [_c("profiles-component")],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Can",
                { attrs: { lazy: "", I: "View", a: "ListOptionsSpecialties" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { lazy: "", title: "Specialties" } },
                    [
                      _c("SpecialtiesCC", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.provider,
                          callback: function ($$v) {
                            _vm.provider = $$v
                          },
                          expression: "provider",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-tab", { attrs: { title: "Activity & Notes" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }