var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          sidebarData: _vm.formData,
          fields: _vm.fields,
          isAddEditSidbar: false,
          deleteFilter: () => false,
          addEditLink: {
            name: "ins-plans-details",
            params: { id: _vm.formData.id ? _vm.formData.id : "new" },
            query: { insuranceId: +_vm.$route.params.id },
          },
          getByIdOnEditFilter: () => false,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.formData = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.formData = $event
          },
        },
        model: {
          value: _vm.items,
          callback: function ($$v) {
            _vm.items = $$v
          },
          expression: "items",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }