<template>
  <div>
    <b-card no-body>
      <fee-schedule-connect/>
      <h4>Fee Schedule Overrides</h4>
      <fee-schedule-override-c-c/> 
    </b-card>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import {
  BCard
} from 'bootstrap-vue';
import FeeScheduleConnect
  from "@/class-components/practices/practices-details/corporations-more/corporations-more-more/fee-schedules-more/FeeScheduleConnect.vue";
import ProcedurModPriceSidebar
  from "@/class-components/practices/practices-details/profiles-more/plansdetails-more/components/ProcedurModPriceSidebar.vue";
import FeeScheduleOverrideCC
  from "@/class-components/practices/practices-details/corporations-more/corporations-more-more/fee-schedules-more/FeeScheduleOverrideCC.vue";

@Component({
  components: {
    FeeScheduleOverrideCC,
    ProcedurModPriceSidebar,
    FeeScheduleConnect,
    BCard
  }
})
export default class FeeSchedulesCC extends Vue {

}
</script>
<style lang="scss" scoped>
@import '/src/assets/scss/variables/_variables.scss';
.danger-text {
  color: var(--danger);
  font-weight: bold;
}
</style>
