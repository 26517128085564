var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.corporationsRepo,
          "load-params": { PracticeId: _vm.$route.params.id },
          isDataFromModel: false,
          sidebarData: _vm.selectedCorp,
          fields: _vm.fields,
          isAddEditSidbar: false,
          copyData: _vm.selectedCorpCopy,
          "add-edit-link": {
            name: "practices-details-view-corporations",
            params: {
              itemData: _vm.selectedCorp.id ? _vm.selectedCorp.id : "new",
            },
          },
          "get-by-id-on-edit-filter": () => false,
          "delete-filter": () => false,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedCorp = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedCorp = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedCorpCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedCorpCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(corporation)",
            fn: function (data) {
              return [
                _c("b", [_vm._v(_vm._s(data.item.name))]),
                _c("br"),
                _c("span", { staticClass: "grey-text smaller-text" }, [
                  _vm._v(
                    " NPI: " +
                      _vm._s(data.item.npiNumber) +
                      " | SC: " +
                      _vm._s(data.item.code) +
                      " "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }