var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: {
      copyData: _vm.planCopy,
      showStatus: _vm.planData.id,
      validate: true,
    },
    on: {
      "update:copyData": function ($event) {
        _vm.planCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.planCopy = $event
      },
      save: _vm.save,
      "on-copy": _vm.onCopy,
    },
    scopedSlots: _vm._u([
      {
        key: "status-right",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex float-right text-right" },
              [
                _vm._v(" Primary "),
                _c("b-form-checkbox", {
                  staticClass: "ml-1 custom-control-light",
                  attrs: { name: "check-button", switch: "" },
                  model: {
                    value: _vm.planCopy.isPrimary,
                    callback: function ($$v) {
                      _vm.$set(_vm.planCopy, "isPrimary", $$v)
                    },
                    expression: "planCopy.isPrimary",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              { staticClass: "mb-4 ml-2" },
              [
                _c("b-col", { attrs: { cols: "3" } }, [
                  _c("label", { attrs: { for: "id" } }, [
                    _vm._v(
                      "Profile ID: " +
                        _vm._s(
                          _vm.planData.profile ? _vm.planData.profile.id : "N/A"
                        )
                    ),
                  ]),
                  _c("br"),
                  _c("label", { attrs: { for: "shortCode" } }, [
                    _vm._v(
                      "Short Code: " +
                        _vm._s(
                          _vm.planData.profile
                            ? _vm.planData.profile.code
                            : "N/A"
                        )
                    ),
                  ]),
                  _c("br"),
                  _c("label", { attrs: { for: "providerName" } }, [
                    _vm._v(
                      "Provider name: " +
                        _vm._s(
                          _vm.planData.profile
                            ? _vm.planData.profile.provider
                              ? _vm.planData.profile.provider.name
                              : "N/A"
                            : "N/A"
                        )
                    ),
                  ]),
                ]),
                _c(
                  "b-col",
                  [
                    _c("b-col", [
                      _c("label", { attrs: { for: "corporation" } }, [
                        _vm._v(
                          "Corporation name: " +
                            _vm._s(
                              _vm.planData.profile
                                ? _vm.planData.profile.corporation
                                  ? _vm.planData.profile.corporation.name
                                  : "N/A"
                                : "N/A"
                            )
                        ),
                      ]),
                      _c("br"),
                      _c("label", { attrs: { for: "location" } }, [
                        _vm._v(
                          "Location name: " +
                            _vm._s(
                              _vm.planData.profile
                                ? _vm.planData.profile.location
                                  ? _vm.planData.profile.location.name
                                  : "N/A"
                                : "N/A"
                            )
                        ),
                      ]),
                      _c("br"),
                      _c("label", { attrs: { for: "insCompany" } }, [
                        _vm._v(
                          "Insurance company: " +
                            _vm._s(
                              _vm.planData.profile
                                ? _vm.planData.profile.insurance
                                  ? _vm.planData.profile.insurance.name
                                  : "N/A"
                                : "N/A"
                            )
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Provider Id",
                          "label-for": "basicInput",
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Provider Id", rules: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.profile.providerId,
                                      disabled: "",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "In/Out Network",
                          "label-for": "contractType",
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: { name: "In/Out Network", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "contractType",
                                      options: [
                                        { text: "Select", value: null },
                                        {
                                          text: "Out of network",
                                          value: _vm.contractTypes.OutOfNetwork,
                                        },
                                        {
                                          text: "In network",
                                          value: _vm.contractTypes.InNetwork,
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.planCopy.contractType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.planCopy,
                                          "contractType",
                                          $$v
                                        )
                                      },
                                      expression: "planCopy.contractType",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Effective Date",
                          "label-for": "effectiveDate",
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Effective Date", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      id: "effectiveDate",
                                      type: "date",
                                    },
                                    model: {
                                      value: _vm.planCopy.daetStart,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.planCopy, "daetStart", $$v)
                                      },
                                      expression: "planCopy.daetStart",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      { attrs: { label: "End Date", "label-for": "endDate" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "End Date", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("b-form-datepicker", {
                                    attrs: { id: "endDate", type: "date" },
                                    model: {
                                      value: _vm.planCopy.dateEnd,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.planCopy, "dateEnd", $$v)
                                      },
                                      expression: "planCopy.dateEnd",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Insurance Plan",
                          "label-for": "insurancePlan",
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Insurance Plan", rules: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("infinit-select", {
                                    attrs: {
                                      disabled: !_vm.profile.insuranceId,
                                      filter: {
                                        InsuranceId: _vm.profile.insuranceId,
                                      },
                                      includeIds: _vm.selectedPlans,
                                      multiple: true,
                                      repo: _vm.insurancePlansRepo,
                                      label: "name",
                                    },
                                    model: {
                                      value: _vm.selectedPlans,
                                      callback: function ($$v) {
                                        _vm.selectedPlans = $$v
                                      },
                                      expression: "selectedPlans",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "can",
                      { attrs: { I: "View", a: "ScrubberProcedureSettings" } },
                      [
                        _c(
                          "b-row",
                          { staticClass: "px-2 py-2" },
                          [
                            _c(
                              "b-col",
                              [
                                _c("h4", [_vm._v("EFT/ERA Setup")]),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { "label-for": "hasEFT" },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: { name: "EFT", rules: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: { id: "hasEFT" },
                                                  model: {
                                                    value:
                                                      _vm.planCopy.isEftSetup,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.planCopy,
                                                        "isEftSetup",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "planCopy.isEftSetup",
                                                  },
                                                },
                                                [_vm._v(" Has EFT setup ")]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  { attrs: { "label-for": "hasERA" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: { name: "ERA", rules: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: { id: "hasERA" },
                                                  model: {
                                                    value:
                                                      _vm.planCopy.isEraSetup,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.planCopy,
                                                        "isEraSetup",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "planCopy.isEraSetup",
                                                  },
                                                },
                                                [_vm._v(" Has ERA setup ")]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.planData,
      callback: function ($$v) {
        _vm.planData = $$v
      },
      expression: "planData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }