var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "tabs-component",
        { attrs: { pills: "" } },
        [
          _c(
            "b-tab",
            {
              attrs: { active: "" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Clearing Houses ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("ClearingHousesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" EHR Systems ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("EHRsystemsCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Modifiers ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("ModifiersCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Diagnosis ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("DiagnosisCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Contact Role Types ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("ContactRoleTypesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" POS codes ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("POScodesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Dr. Specialties ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("DrSpecialtiesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Insurance Plan Types ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("InsurancePlanCC")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }