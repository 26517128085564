<template>
  <div>
    <b-card no-body>
      <b-row class="px-2">
        <b-col
          md="6"
          sm="6"
          xs="12"
          class="my-1 pl-2"
        >
          <b-form-group
            label="Search"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 d-flex align-items-center"
          >
            <b-input-group size="sm" class="input-group-merge">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          sm="6"
          xs="6"
          class="my-1 pr-2 text-right"
        >
          <b-button
            type="button"
            variant="primary"
            size="sm"
            pill
            @click="onRowSelected({}, 'add')"
          >
            + Add
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            selectable
            select-mode="single"
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(procCode)="data">
              {{ data.value }}
            </template>
            <template #cell(procType)="data">
              {{ data.value }}
            </template>
            <template #cell(description)="data">
              {{ data.value }}
            </template>
            <template #cell(rvuCode)="data">
              {{ data.value }}
            </template>
            <template #cell(action)="data">
              <div class="text-center">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  @click="onRowSelected(data.item, 'edit')"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="d-flex px-0 ml-1"
                  size="sm"
                  @click="deleteRow(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                  />
                </b-button> -->
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="3"
          class="pb-1 pl-2"
        >
          <label class="d-inline-block text-sm-left mr-50 pl-2">Show</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-col>
        <b-col
          cols="9"
          class="pb-1"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0 pr-2"
          />
        </b-col>
      </b-row>
    </b-card>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-sidebar
          id="sidebar-right"
          bg-variant="white"
          no-header
          v-model="sidebar"
          right
          backdrop
          shadow
        >
          <b-container class="px-2 mt-1">
            <b-row>
              <b-col>
                <h4 v-if="mode=='edit'">Edit Note</h4>
                <h4 v-else>Add Note</h4>
                  <b-form-group
                    label="Note"
                    class="mt-2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Note"
                      rules="required"
                    >
                      <b-form-textarea
                        placeholder=""
                        row="3"
                        v-model="selectedRow.note"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-container>
          <template #footer>
            <b-container>
              <b-row>
                <b-col>
                  <!-- <div v-if="mode == 'edit'" class="w-100 text-left pl-1 pb-2">
                    <span class="danger-text">Note:</span> This EHR system is linked to a practice(s), EHR systems that are linked to a practice can’t be deleted.
                  </div> -->
                  <div class="w-100 text-right pb-2">
                    <!-- <b-button
                      v-if="mode == 'edit'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      @click="deleteRow(selectedRow.id)"
                    >
                      <span class="align-middle">Delete</span>
                    </b-button> -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="mr-1"
                      @click="sidebar = false"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      type="submit"
                      @click.prevent="validationForm"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Done</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </b-sidebar>
      </b-form>
    </validation-observer>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref } from 'vue-property-decorator';
import Procedures from '../../../modules/Procedures';
import Ripple from 'vue-ripple-directive'
import {
  BFormTextarea, BInputGroupAppend, BSidebar, BCard, BTable, BBadge, BContainer, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { ProcActivityNotesTypes } from '../../../types/procedureTypes';

@Component({
  components: {
    BSidebar,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BForm,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BFormTextarea,
  },
  directives: {
    Ripple,
  }
})
export default class ActivityAndNotesCC extends Vue {

  // DATA
  @Ref('simpleRules') readonly refs_simpleRules!: any;
  procedures = new Procedures();
  apiData:ApiMethodResponse<ProcActivityNotesTypes>;
  items:Array<ProcActivityNotesTypes> = [];
  selectedRow:ProcActivityNotesTypes = {
    id: null,
    date: null,
    time: null,
    actionBy: null,
    change: null,
  };
  mode = '';
  result:ApiMethodResponse<ProcActivityNotesTypes>;
  sidebar:boolean = false;
  perPage = 5;
  pageOptions = [3, 5, 10];
  totalRows = 1;
  currentPage = 1;
  sortBy = '';
  sortDesc = false;
  sortDirection = 'asc';
  filter = null;
  filterOn = [];
  selector = null;
  fields = [
    { key: 'date', label: 'Date', sortable: true },
    { key: 'time', label: 'Time', sortable: true },
    { key: 'actionBy', label: 'Action By'},
    { key: 'change', label: 'Change / Note', sortable: true },
    { key: 'action', label: 'Actions'},
  ];

  // METHODS
  onRowSelected(rowItem, mode) {
    this.mode = mode;
    if(mode == 'add') {
      this.selectedRow = {
        id: null,
        date: null,
        time: null,
        actionBy: null,
        change: null,
      };
    } else {
      this.selectedRow = { ...rowItem };
    }
    this.sidebar = true;
  }
  deleteRow(id) {
    alert("Delete " + id);
    this.sidebar = false;
    this.result = this.procedures.deleteActivityAndNotesCC(id);
  }
  validationForm() {
    this.refs_simpleRules.validate().then(success => {
      if (success) {
        // eslint-disable-next-line
        alert('form submitted!');
        if(this.mode == 'add') {
          this.result = this.procedures.addActivityAndNotesCC(this.selectedRow);
        } else if (this.mode == 'edit') {
          this.result = this.procedures.updateActivityAndNotesCC('', this.selectedRow);
        }
      }
    })
  }
  onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length
    this.currentPage = 1
  }

  // CREATED
  async created() {
    this.apiData = await this.procedures.getActivityNotes('id');
    this.items = [ ...this.apiData.data.list || [] ];
  }

  // MOUNTED
  mounted() {
    this.totalRows = this.items.length
  }
}
</script>
<style lang="scss" scoped>
@import '/src/assets/scss/variables/_variables.scss';
.danger-text {
  color: var(--danger);
  font-weight: bold;
}
</style>
