import { render, staticRenderFns } from "./PatientClaims.vue?vue&type=template&id=1850a4d4&scoped=true"
import script from "./PatientClaims.vue?vue&type=script&setup=true&lang=ts"
export * from "./PatientClaims.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1850a4d4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1850a4d4')) {
      api.createRecord('1850a4d4', component.options)
    } else {
      api.reload('1850a4d4', component.options)
    }
    module.hot.accept("./PatientClaims.vue?vue&type=template&id=1850a4d4&scoped=true", function () {
      api.rerender('1850a4d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/Patients/PatientClaims.vue"
export default component.exports