import { render, staticRenderFns } from "./OverwrittenProcedures.vue?vue&type=template&id=79cc4ecf&scoped=true"
import script from "./OverwrittenProcedures.vue?vue&type=script&lang=ts"
export * from "./OverwrittenProcedures.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79cc4ecf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-test/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79cc4ecf')) {
      api.createRecord('79cc4ecf', component.options)
    } else {
      api.reload('79cc4ecf', component.options)
    }
    module.hot.accept("./OverwrittenProcedures.vue?vue&type=template&id=79cc4ecf&scoped=true", function () {
      api.rerender('79cc4ecf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/practices/practices-details/settings-more/OverwrittenProcedures.vue"
export default component.exports