var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    [
      _c("form-component", {
        attrs: { copyData: _vm.procSettingsCopy, validate: true },
        on: {
          "update:copyData": function ($event) {
            _vm.procSettingsCopy = $event
          },
          "update:copy-data": [
            function ($event) {
              _vm.procSettingsCopy = $event
            },
            function ($event) {
              return _vm.$emit("update:procSettingsCopy", $event)
            },
          ],
          save: _vm.save,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-alert",
                          { attrs: { variant: "primary", show: "" } },
                          [
                            _c("div", { staticClass: "alert-body" }, [
                              _c(
                                "span",
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-25",
                                    attrs: {
                                      icon: "AlertCircleIcon",
                                      size: "18",
                                    },
                                  }),
                                  _vm._v(
                                    " Data on this page is derived from the "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      "procedure type level and procedure code level"
                                    ),
                                  ]),
                                  _vm._v(
                                    " , you can override specific fields by clicking the "
                                  ),
                                  _c("feather-icon", {
                                    staticClass:
                                      "ml-30 mr-30 secondary-text cursor-pointer",
                                    attrs: { icon: "EditIcon", size: "16" },
                                  }),
                                  _vm._v(" icon "),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c("h5", [_vm._v("Global period settings")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Preoperative period" } },
                                  [
                                    _c("input-override", {
                                      attrs: {
                                        overrideData: _vm.overrideProcSettings
                                          .globalPeriodDaysPre
                                          ? _vm.overrideProcSettings.globalPeriodDaysPre.toString()
                                          : "",
                                      },
                                      model: {
                                        value:
                                          _vm.procSettingsCopy
                                            .globalPeriodDaysPre,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.procSettingsCopy,
                                            "globalPeriodDaysPre",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "procSettingsCopy.globalPeriodDaysPre",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Postoperative period" } },
                                  [
                                    _c("input-override", {
                                      attrs: {
                                        overrideData: _vm.overrideProcSettings
                                          .globalPeriodDays
                                          ? _vm.overrideProcSettings.globalPeriodDays.toString()
                                          : "",
                                      },
                                      model: {
                                        value:
                                          _vm.procSettingsCopy.globalPeriodDays,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.procSettingsCopy,
                                            "globalPeriodDays",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "procSettingsCopy.globalPeriodDays",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Type" } },
                                  [
                                    _c("single-select-override", {
                                      attrs: {
                                        "options-enum": _vm.GlobalPeriodType,
                                        "is-options-from-repo": false,
                                        "is-options-enum": true,
                                        "override-data":
                                          _vm.overrideProcSettings
                                            .globalPeriodType,
                                      },
                                      model: {
                                        value:
                                          _vm.procSettingsCopy.globalPeriodType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.procSettingsCopy,
                                            "globalPeriodType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "procSettingsCopy.globalPeriodType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c("h5", [
                                        _vm._v("Patient requirements"),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Ages suitability from",
                                            },
                                          },
                                          [
                                            _c("input-override", {
                                              attrs: {
                                                "override-data":
                                                  _vm.overrideProcSettings
                                                    .ageMin,
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.procSettingsCopy.ageMin,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procSettingsCopy,
                                                    "ageMin",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "procSettingsCopy.ageMin",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          { attrs: { label: "To" } },
                                          [
                                            _c("input-override", {
                                              attrs: {
                                                "override-data":
                                                  _vm.overrideProcSettings
                                                    .ageMax,
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.procSettingsCopy.ageMax,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procSettingsCopy,
                                                    "ageMax",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "procSettingsCopy.ageMax",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Gender" } },
                                  [
                                    _c("radio-override", {
                                      staticClass: "pt-0 mt-0",
                                      attrs: {
                                        "override-data":
                                          _vm.overrideProcSettings.gender,
                                        label: "Gender",
                                        name: "Gender",
                                        options: _vm.Gender,
                                        required: "",
                                        icon: "",
                                      },
                                      model: {
                                        value: _vm.procSettingsCopy.gender,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.procSettingsCopy,
                                            "gender",
                                            $$v
                                          )
                                        },
                                        expression: "procSettingsCopy.gender",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-row",
                          { staticClass: "mt-3" },
                          [
                            _c(
                              "b-col",
                              [
                                _c("radio-override", {
                                  attrs: {
                                    "override-data":
                                      _vm.overrideProcSettings
                                        .isJustifiableWriteOff,
                                    label: "Is justifiable write off (WRO)?",
                                  },
                                  model: {
                                    value:
                                      _vm.procSettingsCopy
                                        .isJustifiableWriteOff,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.procSettingsCopy,
                                        "isJustifiableWriteOff",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "procSettingsCopy.isJustifiableWriteOff",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("input-override", {
                          attrs: {
                            label: "Max. Daily claim limit",
                            overrideData:
                              _vm.overrideProcSettings.maxDailyLimit,
                            type: "number",
                          },
                          model: {
                            value: _vm.procSettingsCopy.maxDailyLimit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.procSettingsCopy,
                                "maxDailyLimit",
                                $$v
                              )
                            },
                            expression: "procSettingsCopy.maxDailyLimit",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("input-override", {
                          attrs: {
                            label: "Min. Daily claim limit",
                            overrideData:
                              _vm.overrideProcSettings.minDailyLimit,
                            type: "number",
                          },
                          model: {
                            value: _vm.procSettingsCopy.minDailyLimit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.procSettingsCopy,
                                "minDailyLimit",
                                $$v
                              )
                            },
                            expression: "procSettingsCopy.minDailyLimit",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("single-select-override", {
                          attrs: {
                            label: "Modifiers to use when within daily limit",
                            optionsRepo: _vm.modifiersRepo,
                            optionsLabel: "code",
                            overrideData: _vm.overrideDefaultModCode,
                          },
                          on: {
                            "update:overrideData": function ($event) {
                              _vm.overrideDefaultModCode = $event
                            },
                            "update:override-data": function ($event) {
                              _vm.overrideDefaultModCode = $event
                            },
                          },
                          model: {
                            value:
                              _vm.procSettingsCopy.dailyLimitDefaultModifierId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.procSettingsCopy,
                                "dailyLimitDefaultModifierId",
                                $$v
                              )
                            },
                            expression:
                              "procSettingsCopy.dailyLimitDefaultModifierId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" },
                            ],
                            attrs: {
                              title:
                                "set this if this procedure could be done on the right and left side with RT and LT modifiers",
                              label: "Is RT / LT applicable",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Is RT/LT applicable", rules: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          id: "isRTLTApplicable",
                                          switch: "",
                                        },
                                        model: {
                                          value:
                                            _vm.procSettingsCopy
                                              .isRTLTApplicable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.procSettingsCopy,
                                              "isRTLTApplicable",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "procSettingsCopy.isRTLTApplicable",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("single-select-override", {
                          directives: [
                            { name: "b-tooltip", rawName: "v-b-tooltip" },
                          ],
                          attrs: {
                            title:
                              "set this if the min. daily limit is set to more than 1 to determine if the multiplication is done by the number of procedures or the number of units",
                            label: "Line type",
                            overrideData:
                              _vm.overrideProcSettings
                                .dailyLimitDefaultLineType,
                            isOptionsFromRepo: false,
                            "options-enum": _vm.dailyLimitLineType,
                            "is-options-enum": true,
                          },
                          model: {
                            value:
                              _vm.procSettingsCopy.dailyLimitDefaultLineType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.procSettingsCopy,
                                "dailyLimitDefaultLineType",
                                $$v
                              )
                            },
                            expression:
                              "procSettingsCopy.dailyLimitDefaultLineType",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-row",
                          {
                            staticClass:
                              "border border-primary rounded p-2 my-2",
                          },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { staticClass: "px-3" },
                                      [
                                        _c("h5", { staticClass: "mb-2" }, [
                                          _vm._v("Diagnosis"),
                                        ]),
                                        _c("Diagnosis", {
                                          attrs: {
                                            procSettingsCopy:
                                              _vm.procSettingsCopy,
                                          },
                                          on: {
                                            "update:procSettingsCopy":
                                              function ($event) {
                                                _vm.procSettingsCopy = $event
                                              },
                                            "update:proc-settings-copy":
                                              function ($event) {
                                                _vm.procSettingsCopy = $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { staticClass: "px-3" },
                                      [
                                        _c("h5", { staticClass: "mb-2" }, [
                                          _vm._v("Diagnosis Defaults"),
                                        ]),
                                        _c("Diagnosis", {
                                          directives: [
                                            {
                                              name: "disable-all",
                                              rawName: "v-disable-all",
                                            },
                                          ],
                                          attrs: {
                                            procSettingsCopy:
                                              _vm.overrideProcSettings,
                                          },
                                          on: {
                                            "update:procSettingsCopy":
                                              function ($event) {
                                                _vm.overrideProcSettings =
                                                  $event
                                              },
                                            "update:proc-settings-copy":
                                              function ($event) {
                                                _vm.overrideProcSettings =
                                                  $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right mr-3",
                                            attrs: { variant: "primary" },
                                            on: { click: _vm.cloneDiags },
                                          },
                                          [_vm._v(" Clone default settings ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "mx-2" },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass:
                              "border border-primary rounded p-2 my-2",
                          },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { staticClass: "px-3" },
                                      [
                                        _c("h5", { staticClass: "mb-2" }, [
                                          _vm._v("Modifiers"),
                                        ]),
                                        _c("Modifiers", {
                                          attrs: {
                                            procSettingsCopy:
                                              _vm.procSettingsCopy,
                                          },
                                          on: {
                                            "update:procSettingsCopy":
                                              function ($event) {
                                                _vm.procSettingsCopy = $event
                                              },
                                            "update:proc-settings-copy":
                                              function ($event) {
                                                _vm.procSettingsCopy = $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { staticClass: "px-3" },
                                      [
                                        _c("h5", { staticClass: "mb-2" }, [
                                          _vm._v("Modifiers Defaults"),
                                        ]),
                                        _c("Modifiers", {
                                          directives: [
                                            {
                                              name: "disable-all",
                                              rawName: "v-disable-all",
                                            },
                                          ],
                                          attrs: {
                                            procSettingsCopy:
                                              _vm.overrideProcSettings,
                                          },
                                          on: {
                                            "update:procSettingsCopy":
                                              function ($event) {
                                                _vm.overrideProcSettings =
                                                  $event
                                              },
                                            "update:proc-settings-copy":
                                              function ($event) {
                                                _vm.overrideProcSettings =
                                                  $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right mr-3",
                                            attrs: { variant: "primary" },
                                            on: { click: _vm.cloneMods },
                                          },
                                          [_vm._v(" Clone default settings ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-row",
                          {
                            staticClass:
                              "border border-primary rounded p-2 my-2",
                          },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { staticClass: "px-3" },
                                      [
                                        _c("h5", { staticClass: "mb-2" }, [
                                          _vm._v("Place of Service"),
                                        ]),
                                        _c("Pos", {
                                          attrs: {
                                            procSettingsCopy:
                                              _vm.procSettingsCopy,
                                          },
                                          on: {
                                            "update:procSettingsCopy":
                                              function ($event) {
                                                _vm.procSettingsCopy = $event
                                              },
                                            "update:proc-settings-copy":
                                              function ($event) {
                                                _vm.procSettingsCopy = $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { staticClass: "px-3" },
                                      [
                                        _c("h5", { staticClass: "mb-2" }, [
                                          _vm._v("Place of Service Defaults"),
                                        ]),
                                        _c("Pos", {
                                          directives: [
                                            {
                                              name: "disable-all",
                                              rawName: "v-disable-all",
                                            },
                                          ],
                                          attrs: {
                                            procSettingsCopy:
                                              _vm.overrideProcSettings,
                                          },
                                          on: {
                                            "update:procSettingsCopy":
                                              function ($event) {
                                                _vm.overrideProcSettings =
                                                  $event
                                              },
                                            "update:proc-settings-copy":
                                              function ($event) {
                                                _vm.overrideProcSettings =
                                                  $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right mr-3",
                                            attrs: { variant: "primary" },
                                            on: { click: _vm.clonePos },
                                          },
                                          [_vm._v(" Clone default settings ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.procSettings,
          callback: function ($$v) {
            _vm.procSettings = $$v
          },
          expression: "procSettings",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }