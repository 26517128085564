<template>
  <ProviderMoreTabCC />
</template>

<script lang="ts">
import ProviderMoreTabCC from '/src/class-components/practices/practices-details/provider-more/ProviderMoreTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    ProviderMoreTabCC
  }
})
export default class ProviderMoreTab extends Vue {}
</script>
