<template>
  <InsurancePlansMoreTabCC />
</template>

<script lang="ts">
import InsurancePlansMoreTabCC from '/src/class-components/insurance/InsurancePlansMoreTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    InsurancePlansMoreTabCC
  }
})
export default class InsurancePlansDetails extends Vue {}
</script>
