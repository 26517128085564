var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            {
              staticClass: "pl-2 pt-2",
              attrs: { "base-route": { name: "ins-plans-details" } },
            },
            [
              _c(
                "can",
                { attrs: { I: "View", a: "ScrubberProcedureSettings" } },
                [
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Details")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("DetailsCC", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.formData,
                          callback: function ($$v) {
                            _vm.formData = $$v
                          },
                          expression: "formData",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Other Info")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("OtherInfoCC", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.formData,
                          callback: function ($$v) {
                            _vm.formData = $$v
                          },
                          expression: "formData",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Coverage Info")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("CoverageInfoCC", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.formData,
                          callback: function ($$v) {
                            _vm.formData = $$v
                          },
                          expression: "formData",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Excluded Benefits")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("ExcludedBenefitsCC", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.formData,
                          callback: function ($$v) {
                            _vm.formData = $$v
                          },
                          expression: "formData",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: {
                    to: { name: "ins-plans-details-eligibility-&-claims" },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Eligibility & Claims")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("EligibilityClaimsMoreTabCC", {
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "can",
                { attrs: { I: "View", a: "ScrubberProcedureSettings" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        to: { name: "ins-plans-details-authorization" },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Authorization")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("AuthorizationsMoreTabCC", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.formData,
                          callback: function ($$v) {
                            _vm.formData = $$v
                          },
                          expression: "formData",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-tab", {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Activity & Notes")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }