var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "viewer",
    staticStyle: {
      position: "absolute",
      left: "0",
      right: "2%",
      top: "10%",
      bottom: "10%",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }