var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: {
      copyData: _vm.insurtancePlanCopy,
      validate: true,
      showHeader: true,
      showStatus: _vm.formData.id,
    },
    on: {
      "update:copyData": function ($event) {
        _vm.insurtancePlanCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.insurtancePlanCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$route.params.id == "new"
                    ? "Add Insurance Plan"
                    : _vm.formData.name
                ) +
                " "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-form",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "4", sm: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Insurance Plans Name",
                              "label-for": "planName",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "Insurance Plans Name",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "planName",
                                          placeholder: "",
                                        },
                                        model: {
                                          value: _vm.insurtancePlanCopy.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.insurtancePlanCopy,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "insurtancePlanCopy.name",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Insurance Plans ID",
                                      "label-for": "planId",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: { name: "Insurance Plans ID" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "planId",
                                                  disabled: "",
                                                },
                                                model: {
                                                  value: _vm.formData.id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "id",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.id",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Short Code",
                                      "label-for": "shortCode",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Short Code",
                                        rules: "required|max:5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "shortCode",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.insurtancePlanCopy.code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.insurtancePlanCopy,
                                                      "code",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insurtancePlanCopy.code",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4", sm: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Insurance Company Name",
                              "label-for": "cmpanyName",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "Insurance Company Name",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("infinit-select", {
                                        attrs: {
                                          disabled:
                                            !!_vm.$route.query.insuranceId,
                                          id: "cmpanyName",
                                          includeIds: [
                                            _vm.insurtancePlanCopy.insuranceId,
                                          ],
                                          repo: _vm.insuranceRepo,
                                          label: "name",
                                        },
                                        model: {
                                          value:
                                            _vm.insurtancePlanCopy.insuranceId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.insurtancePlanCopy,
                                              "insuranceId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "insurtancePlanCopy.insuranceId",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Plan type",
                              "label-for": "planType",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Plan type", rules: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("multi-select", {
                                        attrs: {
                                          id: "planType",
                                          dataRepo: _vm.insuranceRepo,
                                          optionsRepo:
                                            _vm.insurancePlanTypesRepo,
                                          sourceIdField: "planTypeId",
                                          label: "name",
                                          required: true,
                                        },
                                        model: {
                                          value:
                                            _vm.insurtancePlanCopy.planTypes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.insurtancePlanCopy,
                                              "planTypes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "insurtancePlanCopy.planTypes",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v
      },
      expression: "formData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }