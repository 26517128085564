var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "fieldset",
      {
        staticClass: "border p-2",
        staticStyle: {
          "border-radius": "10px",
          border: "1px solid #e2e8f0",
          "margin-top": "10px",
          "margin-bottom": "10px",
        },
      },
      [
        _c("legend", [_vm._v("Communication Preferences")]),
        _vm._l(_setup.communicationDto, function (communication, index) {
          return [
            _c(
              _setup.BRow,
              [
                _c(
                  _setup.BCol,
                  [
                    _c(
                      _setup.BFormGroup,
                      [
                        _c(_setup.BFormSelect, {
                          attrs: {
                            options: _setup.languageList,
                            label: "Language",
                          },
                          model: {
                            value: communication.language,
                            callback: function ($$v) {
                              _vm.$set(communication, "language", $$v)
                            },
                            expression: "communication.language",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  _setup.BCol,
                  { attrs: { "align-self": "center" } },
                  [
                    _c(
                      _setup.BFormGroup,
                      [
                        _c(
                          _setup.BFormCheckbox,
                          {
                            attrs: { name: "prefered" },
                            model: {
                              value: communication.prefered,
                              callback: function ($$v) {
                                _vm.$set(communication, "prefered", $$v)
                              },
                              expression: "communication.prefered",
                            },
                          },
                          [_vm._v("Prefered")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  _setup.BCol,
                  { staticClass: "mr-2 mt-1d", attrs: { cols: "1" } },
                  [
                    _c("feather-icon", {
                      staticClass: "text-danger cursor-pointer",
                      attrs: { icon: "XIcon", size: "20" },
                      on: {
                        click: function ($event) {
                          return _setup.communicationDto.splice(index, 1)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        }),
        _c(
          "div",
          { staticClass: "d-flex justify-content-start" },
          [
            _c(
              _setup.BButton,
              {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple.400",
                    value: "rgba(40, 199, 111, 0.15)",
                    expression: "'rgba(40, 199, 111, 0.15)'",
                    modifiers: { 400: true },
                  },
                ],
                staticClass: "text-left align-left",
                attrs: { variant: "flat-primary", size: "sm" },
                on: {
                  click: function ($event) {
                    return _setup.communicationDto.push({
                      language: "",
                      prefered: false,
                    })
                  },
                },
              },
              [_vm._v("Add ")]
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }