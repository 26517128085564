var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { staticStyle: { height: "100%" } },
    [
      _c("b-card-header", [_c("h4", [_vm._v("Add Fee Schedule Manually")])]),
      _c("b-card-body", [
        _c("h5", [
          _vm._v(
            " How would you like to start building your manual fee schedule?"
          ),
        ]),
      ]),
      _c(
        "b-card-body",
        [
          _c(
            "b-row",
            { staticClass: "p-1 border-top border-bottom" },
            [
              _c(
                "b-col",
                [
                  _c("b-card-text", [
                    _vm._v(
                      " Clone an existing Fee Schedule to act as a starting point "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "fee-schedules-manual-clone",
                          })
                        },
                      },
                    },
                    [_vm._v(" Start ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-1 border-bottom" },
            [
              _c(
                "b-col",
                [
                  _c("b-card-text", [
                    _vm._v(
                      " Import a Fee Schedule to act as a starting point "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-button", { attrs: { variant: "primary" } }, [
                    _vm._v(" Start "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-1 border-bottom" },
            [
              _c(
                "b-col",
                [
                  _c("b-card-text", [
                    _vm._v(" Build the Fee Schedule completely from scratch "),
                  ]),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-button", { attrs: { variant: "primary" } }, [
                    _vm._v(" Start "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-button", { attrs: { variant: "outline-primary" } }, [
                _vm._v(" Cancel "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }