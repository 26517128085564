var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-skeleton-wrapper",
    {
      attrs: { loading: _vm._formLoading },
      scopedSlots: _vm._u([
        {
          key: "loading",
          fn: function () {
            return [
              _c(
                "b-card",
                [
                  _c("b-skeleton", { attrs: { width: "85%" } }),
                  _c("b-skeleton", { attrs: { width: "55%" } }),
                  _c("b-skeleton", { attrs: { width: "70%" } }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-card",
            _vm._b({}, "b-card", _vm.$attrs, false),
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showHeader,
                          expression: "showHeader",
                        },
                      ],
                      staticClass: "text-left",
                      attrs: { cols: "6" },
                    },
                    [_c("h4", [_c("b", [_vm._t("header")], 2)])]
                  ),
                  _c("b-col", { attrs: { cols: _vm.showHeader ? 6 : 12 } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex float-right text-right mr-3" },
                      [
                        _vm._t("status-right"),
                        _vm.showStatus
                          ? _c(
                              "span",
                              { staticClass: "d-flex float-right text-right" },
                              [
                                _vm._v(" Status "),
                                _c("b-form-checkbox", {
                                  staticClass: "ml-1 custom-control-light",
                                  attrs: {
                                    value: "Active",
                                    "unchecked-value": "Inactive",
                                    name: "check-button",
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.formDataCopy.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formDataCopy, "status", $$v)
                                    },
                                    expression: "formDataCopy.status",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
              _vm._t("default"),
              _vm._t("body"),
              _c(
                "b-card-footer",
                { attrs: { "footer-border-variant": "white" } },
                [
                  _c(
                    "b-form",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "5" } },
                            [
                              _c(
                                "can",
                                {
                                  attrs: {
                                    a: _vm.$route.meta.resource,
                                    I: "Delete",
                                  },
                                },
                                [
                                  _vm.showDelete
                                    ? [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: { variant: "transparent" },
                                            on: { click: _vm.onDelete },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-50 danger-text",
                                              attrs: { icon: "TrashIcon" },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "danger-text align-middle",
                                              },
                                              [_vm._v("Delete")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { cols: "7" } },
                            [
                              _c(
                                "can",
                                {
                                  attrs: {
                                    a: _vm.$route.meta.resource,
                                    I: _vm.formDataCopy.id
                                      ? "Update"
                                      : "Create",
                                  },
                                },
                                [
                                  _c("label", [_vm._v("No changes made")]),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { 400: true },
                                        },
                                      ],
                                      staticClass: "ml-1 mr-1",
                                      attrs: { variant: "outline-primary" },
                                      on: { click: _vm.cancel },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { 400: true },
                                        },
                                      ],
                                      attrs: {
                                        variant: "primary",
                                        type: "submit",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.validationForm.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("b-spinner", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.isLoading,
                                            expression: "isLoading",
                                          },
                                        ],
                                        attrs: { small: "" },
                                      }),
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "CheckIcon" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "align-middle" },
                                        [_vm._v("Save")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }