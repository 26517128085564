<template>
  <div>
    <b-form-group :label="label">
      <validation-provider
        v-slot="{ errors }"
        :name="label"
        :rules="!disabled && required ? 'required' : ''"
      >
        <b-form-input
          :type="type"
          :name="label"
          v-model="inputData"
          :placeholder="placeholder"
          :disabled="disabled"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import { BFormInput, BFormGroup } from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
  },
})
export default class InputComponent extends Vue {
  @ModelSync("value", "input")
  inputField!: Object | null;

  @Prop()
  label!: String;

  @Prop()
  type!: String;

  @Prop()
  placeholder!: String;

  @Prop()
  disabled!: Boolean;

  @Prop({ default: false, type: Boolean })
  required!: Boolean;

  get inputData() {
    return this.inputField ? this.inputField : "";
  }

  set inputData(value) {
    if (value !== "") {
      console.log(value);
      this.inputField = value;
    }
  }
}
</script>

<style lang="scss" scoped></style>