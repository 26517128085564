var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.practiceContactsRepo,
          "load-params": { PracticeId: _vm.$route.params.id },
          isDataFromModel: false,
          sidebarData: _vm.contact,
          fields: _vm.fields,
          isAddEditSidbar: true,
          sidebarOpen: _vm.sidebar,
          copyData: _vm.contactCopy,
          "do-save": true,
          validate: true,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.contact = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.contact = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
          "update:copyData": function ($event) {
            _vm.contactCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.contactCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(nameurl)",
            fn: function (data) {
              return [
                _c("div", [
                  _vm._v(" " + _vm._s(data.item.name) + " "),
                  _c("br"),
                  _c(
                    "a",
                    { attrs: { href: data.item.url, target: "_blank" } },
                    [
                      _c("u", [
                        _vm._v(_vm._s(_vm.shortenedURL(data.item.url))),
                      ]),
                      _vm._v("   "),
                      _c("feather-icon", {
                        attrs: { icon: "ExternalLinkIcon" },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  data.item.comments.length > 0
                    ? _c(
                        "div",
                        [
                          _c("br"),
                          _vm._l(
                            data.item.comments,
                            function (comment, $index) {
                              return _c("b-col", { key: $index }, [
                                _c("b", [_vm._v("Comment:")]),
                                _vm._v(" " + _vm._s(comment.question) + " "),
                                _c("br"),
                                _vm._v(" " + _vm._s(comment.answer) + " "),
                                _c("br"),
                                _c("br"),
                              ])
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "cell(userpassword)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  [
                    _vm._v(" " + _vm._s(data.item.user) + " "),
                    _c("feather-icon", {
                      staticClass: "text-primary",
                      attrs: { icon: "CopyIcon" },
                      on: {
                        click: function ($event) {
                          return _vm.copyText(data.item.user)
                        },
                      },
                    }),
                    _c("br"),
                    _vm._v(" ● ● ● ● ● ● ● ● "),
                    _c("feather-icon", {
                      staticClass: "text-primary",
                      attrs: { icon: "CopyIcon" },
                      on: {
                        click: function ($event) {
                          return _vm.copyText(data.item.password)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1 pb-3" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.contactCopy.id
                              ? _c("h4", [_vm._v("Edit Login details")])
                              : _c("h4", [_vm._v("Add Login details")]),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { label: "Insurance" },
                                      },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Insurance",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy
                                                          .insurance,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "insurance",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.insurance",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Name" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Name",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.name",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "URL" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "URL",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy.URL,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "URL",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.URL",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Login Username/Email",
                                        },
                                      },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Login Username/Email",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy.user,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "user",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.user",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Password" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Password",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      type: "password",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy
                                                          .password,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "password",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.password",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Comment" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Comment",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy.comment,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "comment",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.comment",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }