var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "misc-wrapper" }, [
    _c("div", { staticClass: "misc-inner p-2 p-sm-3" }, [
      _c(
        "div",
        { staticClass: "w-100 text-center" },
        [
          _c("h2", { staticClass: "mb-1" }, [
            _vm._v(" You are not authorized! 🔐 "),
          ]),
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              " You don’t have permission to access this page. Go Home!! "
            ),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mb-1 btn-sm-block",
              attrs: { variant: "primary", to: _vm.loginRoute() },
            },
            [_vm._v("Back to Home")]
          ),
          _c("b-img", {
            attrs: { fluid: "", src: _vm.imgUrl, alt: "Not authorized page" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }