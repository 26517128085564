var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c("form-component", {
            attrs: {
              copyData: _vm.providerCopy,
              showDelete: _vm.provider.id,
              showHeader: true,
              showStatus: _vm.provider.id,
            },
            on: {
              "update:copyData": function ($event) {
                _vm.providerCopy = $event
              },
              "update:copy-data": function ($event) {
                _vm.providerCopy = $event
              },
              save: _vm.validationForm,
              "on-delete": function ($event) {
                _vm.modalShow = true
              },
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.provider.id
                            ? _vm.provider.name
                            : "Add New Provider"
                        ) +
                        " "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "b-form",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "4", sm: "6" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "First Name",
                                              "label-for": "firstName",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "First Name",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "firstName",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.providerCopy
                                                              .firstName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.providerCopy,
                                                              "firstName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "providerCopy.firstName",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Last Name",
                                              "label-for": "lastName",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Last Name",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "lastName",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.providerCopy
                                                              .lastName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.providerCopy,
                                                              "lastName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "providerCopy.lastName",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "NPI Number",
                                              "label-for": "npiNumber",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "NPI Number",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "npiNumber",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.providerCopy
                                                              .npiNumber,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.providerCopy,
                                                              "npiNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "providerCopy.npiNumber",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Short Code",
                                              "label-for": "shortCode",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Short Code",
                                                rules: "max:4",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uppercase",
                                                            rawName:
                                                              "v-uppercase",
                                                          },
                                                        ],
                                                        attrs: {
                                                          id: "shortCode",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.providerCopy
                                                              .code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.providerCopy,
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "providerCopy.code",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "4", sm: "6" } },
                              [
                                _c("email-phone-fax", {
                                  attrs: {
                                    emails: _vm.providerCopy.emails,
                                    phones: _vm.providerCopy.phones,
                                  },
                                  on: {
                                    "update:emails": function ($event) {
                                      return _vm.$set(
                                        _vm.providerCopy,
                                        "emails",
                                        $event
                                      )
                                    },
                                    "update:phones": function ($event) {
                                      return _vm.$set(
                                        _vm.providerCopy,
                                        "phones",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.provider,
              callback: function ($$v) {
                _vm.provider = $$v
              },
              expression: "provider",
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", "hide-header": "" },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("div", { staticClass: "d-block text-center mt-2" }, [
            _c("p", [
              _c("b", [
                _vm._v(
                  'Are you sure you want to delete "' +
                    _vm._s(_vm.provider.name) +
                    '" provider?'
                ),
              ]),
            ]),
          ]),
          _c(
            "b-row",
            { staticClass: "mt-1 mb-2" },
            [
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      staticClass: "mr-1",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          _vm.modalShow = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      attrs: { variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteRow()
                        },
                      },
                    },
                    [_vm._v(" Yes, Remove ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }