var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.diagnosisRepo,
          "is-data-from-model": false,
          "sidebar-data": _vm.selectedDiag,
          "copy-data": _vm.selectedDiagCopy,
          "sidebar-open": _vm.sidebar,
          fields: _vm.fields,
          validate: true,
          "is-add-edit-sidbar": true,
          "do-save": true,
          "get-by-id-on-edit-filter": () => false,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedDiag = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedDiag = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedDiagCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedDiagCopy = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
          "on-copied": _vm.onCopied,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(tags)",
            fn: function (row) {
              return [_vm._v(" " + _vm._s(row.item.tagCodes.join(", ")) + " ")]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.selectedDiag.id
                              ? _c("h4", [_vm._v("Edit Diagnosis Code")])
                              : _c("h4", [_vm._v("Add Diagnosis Code")]),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Diagnosis Code" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Diagnosis Code",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "upper",
                                                rawName: "v-upper",
                                              },
                                            ],
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.selectedDiagCopy.code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedDiagCopy,
                                                  "code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedDiagCopy.code",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Description" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Description",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              placeholder: "",
                                              rows: "3",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.selectedDiagCopy
                                                  .description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedDiagCopy,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedDiagCopy.description",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Tags" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    options: Object.keys(
                                      _vm.diagnosisTagCode
                                    ).map((key) => ({
                                      label: key,
                                      value: _vm.diagnosisTagCode[key],
                                    })),
                                    multiple: "",
                                    "close-on-select": false,
                                    "clear-on-select": false,
                                    "preserve-search": true,
                                    reduce: (x) => x.value,
                                    placeholder: "Select Tags",
                                    label: "label",
                                    "max-height": 200,
                                  },
                                  model: {
                                    value: _vm.selectedDiagCopy.tagCodes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedDiagCopy,
                                        "tagCodes",
                                        $$v
                                      )
                                    },
                                    expression: "selectedDiagCopy.tagCodes",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }