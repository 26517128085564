var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "2", sm: "4", xs: "4" } },
                [
                  _c("feather-icon", {
                    staticClass: "floating-icon",
                    attrs: { icon: "Edit2Icon", size: "20" },
                    on: {
                      click: function ($event) {
                        _vm.modalShow = true
                        _vm.uploadedImage = null
                      },
                    },
                  }),
                  _c("b-img", {
                    attrs: {
                      height: "150",
                      width: "150",
                      src: _vm.companyLogo,
                      alt: "company logo",
                    },
                  }),
                ],
                1
              ),
              _vm.formData.id
                ? [
                    _c("b-col", { attrs: { md: "10", sm: "8", xs: "8" } }, [
                      _c(
                        "h3",
                        { staticClass: "pb-0 mb-0 d-flex align-items-center" },
                        [
                          _vm._v(" " + _vm._s(_vm.formData.name) + "   "),
                          _c(
                            "b-badge",
                            {
                              staticClass: "smaller-text-b",
                              attrs: { pill: "", variant: _vm.status[1][1] },
                            },
                            [_vm._v(" " + _vm._s(_vm.status[0][1]) + " ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" ID: " + _vm._s(_vm.formData.id) + " "),
                      _c("p", { staticClass: "mt-1" }),
                      _vm.formData.address
                        ? _c("div", [
                            _vm._v(" " + _vm._s(_vm.formData.address.address1)),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.formData.address.address2)),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.formData.address.city)),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.formData.address.state)),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.formData.address.country)),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _vm.formData.email
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formData.email.emailAddress) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.formData.phone
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.formData.phone.number) + " "
                            ),
                          ])
                        : _vm._e(),
                      _c("p"),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "tabs-component",
        {
          attrs: {
            "base-route": {
              name: "practices-details",
              params: { id: _vm.$route.params.id },
            },
            pills: "",
          },
        },
        [
          _c(
            "Can",
            { attrs: { I: "View", a: "Practices" } },
            [
              _c(
                "b-tab",
                {
                  staticClass: "tab-design",
                  attrs: { active: "", title: "Practice" },
                  on: { click: function ($event) {} },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Practice"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("practices-tab-cc", {
                    on: { "save-practice": _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Contact List" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Contact List"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("contact-list-tab-cc", {
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Locations" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Locations"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("LocationTabCC")],
                1
              ),
            ],
            1
          ),
          _c(
            "Can",
            { attrs: { I: "View", a: "Corporations" } },
            [
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Corporations" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function (Corporations) {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Corporations"),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("CorporationsTabCC", {
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Can",
            { attrs: { I: "View", a: "Providers" } },
            [
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Provider" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Provider"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("ProviderTabCC")],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Profiles" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Profiles"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("profiles-component")],
                1
              ),
            ],
            1
          ),
          _c(
            "Can",
            { attrs: { I: "View", a: "Profiles" } },
            [
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Credentialing" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Credentialing"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("profiles-component", {
                    attrs: { "is-credentialing": true },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Can",
            { attrs: { I: "View", a: "WebLinks" } },
            [
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Web Links" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Web Links"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("weblinks-c-c", {
                    attrs: {
                      "filter-by-field": "practiceId",
                      "web-links-to-form-field": "practiceId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Can",
            { attrs: { I: "View", a: "StorageObjects" } },
            [
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Attachments" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Attachments"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("AttachmentsTabCC")],
                1
              ),
            ],
            1
          ),
          _c(
            "can",
            { attrs: { I: "View", a: "ScrubberPracticeSettings" } },
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    lazy: "",
                    title: "Settings",
                    to: {
                      name: "practices-details-settings",
                      params: { id: _vm.$route.params.id },
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Settings"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("SettingsTabCC")],
                1
              ),
            ],
            1
          ),
          _c(
            "Can",
            { attrs: { I: "View", a: "Notes" } },
            [
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", title: "Notes" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("feather-icon", {
                            staticClass: "mr-0 mr-sm-50",
                            attrs: { icon: "ClockIcon", size: "16" },
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Notes"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("notes-tab", {
                    attrs: { filter: { practiceId: _vm.$route.params.id } },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", title: "Update Image", size: "xs" },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "upload-image text-center" },
            [
              _c("b-img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.uploadedImage || _vm.companyLogo,
                    expression: "uploadedImage || companyLogo",
                  },
                ],
                staticClass: "upload-image-image",
                attrs: {
                  src: _vm.uploadedImage ? _vm.uploadedImage : _vm.companyLogo,
                  alt: "use image",
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.uploadedImage && !_vm.companyLogo,
                      expression: "!uploadedImage && !companyLogo",
                    },
                  ],
                  staticClass: "upload-image-inner",
                },
                [
                  _c("input", {
                    ref: "fileTag",
                    attrs: {
                      id: "fileid",
                      accept: "image/*",
                      type: "file",
                      hidden: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange(_vm.uploadedImage, $event)
                      },
                    },
                  }),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      attrs: { variant: "primary" },
                      on: { click: _vm.uploadInitiate },
                    },
                    [_vm._v(" Click To Upload ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1 mb-2 text-center" },
            [
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.uploadedImage && !_vm.companyLogo,
                      expression: "!uploadedImage && !companyLogo",
                    },
                  ],
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      attrs: { block: "", variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          _vm.modalShow = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.uploadedImage && _vm.companyLogo,
                      expression: "!uploadedImage && companyLogo",
                    },
                  ],
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      attrs: { block: "", variant: "outline-primary" },
                      on: { click: _vm.uploadInitiate },
                    },
                    [_vm._v(" Change ")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.uploadedImage,
                      expression: "uploadedImage",
                    },
                  ],
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      attrs: { block: "", variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          _vm.uploadedImage = false
                        },
                      },
                    },
                    [_vm._v(" Remove ")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pl-0", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      attrs: {
                        block: "",
                        variant: _vm.uploadedImage ? "primary" : "light",
                        disabled: !_vm.uploadedImage,
                      },
                      on: { click: _vm.saveImage },
                    },
                    [
                      _c("feather-icon", { attrs: { icon: "CheckIcon" } }),
                      _vm._v(" Done "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }