import axios from "@/libs/axios";
import { ProcCodesTypes, ProcDetailTypes, ProcTypesTypes } from "@/types/procedureTypes";
import { AxiosResponse } from "axios";
import Vue from "vue";
import { UserInfo } from "./responseTypes";

export  class HttpRepository {

    static user = {
		login(Code: string): Promise<AxiosResponse<ModelResponse<UserInfo>>> {
			return axios.get<ModelResponse<UserInfo>>('/OAtuh/Login',{params:{
                code:Code,
                HostUrl:new URL(document.URL).origin
            }});
		},
		logout(){
			return axios.get<ModelResponse<boolean>>('/OAtuh/Logout');
		}

	};

	static ProsedureTypes={
		getProcedureTypes(BaseFilters){
			return axios.get<ListResponseModel<ProcDetailTypes>>('/ProcedureTypes/GetList',{params:BaseFilters})
		},
		addProcedureTypes(formData: ProcDetailTypes){
			return axios.post<ModelResponse<ProcDetailTypes>>('/ProcedureTypes/AddType',formData)
		},
		deleteProcedureTypes(Id: string){
			return axios.delete<ResponseModel>('/ProcedureTypes/DeleteType',{params:{id:Id}})
		}
	}

	static ProcedureCodes={
		addProcedureCodes(formData: ProcCodesTypes){
			return axios.post<ModelResponse<ProcCodesTypes>>('/ProcedureCodes/AddCode',formData)
		},
		getProcedureCodes(id: string){
			return axios.get<ModelResponse<ProcCodesTypes>>('/ProcedureCodes/GetCodes',{params:{
				id:id
			}})
		}
	}
}
