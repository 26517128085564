var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            { staticClass: "pl-2 pt-2", attrs: { pills: "" } },
            [
              _c("b-tab", {
                attrs: { active: "" },
                on: {
                  click: function ($event) {
                    _vm.tabVal = 1
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Location info")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("b-tab", {
                on: {
                  click: function ($event) {
                    _vm.tabVal = 2
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Contacts in location")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c("AddLocationCC", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabVal == 1,
                expression: "tabVal == 1",
              },
            ],
            attrs: { mode: "edit" },
            model: {
              value: _vm.practiceLocation,
              callback: function ($$v) {
                _vm.practiceLocation = $$v
              },
              expression: "practiceLocation",
            },
          }),
          _c("ContactListCC", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabVal == 2,
                expression: "tabVal == 2",
              },
            ],
            model: {
              value: _vm.practiceLocation,
              callback: function ($$v) {
                _vm.practiceLocation = $$v
              },
              expression: "practiceLocation",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }