var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        ref: "table",
        attrs: {
          "items-repo": _vm.practiceAttachmentRepo,
          "load-params": { practiceId: +_vm.$route.params.id },
          "is-data-from-model": false,
          "sidebar-data": _vm.practiceAtt,
          "copy-data": _vm.practiceAttCopy,
          "sidebar-open": _vm.sidebar,
          fields: _vm.fields,
          validate: true,
          "is-add-edit-sidbar": true,
          "do-save": false,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.practiceAtt = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.practiceAtt = $event
          },
          "update:copyData": function ($event) {
            _vm.practiceAttCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.practiceAttCopy = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
          save: _vm.onSave,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(dateCreated)",
            fn: function (data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      new Date(data.item.dateCreated).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "cell(Edit)",
            fn: function (data) {
              return [
                _c("b-icon-download", {
                  staticClass: "mx-1",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadFile(data.id)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "cell(delete)",
            fn: function (data) {
              return [
                _c("b-icon-trash", {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.table.deleteRow(data.id)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("h4", [_vm._v("Upload Attachment")]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.uploadedFiles,
                                    expression: "!uploadedFiles",
                                  },
                                ],
                                staticClass: "upload-image text-center mt-2",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "upload-image-inner" },
                                  [
                                    _c("input", {
                                      ref: "fileTag",
                                      attrs: {
                                        id: "fileid",
                                        type: "file",
                                        hidden: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onFileChange(
                                            _vm.uploadedFiles,
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: { variant: "primary" },
                                        on: { click: _vm.uploadInitiate },
                                      },
                                      [_vm._v(" Click To Upload ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.uploadedFiles,
                                    expression: "uploadedFiles",
                                  },
                                ],
                                staticClass: "d-flex",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mt-2 text-wrap text-truncate line-gap float-left",
                                  },
                                  _vm._l(
                                    _vm.uploadedFiles,
                                    function (file, $index) {
                                      return _c("div", { key: $index }, [
                                        _c("b", [_vm._v("File name:")]),
                                        _vm._v(" " + _vm._s(file.name) + " "),
                                        _c("br"),
                                        _c("b", [_vm._v("Uploaded by:")]),
                                        _vm._v(
                                          " " + _vm._s("Diana Green") + " "
                                        ),
                                        _c("br"),
                                        _c("b", [_vm._v("Date:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              new Date(
                                                file.lastModified
                                              ).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                              })
                                            ) +
                                            " " +
                                            _vm._s(
                                              new Date(
                                                file.lastModified
                                              ).toLocaleTimeString("en-US")
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.uploadedFiles,
                                        expression: "uploadedFiles",
                                      },
                                    ],
                                    staticClass: "float-right line-gap mt-2",
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer text-danger",
                                      attrs: { icon: "XCircleIcon" },
                                      on: {
                                        click: function ($event) {
                                          _vm.uploadedFiles = null
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: "Provider" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Provider",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.providerRepo,
                                              filter: {
                                                practiceId:
                                                  +_vm.$route.params.id,
                                              },
                                              label: "name",
                                            },
                                            model: {
                                              value:
                                                _vm.practiceAttCopy.providerId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.practiceAttCopy,
                                                  "providerId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "practiceAttCopy.providerId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: "Comment" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Comment", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              placeholder: "",
                                              rows: "3",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.practiceAttCopy.comment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.practiceAttCopy,
                                                  "comment",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "practiceAttCopy.comment",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }