var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.practiceContactsRepo,
          "load-params": { PracticeId: _vm.$route.params.id },
          isDataFromModel: false,
          sidebarData: _vm.contact,
          fields: _vm.fields,
          isAddEditSidbar: true,
          sidebarOpen: _vm.sidebar,
          copyData: _vm.contactCopy,
          "do-save": true,
          validate: true,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.contact = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.contact = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
          "update:copyData": function ($event) {
            _vm.contactCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.contactCopy = $event
          },
          "on-copied": function ($event) {
            _vm.contactCopy.practiceId = +_vm.$route.params.id
          },
          "on-open": _vm.onOpenSidebar,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(lastName)",
            fn: function (data) {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "pr-0", attrs: { cols: "3" } },
                      [
                        _c("b-avatar", {
                          staticClass: "float-left",
                          attrs: {
                            variant: _vm.getColor(),
                            text: _vm.getName(
                              data.item.firstName + " " + data.item.lastName
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("b-col", { staticClass: "pl-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            data.item.firstName + " " + data.item.lastName
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("span", { staticClass: "grey-text smaller-text" }, [
                        _vm._v(_vm._s(data.item.role)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(phones)",
            fn: function (data) {
              return _vm._l(data.item.phones, function (phone, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    phone.type == "Work"
                      ? _c("feather-icon", {
                          staticClass: "grey-text",
                          attrs: { icon: "BriefcaseIcon" },
                        })
                      : _vm._e(),
                    phone.type == "Personal"
                      ? _c("feather-icon", {
                          staticClass: "grey-text",
                          attrs: { icon: "SmartphoneIcon" },
                        })
                      : _vm._e(),
                    phone.type == "Home"
                      ? _c("feather-icon", {
                          staticClass: "grey-text",
                          attrs: { icon: "HomeIcon" },
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(phone.number) + " "),
                  ],
                  1
                )
              })
            },
          },
          {
            key: "cell(emails)",
            fn: function (data) {
              return _vm._l(data.item.emails, function (email, index) {
                return _c("div", { key: index }, [
                  _vm._v(" " + _vm._s(email.emailAddress) + " "),
                  _c("br"),
                ])
              })
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1 pb-3" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.contactCopy.id
                              ? _c("h4", [_vm._v("Edit Contact")])
                              : _c("h4", [_vm._v("Add Contact")]),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { label: "First Name" },
                                      },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "First Name",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy
                                                          .firstName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.firstName",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { label: "Last Name" },
                                      },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Last Name",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contactCopy
                                                          .lastName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contactCopy,
                                                          "lastName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contactCopy.lastName",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "Can",
                              { attrs: { I: "View", a: "ListOptions" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Role" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: { name: "Role", rules: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("multi-select", {
                                                ref: "roleTypes",
                                                attrs: {
                                                  optionsRepo:
                                                    _vm.contactRoleTypes,
                                                  sourceIdField:
                                                    "contactRoleTypeId",
                                                  label: "roleName",
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.contactCopy
                                                      .contactRoles,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contactCopy,
                                                      "contactRoles",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contactCopy.contactRoles",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Location Name" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Location Name", rules: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("multi-select", {
                                            ref: "locations",
                                            attrs: {
                                              optionsRepo: _vm.locations,
                                              optionsFilter: {
                                                PracticeId:
                                                  +_vm.$route.params.id,
                                              },
                                              listField: "contactLocations",
                                              sourceIdField: "locationId",
                                              label: "name",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.contactCopy
                                                  .contactLocations,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contactCopy,
                                                  "contactLocations",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contactCopy.contactLocations",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c("br"),
                            _c("email-phone-fax", {
                              attrs: {
                                emails: _vm.contactCopy.emails,
                                phones: _vm.contactCopy.phones,
                              },
                              on: {
                                "update:emails": function ($event) {
                                  return _vm.$set(
                                    _vm.contactCopy,
                                    "emails",
                                    $event
                                  )
                                },
                                "update:phones": function ($event) {
                                  return _vm.$set(
                                    _vm.contactCopy,
                                    "phones",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }