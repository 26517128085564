var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.TableComponent, {
    attrs: {
      "items-repo": _setup.visitRepo,
      "load-params": { patientId: _setup.patientId },
      "is-data-from-model": false,
      fields: _setup.fields,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }