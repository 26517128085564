var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "table-component",
        {
          ref: "tableComponent",
          attrs: {
            "items-repo": _vm.apiInsuranceMapRepo,
            "load-func-name": "loadGroupedList",
            fields: [
              {
                label: "Insurance company Name",
                key: "insuranceName",
                sortable: true,
              },
              {
                label: "action",
                key: "action",
              },
            ],
            "load-params": { InsuranceId: _vm.$route.params.id },
            "is-data-from-model": false,
            "is-add-edit-sidbar": true,
            "copy-data": _vm.selectedInsuranceCopy,
            "sidebar-data": _vm.selectedInsurance,
            "get-by-id-on-edit-filter": () => false,
            "sidebar-open": _vm.sidebar,
          },
          on: {
            "update:copyData": function ($event) {
              _vm.selectedInsuranceCopy = $event
            },
            "update:copy-data": function ($event) {
              _vm.selectedInsuranceCopy = $event
            },
            "update:sidebarData": function ($event) {
              _vm.selectedInsurance = $event
            },
            "update:sidebar-data": function ($event) {
              _vm.selectedInsurance = $event
            },
            "update:sidebarOpen": function ($event) {
              _vm.sidebar = $event
            },
            "update:sidebar-open": function ($event) {
              _vm.sidebar = $event
            },
            save: _vm.submitMapInsurances,
            "on-open": _vm.onOpen,
            onCopied: _vm.onCopied,
          },
          scopedSlots: _vm._u([
            {
              key: "search",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "m-1" }, [
                        _c("h4", [
                          _vm._v(
                            "List of insurance companies that have been merged under this insurance company name"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          [
            _vm.selectedInsurance.status == undefined
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "w-auto p-1" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "my-1" }, [
                            _c("h4", [_vm._v("Insurance company's to Map")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "my-1" }, [
                            _vm._v(
                              " Select Insurance Companies you would like to add to the map list. "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "my-1" }, [
                            _vm._v(
                              " The selected Insurance Companies will be automatically marked as Inactive and will be mapd under this Insurance Company name "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Insurance Companie(s)" } },
                                [
                                  _c("infinit-select", {
                                    attrs: {
                                      repo: _vm.insuranceRepo,
                                      label: "name",
                                      multiple: true,
                                    },
                                    model: {
                                      value: _vm.insurancesToMap,
                                      callback: function ($$v) {
                                        _vm.insurancesToMap = $$v
                                      },
                                      expression: "insurancesToMap",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "w-auto p-1" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "my-1" }, [
                            _c("h4", [
                              _vm._v(
                                "Move " +
                                  _vm._s(_vm.selectedInsurance.insuranceName)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "my-1" }, [
                            _vm._v(
                              " Move " +
                                _vm._s(_vm.selectedInsurance.insuranceName) +
                                " under a different Insurance Company. "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Prectices:" } },
                                [
                                  _c("DxList", {
                                    attrs: {
                                      "data-source": _vm.dataSource,
                                      height: 400,
                                      "show-selection-controls": true,
                                      "selection-mode": _vm.selectionMode,
                                      "select-all-mode": _vm.selectAllMode,
                                      "select-by-click": _vm.selectByClick,
                                      displayExpr: "name",
                                      "selected-item-keys":
                                        _vm.selectedItemKeys,
                                    },
                                    on: {
                                      "update:selectedItemKeys": function (
                                        $event
                                      ) {
                                        _vm.selectedItemKeys = $event
                                      },
                                      "update:selected-item-keys": function (
                                        $event
                                      ) {
                                        _vm.selectedItemKeys = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Insurance Companie(s)" } },
                                [
                                  _c("infinit-select", {
                                    attrs: {
                                      repo: _vm.insuranceRepo,
                                      label: "name",
                                    },
                                    model: {
                                      value: _vm.insuranceToBeMoved,
                                      callback: function ($$v) {
                                        _vm.insuranceToBeMoved = $$v
                                      },
                                      expression: "insuranceToBeMoved",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }