<template>
  <CorporationsMoreTabCC />
</template>

<script lang="ts">
import CorporationsMoreTabCC from '/src/class-components/practices/practices-details/corporations-more/CorporationsMoreTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    CorporationsMoreTabCC
  }
})
export default class CorporationsMoreTab extends Vue {}
</script>
