var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        _setup.BCard,
        [
          _c(_setup.BTable, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.data.length > 0,
                expression: "data.length >0",
              },
            ],
            attrs: {
              striped: "",
              hover: "",
              items: _setup.data,
              fields: _setup.fields,
            },
          }),
        ],
        1
      ),
      _c(_setup.ClaimsList, { attrs: { patientId: _vm.$route.params.id } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }