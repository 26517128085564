<template>
  <LocationMoreTabCC />
</template>

<script lang="ts">
import LocationMoreTabCC from '/src/class-components/practices/practices-details/location-more/LocationMoreTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    LocationMoreTabCC
  }
})
export default class LocationMoreTab extends Vue {}
</script>
