var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        ref: "table",
        attrs: {
          "items-repo": _vm.scrubRepo,
          "is-data-from-model": false,
          fields: _vm.fields,
          "load-params": {
            practiceId: _vm.selectedPractice,
            showBilled: _vm.showBilled,
            showApproved: _vm.showApproved,
            PatientResponsibility: _vm.showPatient,
          },
        },
        on: {
          "row-clicked": function ($event) {
            return _vm.openDialog($event.lastScrubResult)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "search-right",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticStyle: { width: "30%" } },
                  [
                    _c("infinit-select", {
                      attrs: {
                        repo: _vm.practiceRepo,
                        label: "name",
                        filter: { WithScrubbingTurnedOn: true },
                        placeholder: "select practice",
                      },
                      model: {
                        value: _vm.selectedPractice,
                        callback: function ($$v) {
                          _vm.selectedPractice = $$v
                        },
                        expression: "selectedPractice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "mx-2",
                    model: {
                      value: _vm.showBilled,
                      callback: function ($$v) {
                        _vm.showBilled = $$v
                      },
                      expression: "showBilled",
                    },
                  },
                  [_vm._v("Include billed")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "Add-left",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticStyle: { position: "absolute", left: "50%" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "mx-2",
                        model: {
                          value: _vm.showApproved,
                          callback: function ($$v) {
                            _vm.showApproved = $$v
                          },
                          expression: "showApproved",
                        },
                      },
                      [_vm._v("Include approved")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { position: "absolute", left: "60%" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "mx-2",
                        model: {
                          value: _vm.showPatient,
                          callback: function ($$v) {
                            _vm.showPatient = $$v
                          },
                          expression: "showPatient",
                        },
                      },
                      [_vm._v("Include patient responsibility")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(result)",
            fn: function (data) {
              return [
                data.item
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "transparent" },
                            on: {
                              click: function ($event) {
                                _vm.openDialog(data.item, data.index)
                                _vm.nextToSelectedId =
                                  _vm.table.rootTable._data.localItems[
                                    data.index + 1
                                  ].id
                              },
                            },
                          },
                          [
                            _c("b-icon-eye", {
                              attrs: { icon: "eye-fill", variant: "blue" },
                            }),
                            _vm.loadingIndex[data.index].loading === true
                              ? _c("b-spinner", {
                                  staticClass: "mx-1",
                                  attrs: {
                                    small: "",
                                    type: "grow",
                                    label: "Loading...",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.selectedScrubb.result
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "modal-1",
                title: "Claim number " + _vm.selectedScrubb.visit.claimNumber,
                "ok-title": "Approve Claim",
                size: "xl",
                scrollable: "",
              },
              on: { ok: _vm.ApproveClaim },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function ({ ok, cancel }) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              disabled: _vm.nextToSelectedId == 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.loadNext()
                              },
                            },
                          },
                          [
                            _vm._v("Next "),
                            _vm.loadingNext
                              ? _c("b-spinner", {
                                  staticClass: "mx-1",
                                  attrs: {
                                    small: "",
                                    type: "grow",
                                    label: "Loading...",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v("Approve Claim")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "secondary" },
                            on: {
                              click: function ($event) {
                                return cancel()
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3483807006
              ),
            },
            [
              _c(
                "div",
                [
                  _vm.selectedScrubb.result
                    ? _c(
                        "b-row",
                        {
                          class: _vm.selectedScrubb.status,
                          attrs: { id: "statusBox" },
                        },
                        [
                          _c("b-col", {
                            staticStyle: {
                              flex: "none",
                              width: "5px",
                              "align-self": "center",
                            },
                          }),
                          _c("b-col", { staticStyle: { color: "#1F7580" } }, [
                            _vm._v(
                              " This result is marked as " +
                                _vm._s(_vm.selectedScrubb.status) +
                                " "
                            ),
                          ]),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                id: "toggleStatusButton",
                                variant:
                                  _vm.selectedScrubb.status ===
                                  _vm.ScrubResultStatus.Approved
                                    ? "danger"
                                    : "primary",
                              },
                              on: { click: _vm.toggleStatus },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedScrubb.status ===
                                      _vm.ScrubResultStatus.Approved
                                      ? "Flag"
                                      : "Approve"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("scrubber", {
                    attrs: {
                      "scrub-result": _vm.selectedScrubb.result,
                      "claim-number": _vm.selectedScrubb.visit.claimNumber,
                      "scrub-result-id": _vm.selectedScrubb.id,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "footer",
                          fn: function () {
                            return [_c("span")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2261540193
                    ),
                    model: {
                      value: _vm.selectedScrubb.visitValidated,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectedScrubb, "visitValidated", $$v)
                      },
                      expression: "selectedScrubb.visitValidated",
                    },
                  }),
                  _c(
                    "b-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedScrubb.claimChanges.length,
                          expression: "selectedScrubb.claimChanges.length",
                        },
                      ],
                    },
                    [
                      _c(
                        "b-col",
                        [
                          _c("h5", { staticClass: "mt-2" }, [
                            _vm._v("Changes Waiting For Approval "),
                          ]),
                          _vm._l(
                            _vm.selectedScrubb.claimChanges,
                            function (item, index) {
                              return _c(
                                "b-alert",
                                {
                                  key: index,
                                  staticClass: "mb-2",
                                  attrs: { variant: "primary", show: true },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between p-1",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c("b", [_vm._v("Change:")]),
                                          _vm._v(
                                            " " + _vm._s(item.message) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c("div", [
                                            _c("b", [
                                              _vm._v("Current Status:"),
                                            ]),
                                            _vm._v(
                                              " " + _vm._s(item.status) + " "
                                            ),
                                          ]),
                                          _c("div", [
                                            _c("b", [_vm._v("Change Status:")]),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.status,
                                                    expression: "item.status",
                                                  },
                                                ],
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      item,
                                                      "status",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "Approved",
                                                    },
                                                  },
                                                  [_vm._v("Approved")]
                                                ),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "Deleted" },
                                                  },
                                                  [_vm._v("Rejected")]
                                                ),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value:
                                                        "WaitingForApproval",
                                                    },
                                                  },
                                                  [_vm._v("Pending")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "primary",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submitClaimChange(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Submit")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }