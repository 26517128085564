var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form-component", {
    attrs: { copyData: _vm.generalSettingsCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.generalSettingsCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.generalSettingsCopy = $event
      },
      save: _vm.save,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6", sm: "10" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c("h5", [_vm._v("Global Period Rules")]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Modifier for E&M type" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Modifier for E&M Type",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.modifiersRepo,
                                              label: "code",
                                              includeIds: [
                                                _vm.generalSettingsCopy
                                                  .globalPeriodEnmModifierId,
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .globalPeriodEnmModifierId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "globalPeriodEnmModifierId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.globalPeriodEnmModifierId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Modifier for other types" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Modifier for Other Types",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.modifiersRepo,
                                              label: "code",
                                              includeIds: [
                                                _vm.generalSettingsCopy
                                                  .globalPeriodDefaultModifierId,
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .globalPeriodDefaultModifierId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "globalPeriodDefaultModifierId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.globalPeriodDefaultModifierId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c("h5", [_vm._v("Daily Limit")]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Default daily limit" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Daily Limit",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .dailyLimitDefault,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "dailyLimitDefault",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.dailyLimitDefault",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Default modifier" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Default Modifier",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.modifiersRepo,
                                              label: "code",
                                              includeIds: [
                                                _vm.generalSettingsCopy
                                                  .dailyLimitDefaultModifierId,
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .dailyLimitDefaultModifierId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "dailyLimitDefaultModifierId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.dailyLimitDefaultModifierId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Default line type" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Line Type",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.optionsDailyLimitLineType,
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .dailyLimitDefaultLineType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "dailyLimitDefaultLineType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.dailyLimitDefaultLineType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c("h5", [_vm._v("Bundles")]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Modifier for E&M type" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "E&M Type",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.modifiersRepo,
                                              label: "code",
                                              includeIds: [
                                                _vm.generalSettingsCopy
                                                  .bundleEnmModifierId,
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .bundleEnmModifierId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "bundleEnmModifierId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.bundleEnmModifierId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Modifier for other types" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Other Types",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.modifiersRepo,
                                              label: "code",
                                              includeIds: [
                                                _vm.generalSettingsCopy
                                                  .bundleDefaultModifierId,
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .bundleDefaultModifierId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "bundleDefaultModifierId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.bundleDefaultModifierId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c("h5", [_vm._v("Other settings")]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { label: "Max diagnosis per procedure" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Max Diagnosis",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.generalSettingsCopy
                                                  .procedureMaxDiagnisis,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.generalSettingsCopy,
                                                  "procedureMaxDiagnisis",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "generalSettingsCopy.procedureMaxDiagnisis",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v
      },
      expression: "formData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }