var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          fields: _vm.fields,
          "items-repo": _vm.procCodesRepo,
          "is-data-from-model": false,
          "get-by-id-on-edit-filter": () => false,
          "is-add-edit-sidbar": false,
          "copy-data": _vm.copyProcCode,
          "sidebar-data": _vm.procCode,
          "add-edit-link": {
            name: "proc-codes-update",
            params: { id: _vm.copyProcCode.id },
          },
          "load-params": { procedureTypeId: _vm.$route.params.id },
        },
        on: {
          "update:copyData": function ($event) {
            _vm.copyProcCode = $event
          },
          "update:copy-data": function ($event) {
            _vm.copyProcCode = $event
          },
          "update:sidebarData": function ($event) {
            _vm.procCode = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.procCode = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [_c("span")]
            },
            proxy: true,
          },
          {
            key: "cell(delete)",
            fn: function () {
              return [_c("span")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }