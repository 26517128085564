var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "BContainer",
    { staticClass: "my-2" },
    [
      _c("BRow", [_c("BCol", [_c("h3", [_vm._v("Bulk Import Profiles")])])], 1),
      _vm._t("practiceId", null, {
        state: _vm.state,
        props: _vm.props,
        save: _vm.save,
        v$: _vm.v$,
        validateState: _vm.validateState,
      }),
      _c(
        "BRow",
        [
          _c(
            "BCol",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Locations",
                    state: _vm.validateState("locationIds"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "invalid-feedback",
                      fn: function () {
                        return [
                          _c("strong", [
                            _vm._v("Please select at least one location"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("InfinitSelect", {
                    attrs: {
                      repo: _vm.locationsRepo,
                      multiple: true,
                      filter: { practiceId: _vm.props.practiceId },
                      label: "name",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function ({ name, address }) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(name) +
                                  " " +
                                  _vm._s(
                                    address
                                      ? "(" +
                                          address.address1 +
                                          " " +
                                          address.city +
                                          " " +
                                          address.state +
                                          " " +
                                          address.zip +
                                          ")"
                                      : ""
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.state.locationIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.state, "locationIds", $$v)
                      },
                      expression: "state.locationIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "BCol",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.validateState("providerIds"),
                    label: "Providers",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "invalid-feedback",
                      fn: function () {
                        return [
                          _c("strong", [
                            _vm._v("Please select at least one provider"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("InfinitSelect", {
                    attrs: {
                      repo: _vm.providersRepo,
                      multiple: true,
                      filter: { practiceId: _vm.props.practiceId },
                      label: "name",
                    },
                    model: {
                      value: _vm.state.providerIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.state, "providerIds", $$v)
                      },
                      expression: "state.providerIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "BRow",
        { staticClass: "mt-2" },
        [
          _c(
            "BCol",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Insurances",
                    state: _vm.validateState("insuranceIds"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "invalid-feedback",
                      fn: function () {
                        return [
                          _c("strong", [
                            _vm._v("Please select at least one insurance"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("InfinitSelect", {
                    attrs: {
                      repo: _vm.insurancesRepo,
                      multiple: true,
                      label: "name",
                    },
                    model: {
                      value: _vm.state.insuranceIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.state, "insuranceIds", $$v)
                      },
                      expression: "state.insuranceIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "BCol",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Corporations",
                    state: _vm.validateState("corporationIds"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "invalid-feedback",
                      fn: function () {
                        return [
                          _c("strong", [
                            _vm._v("Please select at least one corporation"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("InfinitSelect", {
                    attrs: {
                      repo: _vm.corporationsRepo,
                      multiple: true,
                      filter: { practiceId: _vm.props.practiceId },
                      label: "name",
                    },
                    model: {
                      value: _vm.state.corporationIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.state, "corporationIds", $$v)
                      },
                      expression: "state.corporationIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type == "credentialing"
        ? [
            _c("Credentialing", {
              attrs: {
                credentialingAssignedUserId:
                  _vm.state.credentialingAssignedUserId,
                credentialingType: _vm.state.credentialingType,
                credentialingDueDate: _vm.state.credentialingDueDate,
                credentialingDetails: _vm.state.credentialingDetails,
                status: _vm.state.status,
              },
              on: {
                "update:credentialingAssignedUserId": function ($event) {
                  return _vm.$set(
                    _vm.state,
                    "credentialingAssignedUserId",
                    $event
                  )
                },
                "update:credentialing-assigned-user-id": function ($event) {
                  return _vm.$set(
                    _vm.state,
                    "credentialingAssignedUserId",
                    $event
                  )
                },
                "update:credentialingType": function ($event) {
                  return _vm.$set(_vm.state, "credentialingType", $event)
                },
                "update:credentialing-type": function ($event) {
                  return _vm.$set(_vm.state, "credentialingType", $event)
                },
                "update:credentialingDueDate": function ($event) {
                  return _vm.$set(_vm.state, "credentialingDueDate", $event)
                },
                "update:credentialing-due-date": function ($event) {
                  return _vm.$set(_vm.state, "credentialingDueDate", $event)
                },
                "update:credentialingDetails": function ($event) {
                  return _vm.$set(_vm.state, "credentialingDetails", $event)
                },
                "update:credentialing-details": function ($event) {
                  return _vm.$set(_vm.state, "credentialingDetails", $event)
                },
                "update:status": function ($event) {
                  return _vm.$set(_vm.state, "status", $event)
                },
              },
            }),
          ]
        : _vm._e(),
      _c(
        "BRow",
        { staticClass: "mt-2", attrs: { "align-h": "end" } },
        [
          _c(
            "BCol",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-button",
                { attrs: { variant: "primary" }, on: { click: _vm.save } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }