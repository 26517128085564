var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-skeleton-wrapper",
        {
          attrs: { loading: _vm.state.formLoading.isLoading },
          scopedSlots: _vm._u([
            {
              key: "loading",
              fn: function () {
                return [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("b-skeleton", {
                                attrs: { size: "90px", type: "avatar" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("b-skeleton", { attrs: { width: "85%" } }),
                              _c("b-skeleton", { attrs: { width: "55%" } }),
                              _c("b-skeleton", { attrs: { width: "70%" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("b-avatar", {
                        attrs: {
                          size: "100",
                          variant: "primary",
                          src: "https://picsum.photos/200/300",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-col", [
                    _c("b", [_c("span", [_vm._v(_vm._s(_vm.form.name))])]),
                    _c("br"),
                    _c("span", [
                      _vm._v("Chart Number: " + _vm._s(_vm.form.chart)),
                    ]),
                    _c("br"),
                    _c("b", [
                      _c("span", [_vm._v(" " + _vm._s(_vm.form.address1))]),
                      _c("br"),
                      _c("span", [_vm._v(" " + _vm._s(_vm.form.address2))]),
                      _vm.form.address2 ? _c("br") : _vm._e(),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.form.city) +
                            ", " +
                            _vm._s(_vm.form.state) +
                            ", " +
                            _vm._s(_vm.form.zip)
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-align": "right",
                            "label-cols": "3",
                            label: "Email",
                            "label-for": "email",
                          },
                        },
                        [
                          _c("b-form-input", {
                            staticStyle: { border: "transparent" },
                            attrs: { id: "email" },
                            model: {
                              value: _vm.form.emailAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "emailAddress", $$v)
                              },
                              expression: "form.emailAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-align": "right",
                            "label-cols": "3",
                            label: "Primary Phone: ",
                            "label-for": "primaryPhone",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "(###) ###-####",
                                expression: "'(###) ###-####'",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.cellPhone,
                                expression: "form.cellPhone",
                              },
                            ],
                            staticStyle: {
                              border: "transparent",
                              "margin-top": "6px",
                            },
                            attrs: { id: "primaryPhone" },
                            domProps: { value: _vm.form.cellPhone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "cellPhone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "TabsComponent",
        { attrs: { pills: "", lazy: "" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Patient Details", active: "" } },
            [
              _c("PatientDetails", {
                attrs: { form: _vm.form, "form-copy": _vm.formCopy },
                on: {
                  "update:form": function ($event) {
                    _vm.form = $event
                  },
                  "update:formCopy": function ($event) {
                    _vm.formCopy = $event
                  },
                  "update:form-copy": function ($event) {
                    _vm.formCopy = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Patient Insurances" } },
            [_c("PatientInsurances")],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Inquiry" } },
            [_c("PatientClaims")],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: "Notes" } },
            [_c("NotesTab", { attrs: { filter: { PatientId: _vm.form.id } } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }