var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("JustifiableWriteoffsCC", {
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
      _c("hr"),
      _c("OtherRequiredModifiersCC"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }